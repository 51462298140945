<style scoped>
.wrongLine{
  text-decoration: line-through;
}
.notify1 {
  width: 280px;
}
.notify2 {
  text-align: right; 
  width: 100%;
}
.notify3 {
  margin-top: 8px;
  text-align: right;
}
</style>
<template>
  <div>
    <!-- <title>{{ $route.meta.title }}</title> -->
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Нууц үг шинэчлэх</h2>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Profile Menu -->
      <div
        class="col-span-12 lg:col-span-4 xxl:col-span-3 flex lg:block flex-col-reverse"
      >
        <div class="intro-y box mt-5">
          <div class="relative flex items-center p-5">
            <div class="w-12 h-12 image-fit">
              <img
                class="rounded-full"
                :src="this.user.image"
              />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium text-base">
                Сайн уу?
              </div>
              <div class="text-gray-600">{{ this.user.name }}</div>
            </div>
            <div class="dropdown">
              <a class="dropdown-toggle w-5 h-5 block" href="javascript:;">
                <MoreHorizontalIcon
                  class="w-5 h-5 text-gray-700 dark:text-gray-300"
                />
              </a>
              <div class="dropdown-box w-56">
                <div class="dropdown-box__content box dark:bg-dark-1">
                  <div
                    class="p-4 border-b border-gray-200 dark:border-dark-5 font-medium"
                  >
                    Export Options
                  </div>
                  <div class="p-2">
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <ActivityIcon
                        class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"
                      />
                      English
                    </a>
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <BoxIcon
                        class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"
                      />
                      Indonesia
                      <div
                        class="text-xs text-white px-1 rounded-full bg-theme-6 ml-auto"
                      >
                        10
                      </div>
                    </a>
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <LayoutIcon
                        class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"
                      />
                      English
                    </a>
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SidebarIcon
                        class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"
                      />
                      Indonesia
                    </a>
                  </div>
                  <div
                    class="px-3 py-3 border-t border-gray-200 dark:border-dark-5 font-medium flex"
                  >
                    <button
                      type="button"
                      class="button button--sm bg-theme-1 text-white"
                    >
                      Settings
                    </button>
                    <button
                      type="button"
                      class="button button--sm bg-gray-200 text-gray-600 dark:bg-dark-5 dark:text-gray-300 ml-auto"
                    >
                      View Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <router-link
              class="flex items-center"
              to="/profile"
            >
              <UserIcon class="w-4 h-4 mr-2" /> Хувийн мэдээлэл
            </router-link>

            <router-link class="flex items-center mt-5" to="/update-profile">
              <BoxIcon class="w-4 h-4 mr-2" /> Бүртгэл засварлах
            </router-link>

            <router-link class="flex items-center mt-5 text-theme-1 dark:text-theme-10 font-medium" to="/change-password">
              <LockIcon class="w-4 h-4 mr-2" /> Нууц үг шинэчлэх
            </router-link>
            
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <a class="flex items-center" href="">
              <ActivityIcon class="w-4 h-4 mr-2" /> Email Settings
            </a>
            <a class="flex items-center mt-5" href="">
              <BoxIcon class="w-4 h-4 mr-2" /> Saved Credit Cards
            </a>
            <a class="flex items-center mt-5" href="">
              <LockIcon class="w-4 h-4 mr-2" /> Social Networks
            </a>
            <a class="flex items-center mt-5" href="">
              <SettingsIcon class="w-4 h-4 mr-2" /> Tax Information
            </a>
          </div>
          <div class="p-5 border-t flex dark:border-dark-5">
            <button
              type="button"
              class="button button--sm block bg-theme-1 text-white"
            >
              New Group
            </button>
            <button
              type="button"
              class="button button--sm border text-gray-700 dark:bg-dark-5 dark:text-gray-300 ml-auto"
            >
              New Quick Link
            </button>
          </div>
        </div>
      </div>
      <!-- END: Profile Menu -->
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
        <!-- BEGIN: Change Password -->
        <div class="intro-y box lg:mt-5">
          <div
            class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Нууц үг шинэчлэх</h2>
          </div>
          <div class="p-5" >
            <form @submit.prevent="submit">
            <div>
              <label>Хуучин нууц үг</label>
              <input
                type="password"
                class="input w-full border mt-2"
                placeholder="Old password"
                v-model="passwords.old"
                required
                pattern=".{6,}"
              />
            </div>
            <div class="mt-3">
              <label>Шинэ нууц үг</label>
              <input
                type="password"
                class="input w-full border mt-2"
                placeholder="New password"
                v-model="passwords.new"
                ref="password"
                required
                pattern=".{6,}"
              />
            </div>
            <div class="mt-3">
              <label>Шинэ нууц үг давтан</label>
              <input
                type="password"
                class="input w-full border mt-2"
                placeholder="Confirm new password"
                v-model="passwords.confirm"
                required
                pattern=".{6,}"
              />
            </div>
            <button type="submit" class="button bg-theme-1 text-white mt-4">
              Хадгалах
            </button>
            </form>
          </div>
        </div>
        <!-- END: Change Password -->
      </div>
    </div>
    
  </div>
</template>
<script>

export default {
  mounted(){
    var user = localStorage.getItem('user');
    if(user != null) {
      var user = JSON.parse(localStorage.getItem('user'));
      this.user.name = user.name;
      this.user.image = user.image;
    }
  },
  data() {
    return {
      passwords: {
        old: '',
        new: '',
        confirm: ''
      },
      user: {
        name: '',
        image: ''
      },
      status: {
        inviteConrfirm: false
      }
    }
  },
  methods: {
    submit() {
      var rts = this;
      if(this.passwords.new !== this.passwords.confirm) {
        rts.$msgbox({
          message: 'Баталгаажуулах нууц үг буруу',
          showClose: true,
          type: 'error'
        });
      } else {
        const token = localStorage.getItem('token');
        this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/user/change-password', 
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {
            oldPassword: this.passwords.old,
            password: this.passwords.new
          }
        }).then(function(data) {
          
          if(data.data.result === 'success') {
              rts.$msgbox({
                message: data.data.data,
                showClose: true,
                type: 'success'
              });
              rts.passwords.old = '';
              rts.passwords.new = '';
              rts.passwords.confirm = '';

          } else if(data.data.result === 'failed') {

            rts.$msgbox({
              message: data.data.data,
              showClose: true,
              type: 'error'
            });

          }
        }).catch(error => {
                if(error.response.status == 402) {
                    rts.$router.push('/payment');
                }
        });
      }
    },
    joinRoom(roomId) {
        this.status.inviteConrfirm = true;
        this.$socket.emit('joinRoom', {
            roomId: roomId
        });
    },
    IgnoreRoom(roomId, userId) {
      this.status.inviteConrfirm = true;
      this.$socket.emit('ignoreRoom', {
        roomId: roomId,
        userId: userId
      });
    },
    confirmInvite(event) {
        let roomId = event.target.classList[event.target.classList.length - 1];
        this.joinRoom(roomId);
        this.$router.push('/room');
      },
    ignoreInvite(event) {
      let roomId = event.toElement.classList[event.toElement.classList.length - 2];
      let fromUserId = event.toElement.classList[event.toElement.classList.length - 1];
      this.IgnoreRoom(roomId, fromUserId);
    }
  },
  sockets: {
    inviteGame: function(data) {
      this.status.inviteConrfirm = false;
      var rts = this;
      let user = localStorage.getItem('user');
      if(user) {
        var id = JSON.parse(localStorage.getItem('user'));
        if(id.id === data.to) {
          const h = this.$createElement;
          rts.$notify({
            title: 'Урилга',
            showClose: false,
            dangerouslyUseHTMLString: true,
            duration: 6000,
            onClick: function() {
              this.close();
            },
            onClose: function() {
              if(rts.status.inviteConrfirm === false) {
                rts.IgnoreRoom(data.roomId, data.fromId);
              }
            },
            message: h('div', { 
              class: 'notify1' 
              }, [
                    h('div', 
                      { 
                        class: 'notify2'
                      }, data.from + ' таныг уралдаанд урьж байна'),
                      h('div', {
                          class: 'notify3'
                        }, [
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-9 text-white ${data.roomId}`,
                                  on: {
                                    click: rts.confirmInvite.bind()
                                  }
                                }, 'Зөвшөөрөх'
                              ),
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-6 text-white ${data.roomId} ${data.fromId}`,
                                  on: {
                                    click: rts.ignoreInvite.bind()
                                  }
                                }, 'Цуцлах'
                              )
                      ])
                    ])
          });

          
        }
      }
    }
  }
}
</script>
