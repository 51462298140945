<style scoped>
.chatbox {
  width: 400px;
  height: 400px;
  display: flex;
  flex-flow: column;
  border-radius: 10px 10px 0 0;
  background: white;
  transition: .1s ease-out;
}

.chatbox-top {
  position: relative;
  display: flex;
  padding: 15px 0;
  border-radius: 10px 10px 0 0;
  background: rgba(0, 0, 0, .05);
}

.chat-partner-name {
  flex: 1;
  font-size: 15px;
  font-weight: bold;
  color: #30649c;
  text-shadow: 1px 1px 0 white;
  transition: .1s ease-out;
  cursor: pointer;
}

.chatbox-min {
  height: 50px;
}
</style>
<template>
    <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
      <div class="col-span-9 mt-6">
        <div class="intro-y block sm:flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Тоглогчид</h2>
          <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
            <span class="text-lg font-medium truncate mr-5">{{room.players.length}} / 5</span>
            <span class="text-lg font-medium truncate mr-5 flex box" :style="{'padding': '10px', 'cursor': 'pointer'}">ID: {{room.id}}</span>
          </div>
        </div>
        <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
          <table class="table table-report sm:mt-2">
            <thead>
              <tr>
                <th class="whitespace-no-wrap" align="center" width="3%"><UserIcon class="w-5 h-5 mx-auto" :style="{ 'color':'black' }" /></th>
                <th class="whitespace-no-wrap" align="center">Зураг</th>
                <th class="whitespace-no-wrap">Нэр</th>
                <th class="whitespace-no-wrap">Цол</th>
                <th class="text-center whitespace-no-wrap">WPM</th>
                <th class="text-center whitespace-no-wrap">Accuracy</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(players, index) in this.room.players"
                :key="index"
                class="intro-x"
              >
              <td align="center">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="oval" class="w-6 h-6" />
                </div>
              </td>
                <td class="w-60" align="center">
                  <div class="flex">
                    <div class="w-10 h-10 image-fit zoom-in" :style="{'margin': 'auto'}">
                      <Tippy
                        tag="img"
                        class="rounded-full"
                        :src="`${players.image}`"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <a href="" class="font-medium whitespace-no-wrap">{{
                    players.name
                  }}</a>
                  <div class="text-gray-600 text-xs whitespace-no-wrap">
                    #{{ players.id }}
                  </div>
                </td>
                <td>
                    <img :src="`${$appUrl}/images/rank/${players.r_image}`" :style="{'width': 'auto', 'height': '40px', 'box-shadow': 'none'}"/>
                </td>
                <td class="text-center">{{ players.avg_wpm }}</td>
                <td class="text-center">{{ players.avg_percent }}</td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="intro-y block sm:flex items-center h-10">
            <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
                <button class="button w-50 mr-2 mb-2 flex items-center justify-center bg-theme-6 text-white" @click="this.leaveRoom">
                    <LogOutIcon class="w-4 h-4 mr-2" /> Өрөөнөөс гарах
                </button>
            </div>
          </div> -->
        </div>
      </div>

      <div class="intro-y box col-span-3 mt-6" :style="{'height': '700px', 'max-height': '700px', 'overflow-y': 'auto'}">
          <div
              class="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto py-5">Онлайн хэрэглэгчид ({{friends.list.length}})</h2>
            </div>
            <div class="pr-1">
              <div class="px-5 pb-5 lg:pb-0 mt-5">
                <div class="relative text-gray-700 dark:text-gray-300 pb-5">
                  <input
                    type="text"
                    class="input input--lg w-full bg-gray-200 pr-10 placeholder-theme-13"
                    placeholder="Хэрэглэгчийн нэрээр хайх..."
                    v-model="searchOnName"
                  />
                  <SearchIcon
                    class="w-4 h-4 hidden sm:absolute my-auto inset-y-0 mr-3 right-0"
                  />
                </div>
              </div>
            </div>
            <div class="p-5 pt-1">
              <div class="tab-content">
                <div
                  v-for="(friends, index) in this.friends.list"
                  :key="index"
                >
                  <div v-if="(friends.name).toUpperCase().includes(searchOnName.toUpperCase())" class="box intro-y px-4 py-4 mb-3 flex items-center zoom-in">
                    <div
                      class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
                    >
                      <img
                        :src="`${friends.image}`"
                      />

                    <div v-if="friends.online === 1"
                      class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
                    >
                    
                    </div>

                    <div v-else
                      class="w-3 h-3 bg-theme-7 absolute right-0 bottom-0 rounded-full border-2 border-white"
                    >
                    </div>


                    </div>
                    <div class="ml-4 mr-auto">
                      <div class="font-medium">{{ friends.name }}</div>
                      <div class="text-gray-600 text-xs">
                        #{{ friends.id }}
                      </div>
                    </div>
                    
                    <button v-if="friends.online === 1 && inRoomPlayers(friends.id)" class="button px-2 mr-1 mb-2 bg-gray-200 text-gray-600" @click="inviteFriend(friends.id)" disabled> 

                      <span v-if="!room.invite.includes(friends.id)" class="w-5 h-5 flex items-center justify-center">
                          <CheckIcon class="w-4 h-4" />
                      </span> 
                      
                    </button>

                    <button v-else-if="friends.online === 1 && !inRoomPlayers(friends.id)" class="button px-2 mr-1 mb-2 bg-gray-200 text-gray-600" @click="inviteFriend(friends.id)"> 
                      <span v-if="!room.invite.includes(friends.id)" class="w-5 h-5 flex items-center justify-center">
                          <PlusIcon class="w-4 h-4" />
                      </span> 

                      <div v-else-if="room.invite.includes(friends.id)"
                        class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                      >
                        <LoadingIcon icon="three-dots" class="w-4 h-4" />
                      </div> 
                      
                    </button>

                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>



    <div id="warning-modal-preview" class="modal">
        <div class="modal__content">
            <div class="p-5 text-center">
                <xCircleIcon class="w-10 h-10 text-theme-6 mx-auto mt-3" />
                <div class="text-3xl">Уучлаарай</div>
                <div class="text-gray-600 mt-2" :style="{'font-size': '15px'}"> Та уралдаанд нэгдээгүй байна.! </div>
            </div>
            <div class="px-5 pb-8 text-center"> <button type="button" data-dismiss="modal" class="button w-24 bg-theme-1 text-white"> За </button> </div>
        </div>
    </div>

     <div id="delete-race-preview" class="modal">
        <div class="modal__content">
            <div class="p-5 text-center">
                <xCircleIcon class="w-10 h-10 text-theme-6 mx-auto mt-3" />
                <div class="text-3xl">Уучлаарай</div>
                <div class="text-gray-600 mt-2" :style="{'font-size': '15px'}"> Тус уралдаан цуцлагдлаа.! </div>
            </div>
            <div class="px-5 pb-8 text-center"> <button type="button" data-dismiss="modal" class="button w-24 bg-theme-1 text-white"> За </button> </div>
        </div>
    </div>

    <div class="chatbox fixed bottom-0 left-0 shadow-md ml-10 box border z-50" v-bind:class="{'chatbox-min': chatMinus}">
        <div class="chatbox-top" @click="MinusOrMaximum">
          <div v-if="room.chatNotify == true" class="w-6 h-6 bg-theme-6 absolute rounded-full border-2 border-white" :style="{'right': '-5px', 'top': '-5px'}"></div>
          <div class="chat-partner-name" align="center">
            <h2 class="font-medium text-base">Group chat</h2>
          </div>    
        </div>
        <div class="chat-messages">
          <div class="chat__box box" :style="{'height': '350px'}">
                <!-- BEGIN: Chat Active -->
                <div class="h-full flex flex-col">
                  <div class="overflow-y-scroll scrollbar-hidden px-5 pt-5 flex-1" :style="{'max-height': '100%', 'padding-bottom': '80px'}" ref="pageBackground">
                    <div v-for="(items, index) in room.chats" :key="index">
                      <div v-if="items.user.id == room.me" class="chat__box__text-box flex items-end float-right mb-4">
                        <div
                          class="bg-theme-1 px-4 py-3 text-white rounded-l-md rounded-t-md"
                        >
                          {{items.message}}
                        </div>
                        <div
                          class="w-10 h-10 hidden sm:block flex-none image-fit relative ml-5"
                        >
                          <img
                            alt="Midone Tailwind HTML Admin Template"
                            class="rounded-full"
                            :src="items.user.image"
                          />
                        </div>
                      </div>

                      <div v-else class="chat__box__text-box flex items-end float-left mb-4">
                        <div
                          class="w-10 h-10 hidden sm:block flex-none image-fit relative mr-5"
                        >
                          <img
                            :alt="items.user.name"
                            class="rounded-full"
                            :src="items.user.image"
                          />
                        </div>
                        <div
                          class="bg-gray-200 dark:bg-dark-5 px-4 py-3 text-gray-700 dark:text-gray-300 rounded-r-md rounded-t-md"
                        >
                          <div class="mt-1 text-xs text-gray-600" align="left">{{items.user.name}}</div>
                          {{items.message}}
                        </div>
                      </div>
                      <div class="clear-both"></div>
                    </div>
                    
                  </div>
                  <div
                    class="pt-4 pb-10 sm:py-4 flex items-center border-t border-gray-200 dark:border-dark-5"
                  >
                    <input
                      type="text"
                      class="chat__box__input input dark:bg-dark-3 w-full h-8 resize-none border-transparent px-5 py-3 focus:shadow-none"
                      rows="1"
                      placeholder="50 тэмдэгтэнд багтааж бичнэ үү"
                      maxlength="50"
                      v-model="room.message"
                      @keyup.enter="sendMessage"
                    />
                    <a
                      href="javascript:;"
                      @click="sendMessage"
                      class="w-8 h-8 block bg-theme-1 text-white rounded-full flex-none flex items-center justify-center mr-5"
                    >
                      <SendIcon class="w-4 h-4" />
                    </a>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
</template>
<script>
let notifySound;
export default {
    mounted() {
        notifySound = new Audio(require(`@/assets/sound/notification/notification.mp3`));
        this.getFriends();
        this.getRoomId();
        let user = JSON.parse(localStorage.getItem('user'));
        this.room.me = user.id;
    },
    data() {
        return {
            chatMinus: true,
            friends: {
                list: []
            },
            room: {
                id: '',
                players: [],
                start: false,
                invite: [],
                chats: [],
                message: '',
                me: 0,
                chatNotify: false
            },
            searchOnName: ''
        }
    },
    methods: {
        MinusOrMaximum() {
          if(this.chatMinus == false) {
            this.chatMinus = true;
          } else {
            this.chatMinus = false;
            this.room.chatNotify = false;
            this.$refs.pageBackground.scrollTop = this.$refs.pageBackground.scrollHeight;
          }
        },
        sendMessage() {
          if(this.room.message != '') {
            let user = JSON.parse(localStorage.getItem('user'));
            this.$socket.emit('chatRoomMessage', {
                  roomId: this.room.id,
                  user,
                  message: this.room.message
            });
            this.room.message = '';
          }
        },
        getRoomId(){
            const token = localStorage.getItem('token');
            var rts = this;
            if(token) {
                this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/room/get-room',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(function(data) {
                    if(data.data.data === 0) {
                        cash('#warning-modal-preview').modal('show');
                        rts.$router.push('/beginner');
                    } else {
                        rts.room.id = data.data.data.roomId;
                        rts.getChat();
                        rts.$socket.emit('joinRoom', {
                            roomId: rts.room.id
                        });
                        rts.getPlayers();
                    }
                }).catch(error => {
                if(error.response.status == 402) {
                  rts.$router.push('/text');
                }
              });
            }
        },
        getChat() {
          let cookie = JSON.parse(localStorage.getItem(this.room.id)); let chts = [];
          if(cookie) {
            for (let i = 0; i < cookie.length; i++) {
              chts.push(cookie[i]);
            }
            this.room.chats = chts;
            
          }
        },
        getFriends() {
            const token = localStorage.getItem('token');
            var rts = this;
            if(token) {
                this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/user/get-friends',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(function(data) {
                    rts.friends.list = data.data.data;
                }).catch(error => {
                  if(error.response.status == 402) {
                    rts.$router.push('/text');
                  }
                });
            }
        },
        inviteFriend (id){
            if(!this.room.invite.includes(id)) {
              this.room.invite.push(id);
            }
            setTimeout(() => {
              this.room.invite.forEach((element, index) => {
                if(id == element) {
                  this.room.invite.splice(index, 1);
                }
              });
            }, 5000);
            this.$socket.emit('inviteTo', {
                roomId: this.room.id,
                to: id
            });
        },
        getPlayers() {
            const token = localStorage.getItem('token');
            var rts = this;
            this.$axios({
                method: 'post',
                url: this.$appUrl+'/api/room/get-players',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    roomId: rts.room.id
                }
            }).then(function(data) {
                rts.room.players = data.data.data;
            }).catch(error => {
              if(error.response.status == 402) {
                rts.$router.push('/text');
              }
            });
        },
        leaveRoom() {
            this.$socket.emit('leaveRoom',{
                roomId: this.room.id
            });
        },
        inRoomPlayers(id) {
          let ex = this.room.players.some(function(field) {
            return field.id == id
          });
          return ex;
        }
    },
    beforeRouteLeave (to, from , next) {
        localStorage.removeItem(this.room.id);
        if(this.room.start === false) {
          this.leaveRoom();
          next();
        } else {
          next();
        }
    },
    sockets: {
    onMessage: function(data) {
      notifySound.pause();
      notifySound.currentTime = 0;
      notifySound.play();
      this.$refs.pageBackground.scrollTop = this.$refs.pageBackground.scrollHeight;
      if (data.user.id != this.room.me) {
        if(this.chatMinus == true) {
          this.room.chatNotify = true;
        }
      }

      let cookie = JSON.parse(localStorage.getItem(this.room.id)); let chts = [];
      if(cookie) {
        for (let i = 0; i < cookie.length; i++) {
          chts.push(cookie[i]);
        }
        chts.push(data);
        localStorage[this.room.id] = JSON.stringify(chts);
        this.room.chats = chts;
      } else {
        chts.push(data);
        localStorage[this.room.id] = JSON.stringify(chts);
        this.room.chats = chts;
      }
    },
    onlineUser: function(data) {
      let ex = this.friends.list.some(function(field) {
        return field.id === data.user.id
      });
      
      if(ex) {
        this.friends.list.forEach(element => {
          if(element.id === data.id) {
            element.online = 1;
          }
        });
      } else {
        this.friends.list.push(data.user);
      }
    },
    ignoredRoom: function(data) {
      var user = JSON.parse(localStorage.getItem('user'));
      
      if(data.from == user.id) {
        this.$msgbox({
          message: data.user + ' уралдаанд нэгдэхээс татгалзлаа.',
          type: 'error'
        });
        this.room.invite.forEach((element, index) => {
          if(element == data.fromId) {
            this.room.invite.splice(index, 1);
          }
        });
      }
    },
    start: function(data) {
      this.room.start = true;
      this.$router.push({name: 'top-menu-race', params: {roomId: this.room.id}});
    },
    offlineUser: function(data) {
      let ex = this.friends.list.some(function(field) {
        return field.id === data.id
      });
      
      if(ex) {
        this.friends.list.forEach((element, index) => {
          if(element.id === data.id) {
            this.friends.list.splice(index, 1);
            //element.online = 0;
          }
        });
      } 
    },
    newPlayer: function(data) {
      let ex = this.room.players.some(function(field) {
        return field.id === data.user.id
      });
      if(!ex) {
        this.room.players.push(data.user);
      }
    },
    leavePlayer: function(data) {
      let ex = this.room.players.some(function(field) {
        return field.id === data.user
      });

      if(ex) {
        this.room.players.forEach((element, index) => {
          if(element.id === data.user) {
            this.room.players.splice(index, 1);
          }
        });
      }
    },
    deleteRoom: function(data) {
        cash('#delete-race-preview').modal('show');
        this.$router.go(-1);
    }
  }
}
</script>