<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300&display=swap');
.wrongLine{
  text-decoration: line-through;
}
.main-font {
  font-family: 'Exo 2', sans-serif;
}
.notify1 {
  width: 280px;
}
.notify2 {
  text-align: right; 
  width: 100%;
}
.notify3 {
  margin-top: 8px;
  text-align: right;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    text-align: center;
}

.clock-day:before {
  content: var(--timer-day);
}
.clock-hours:before {
  content: var(--timer-hours);
}
.clock-minutes:before {
  content: var(--timer-minutes);
}
.clock-seconds:before {
  content: var(--timer-seconds);
}

body {
  min-height: 80vh;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.clock-container {
  font-family: 'Exo 2', sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 5px;
  display: -webkit-box;
  display: flex;
}
.clock-col {
  text-align: center;
  margin-right: 0px;
  margin-left: 0px;
  min-width: 25%;
  position: relative;
}
.clock-col:not(:last-child):before, .clock-col:not(:last-child):after {
  content: "";
  background-color: #000;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: block;
  position: absolute;
  right: 0px;
}
.clock-col:not(:last-child):before {
  top: 25%;
}
.clock-col:not(:last-child):after {
  top: 40%;
}
.clock-timer {
  color: #000;
  font-size: 2.2rem;
  text-transform: uppercase;
}
.clock-label {
  color: #000;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-top: 1px;
}
.main-text-content {
  width: 50%; line-height: 100px; font-size: 50px; text-align: left; overflow: hidden; white-space: nowrap;
}
.main-text-content:focus {
  border-left: 1px solid black;
}

@media (max-width: 825px) {
  .clock-container {
    display: none;
  }

}

</style>

<template>
  <div class="grid grid-cols-12 gap-6 main-font">
    <div v-if="status.started == true" class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">

        <div class="col-span-12 lg:col-span-12 mt-8">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              Шагналтай уралдаан
            </h2>
            <div class="flex items-center sm:ml-auto mt-3">
              
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-9 mt-8">

        <div :style="{'width': '100%', 'position': 'relative'}" align="center" class="mb-10">

          <div class="intro-y box p-5 mt-12 sm:mt-5 ml-10" :style="{'width': '120px', 'height': '120px', 'display': 'inline-block'}">
            <div class="flex flex-col xl:flex-row xl:items-center" :style="{'vertical-align': 'middle', 'height': '100%'}">
              <span :style="{'margin': 'auto', 'font-size': '45px', 'font-weight': '700'}">{{counter.typed}}</span>
            </div>
            <div :style="{'margin-top': '-20px'}">ҮГ</div>
          </div>

          <div class="intro-y box p-5 mt-12 sm:mt-5 ml-10" :style="{'width': '120px', 'height': '120px', 'display': 'inline-block'}">
            <div class="flex flex-col xl:flex-row xl:items-center" :style="{'vertical-align': 'middle', 'height': '100%'}">
              <span v-if="this.time.time_passed > 0" :style="{'margin': 'auto', 'font-size': '50px', 'font-weight': '700'}">{{parseInt((this.counter.characters / 5) / (this.time.time_passed / 60))}}</span>
              <span v-else :style="{'margin': 'auto', 'font-size': '45px', 'font-weight': '700'}">0</span>
            </div>
            <div :style="{'margin-top': '-20px'}">WPM</div>
          </div>

          <div class="intro-y box p-5 mt-12 sm:mt-5 ml-10" :style="{'width': '120px', 'height': '120px', 'display': 'inline-block'}">
            <div class="flex flex-col xl:flex-row xl:items-center" :style="{'vertical-align': 'middle', 'height': '100%'}">
              <span :style="{'margin': 'auto', 'font-size': '45px', 'font-weight': '700'}">{{counter.characters}}</span>
            </div>
            <div :style="{'margin-top': '-20px'}">CPM</div>
          </div>

          <button @click="getText" :style="{'position': 'absolute', 'right': '0', 'bottom': '0'}" class="button px-2 mr-10 bg-theme-9 text-white intro-y"> <span class="w-10 h-10 flex items-center justify-center">
              <RefreshCwIcon class="w-10 h-10" />
          </span> </button>

        </div>

        <div class="intro-y box p-5 mt-12 sm:mt-5" :style="{'position': 'relative'}">
          <div class="flex flex-col xl:flex-row xl:items-center">
            <div class="flex" @click="setFocus" :style="{'width': '100%', 'height': '100px', 'background-repeat': 'no-repeat', 'background-position': 'center', 'cursor': 'auto'}" ref="fingerBack">
                <div :style="{'width': '100%', 'height': '100px', 'margin': 'auto', 'display': 'flex', 'flex-wrap': 'nowrap'}">
                    <div style="width: 50%; text-align: right; line-height: 100px; font-size: 50px; text-align: right; white-space: nowrap; overflow: hidden;">
                        <span class="typed-text-content" ref="typedTextContent" v-html="build"></span>
                        <div style="display: inline-block; text-align: center; line-height: 100px; font-size: 50px; outline-width: 0; min-width: 10px; color: #6f8fc9;" @keypress.enter.prevent :contenteditable="this.status.contentEdit" @keydown="check" id="testInputDiv" ref="testInputDiv"></div>
                    </div>
                    
                    <div class="main-text-content" id="mainTextContent" ref="mainTextContent">
                      <span style="margin-right: 15px;">{{this.spans.span1}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span2}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span3}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span4}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span5}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span6}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span7}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span8}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span9}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span10}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span11}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span12}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span13}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span14}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span15}}</span>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="progress intro-y">
            <el-progress :percentage="counter.progress" :show-text="false" :color="'#5787E4'" :stroke-width="3"></el-progress>
        </div>
      </div>

      <div class="intro-y col-span-12 lg:col-span-3">
          <h2 class="font-medium text-base mr-auto">Уралдаан дуусах хугацаа</h2>
            <div class="clock-container box">
                <div class="clock-col">
                  <p class="clock-day clock-timer">
                    {{countdown.day}}
                  </p>
                  <p class="clock-label">
                    Өдөр
                  </p>
                </div>
                <div class="clock-col">
                  <p class="clock-hours clock-timer">
                    {{countdown.hour}}
                  </p>
                  <p class="clock-label">
                    Цаг
                  </p>
                </div>
                <div class="clock-col">
                  <p class="clock-minutes clock-timer">
                    {{countdown.min}}
                  </p>
                  <p class="clock-label">
                    Минут
                  </p>
                </div>
                <div class="clock-col">
                  <p class="clock-seconds clock-timer">
                    {{countdown.sec}}
                  </p>
                  <p class="clock-label">
                    Сек
                  </p>
                </div>
              </div>
            <div
              class=" box flex items-center px-5 py-5 sm:py-0 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto py-5">ТЭРГҮҮЛЭГЧИД</h2>
            </div>
            <div class="p-5 box"  :style="{'height': '700px', 'max-height': '700px', 'overflow-y': 'auto'}">
              <div class="tab-content">
                <div
                  v-for="(users, index) in this.tops.alltime"
                  :key="index"
                  class="intro-y"
                >
                  <div class="box px-4 py-4 mb-3 flex items-center zoom-in" v-bind:class="{'bg-theme-5 dark:bg-theme-1': users.id == me}">
                    <div
                      class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
                    >
                      <img
                        :alt="`${users.name} image`"
                        :src="`${users.image}`"
                      />
                    </div>
                    <div class="ml-4 mr-auto">
                      <div class="font-medium">{{ users.name }}</div>
                      <div class="text-gray-600 text-xs">
                        #{{ users.stage }} <span :style="{'font-weight': '700'}">{{diffTime(users.date)}}</span>
                      </div>
                    </div>
                    <div
                      class="py-1 px-2 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium"
                    >
                      {{users.wpm}} WPM
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    <div v-else-if="status.started == false" class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12 mt-8 intro-y" align="center">
        <div class="intro-y block items-center" align="center" :style="{'margin-top': '5%', 'height': '70px'}">
          <span class="text-lg font-medium truncate mr-5" :style="{'font-weight': '700', 'font-size': '35px'}">
            УРАЛДААН ЭХЛЭХЭД
          </span>
        </div>
        <div :style="{'width': '600px'}">
          <div class="clock-container box">
                <div class="clock-col">
                  <p class="clock-day clock-timer">
                    {{countdown.day}}
                  </p>
                  <p class="clock-label">
                    Өдөр
                  </p>
                </div>
                <div class="clock-col">
                  <p class="clock-hours clock-timer">
                    {{countdown.hour}}
                  </p>
                  <p class="clock-label">
                    Цаг
                  </p>
                </div>
                <div class="clock-col">
                  <p class="clock-minutes clock-timer">
                    {{countdown.min}}
                  </p>
                  <p class="clock-label">
                    Минут
                  </p>
                </div>
                <div class="clock-col">
                  <p class="clock-seconds clock-timer">
                    {{countdown.sec}}
                  </p>
                  <p class="clock-label">
                    Сек
                  </p>
                </div>
              </div>
        </div>
        <div class="intro-y block items-center mt-3" align="center" :style="{'height': '100px'}">
          <span class="text-lg font-medium truncate mr-5" :style="{'font-weight': '700', 'font-size': '35px'}">
            <!-- ДУТУУ БАЙНА ! -->
          </span>
        </div>
      </div>
    </div>

    <div id="finish_modal" ref="finish_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false" @click.self="hiddenResultModal">
     <div class="modal__content">
         <div class="p-5 text-center">
             <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
             <div class="text-3xl mt-5">Амжилттай!</div>
             <div class="grid grid-cols-12">
               <div class="col-span-12 xl:col-span-12 mt-6">
                <div class="text-gray-600 mt-2"> Та <span style="font-weight: 700;">{{parseInt((this.counter.characters / 5) / (this.time.time_passed / 60))}} WPM ({{this.counter.characters}} CPM)</span> бичсэн байна. Танд амжилт хүсье!.</div>
               </div>
             </div>
         </div>
         <!-- <div class="px-5 pb-8 text-center"><button type="button" data-dismiss="modal" @click="hiddenResultModal" class="button w-24 bg-theme-1 text-white"> За </button> </div> -->
         <div class="px-5 pb-8 text-center"><button type="button" data-dismiss="modal" @click="endRace" class="button w-20 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"> Болих </button> <button data-dismiss="modal" type="button" class="button w-40 bg-theme-1 text-white"> Дахиж уралдах </button></div>
     </div>
    </div>  
  </div>
</template>

<script>

import ReportLineChart from "@/components/ReportLineChart";
import ReportDonutChart from "@/components/ReportDonutChart";
import ReportPieChart from "@/components/ReportPieChart";
import ReportMap from "@/components/ReportMap";
import ReportDonutChart1 from "@/components/ReportDonutChart1";
import SimpleLineChart1 from "@/components/SimpleLineChart1";
import VueSlickCarousel from "vue-slick-carousel";
import FlipCountdown from 'vue2-flip-countdown'
let timer = null;
let countdown = null;

export default {
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart,
    ReportMap,
    ReportDonutChart1,
    SimpleLineChart1,
    VueSlickCarousel,
    FlipCountdown 
  },
  data() {
    return {
      onNow: '',
      onPrevPrev: '',
      onPrev: '',
      onCurrent: '',
      countdown: {
        day: 0,
        hour: 0,
        min: 0,
        sec: 0
      },
      salesReportFilter: "",
      me: '',
      texts: {
          original: '',
          typed : '',
          now: '',
          splitted: '',
          splittedOneWord: [],
          currentWord: ''
      },
      counter: {
          space: 0,
          typed: 0,
          errors: 0,
          accuracy: 0,
          characters: 0,
          progress: 0
      },
      status: {
        contentEdit: true,
        inviteConrfirm: false,
        raceId: '',
        start: '0001-01-01 00:00:00',
        end: '0001-01-01 00:00:00',
        started: ''
      },
      tops: {
        alltime: []
      },
      time: {
          time_passed: 0,
          start: false
      },
      spans: {
        span1: '',
        span2: '',
        span3: '',
        span4: '',
        span5: '',
        span6: '',
        span7: '',
        span8: '',
        span9: '',
        span10: '',
        span11: '',
        span12: '',
        span13: '',
        span14: '',
        span15: ''
      }
    };
  },
  computed: {
    build: function() {
      return this.texts.typed;
    },
  },
  mounted(){
    this.status.raceId = this.$route.params.raceId;
    this.me = JSON.parse(localStorage.getItem('user')).id;
    
    clearInterval(timer);
    this.getText();
    this.getTops();
  },
  methods: {
    updateCountdown() {
      var now = new Date();
      var end = new Date(this.status.end)
      var sec = Math.abs(Math.round((end.getTime() - now.getTime()) / 1000));
      this.countdown.day = Math.floor(sec / 24 / 60 / 60);
      var hour = Math.floor((sec) - (this.countdown.day * 86400));
      this.countdown.hour = Math.floor(hour / 3600);
      var min = Math.floor( hour - (this.countdown.hour * 3600));
      var min2 = Math.floor(min / 60);
      if (min2 < 10) {
        this.countdown.min = '0' + min2; 
      } else {
        this.countdown.min = min2; 
      }
      var sec2 = sec % 60;
      if(sec2 < 10) {
        this.countdown.sec = '0' + sec2;
      } else {
        this.countdown.sec = sec2;
      }
    },
    getTops() {
      var rts = this;
      const token = localStorage.getItem('token');
      this.$axios({
        method: 'post',
        url: this.$appUrl+'/api/user/get-all-players-gift-race',
        headers: {
              "Authorization": `Bearer ${token}`
            },
        data: {
              raceId: this.status.raceId
            }
      })
      .then(function(data){
        rts.tops.alltime = data.data.data;
      }).catch(error => {
          if(error.response.status == 402) {
            rts.$router.push('/payment');
          }
        });
    },
    endRace() {
      this.$router.push('/ranking-rooms');
    },
    prevImportantNotes() {
      this.$refs["important-notes"].prev();
    },
    getText() {
      this.onCurrent = '';
      clearInterval(timer);
      this.counter.typed = 0;
      this.counter.space = 0;
      this.counter.errors = 0;
      this.counter.accuracy = 0;
      this.counter.characters = 0;
      this.status.contentEdit = true;      
      this.time.time_passed = 0;
      this.time.start = false;
      this.texts.typed = '';
      this.counter.progress = 0;

      var rts = this;

      const token = localStorage.getItem('token');
      if(token) {
        this.$axios({
            method: 'post',
            url: rts.$appUrl +'/api/text/active-ranking-text',
            headers: {
              "Authorization": `Bearer ${token}`
            },
            data: {
              raceId: this.status.raceId
            }
          })
        .then(function(data){
          if(data.data.result === 'success') {
            
            let start = new Date(data.data.data.start_at);
            // start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate() + ' ' + start.getHours() + ':' + start.getMinutes() + ':' + start.getSeconds();
            rts.status.start = start;

            let end = new Date(data.data.data.end_at);
            end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate() + ' ' + end.getHours() + ':' + end.getMinutes() + ':' + end.getSeconds();
            rts.status.end = end;

            let current = new Date();
            // current = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate() + ' ' + current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
            
            if(current >  rts.status.start) {
              countdown = setInterval(rts.updateCountdown, 1000);
              rts.status.started = true;
              rts.texts.original = data.data.data.text;
              rts.makeSpanText();
            } else {
              let start = new Date(data.data.data.start_at);
              start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate() + ' ' + start.getHours() + ':' + start.getMinutes() + ':' + start.getSeconds();
              rts.status.end = start;
              countdown = setInterval(rts.updateCountdown, 1000);
              rts.status.started = false;
            }
          } else if( data.data.result === 'access') {
              rts.$msgbox({
                message: data.data.msg,
                type: 'error'
              });
              rts.$router.push('/ranking-rooms');
            }
            else if(data.data.result === 'failed') {
              rts.$msgbox({
                message: `Уучлаарай алдаа гарлаа.`,
                type: 'error'
              });
              rts.$router.push('/ranking-rooms');
            }
        }).catch(error => {
          if(error.response.status == 402) {
            rts.$router.push('/payment');
          }
        });
      }
    },
    nextImportantNotes() {
      this.$refs["important-notes"].next();
    },
    makeSpanText() {
        var current_content = '';
        this.texts.splitted = this.texts.original.split(' ');
      this.giveSpanText();

    },
    setFocus() {
      document.getElementById("testInputDiv").focus();
    },
    hiddenResultModal() {
      rts.$refs.testInputDiv.innerHTML = '';
      document.getElementById("testInputDiv").focus();
      this.getText();
    },
    giveSpanText(){
      this.spans.span1 = '';
      this.spans.span2 = '';
      this.spans.span3 = '';
      this.spans.span4 = '';
      this.spans.span5 = '';
      this.spans.span6 = '';
      this.spans.span7 = '';
      this.spans.span8 = '';
      this.spans.span9 = '';
      this.spans.span10 = '';
      this.spans.span11 = '';
      this.spans.span12 = '';
      this.spans.span13 = '';
      this.spans.span14 = '';
      this.spans.span15 = '';
      

      if(this.counter.space < this.texts.splitted.length) {
        for(let i=this.counter.space; i < this.texts.splitted.length; i++){
          switch(i) {
            case this.counter.space : this.spans.span1 = this.texts.splitted[i]; break;
            case this.counter.space + 1 : this.spans.span2 = this.texts.splitted[i]; break;
            case this.counter.space + 2: this.spans.span3 = this.texts.splitted[i]; break;
            case this.counter.space + 3: this.spans.span4 = this.texts.splitted[i]; break;
            case this.counter.space + 4: this.spans.span5 = this.texts.splitted[i]; break;
            case this.counter.space + 5: this.spans.span6 = this.texts.splitted[i]; break;
            case this.counter.space + 6: this.spans.span7 = this.texts.splitted[i]; break;
            case this.counter.space + 7: this.spans.span8 = this.texts.splitted[i]; break;
            case this.counter.space + 8: this.spans.span9 = this.texts.splitted[i]; break;
            case this.counter.space + 9: this.spans.span10 = this.texts.splitted[i]; break;
            case this.counter.space + 10: this.spans.span11 = this.texts.splitted[i]; break;
            case this.counter.space + 11: this.spans.span12 = this.texts.splitted[i]; break;
            case this.counter.space + 12: this.spans.span13 = this.texts.splitted[i]; break;
            case this.counter.space + 13: this.spans.span14 = this.texts.splitted[i]; break;
            case this.counter.space + 14: this.spans.span15 = this.texts.splitted[i]; break;
            default: break;
          }
        }
        this.splitWord();
      } else {
        this.finishGame();
      }
    },
    splitWord() {
      this.texts.splittedOneWord = this.texts.splitted[this.counter.space].split('');
      this.texts.currentWord =  this.texts.splittedOneWord[0];
    }, 
    check(v) {
      var rm = ['Home', 'Tab', 'CapsLock', 'Shift', 'End', 'PageUp', 'PageDown', 'Alt', 'Enter', 'NubLock', 'Control', 'ControlRight', 'ControlLeft'];
      let ex = rm.some(function(field) {
        return field == v.key
      });
      this.onPrevPrev = this.onPrev;
      this.onPrev = this.onNow; 
      this.onNow = v.code;
      if(!ex) {
        if(v.key == 'Backspace') {
          if((this.onPrev == 'KeyA' && (this.onPrevPrev == 'ControlRight' || this.onPrevPrev == 'ControlLeft')) || (this.onPrev == 'KeyA' && (this.onPrevPrev == 'MetaLeft' || this.onPrevPrev == 'MetaRight'))) {
            this.onCurrent = '';
            this.$refs.testInputDiv.innerHTML = '';
          } else {
            if(this.onPrev == 'ControlLeft' || this.onPrev == 'ControlRight' || this.onPrev == 'MetaLeft' || this.onPrev == 'MetaRight') {
              this.onCurrent = '';
              this.$refs.testInputDiv.innerHTML = '';
            } else {
              if(this.onCurrent != '') {
                this.onCurrent = this.onCurrent.substring(0, this.onCurrent.length - 1);
              }
            }
          }
        } else {
          this.onCurrent += v.key;
        }
      }
      this.type(v.key);
    },
    type(space){
        if(this.time.start == false) {
          this.startGame();
        }
            var text = this.texts.splitted[this.counter.space];
            var input = this.onCurrent;      
            var permission = true;
            
            if(input[input.length - 1] == ' ') {
              input = input.substring(0, input.length - 1);
            }
            var input_array = input.split('');
            
            for(let i=-1; i < input_array.length; i++) {
                if(input_array[i] != this.texts.splittedOneWord[i]) {
                  permission = false; break;
                }
                this.texts.currentWord = this.texts.splittedOneWord[i + 1];
            }
                if(permission) {                
                    if(this.texts.splitted.length === this.counter.space + 1) {
                        if(input == this.texts.splitted[this.counter.space]) {
                            this.texts.typed = this.texts.typed +  '<span style="margin-right: 15px; color: #a3a0a0;">'+ input.replace('&nbsp;','') + '</span>';
                                this.counter.characters += text.length;
                                this.counter.typed++;
                                
                                this.$refs.testInputDiv.innerHTML = '';
                                this.counter.space++;

                                this.counter.accuracy = parseInt(this.counter.typed / ( this.counter.typed + this.counter.errors )  * 100 );

                                this.giveSpanText();
                        } else {
                            this.$refs.testInputDiv.classList.remove('wrongLine');
                            this.spans.span1 = text.substr(input.length, text.length);
                        }
                    } else {                        
                        if(space == ' '){
                            if(input != '') {
                                if(text === input) {
                                    this.texts.typed = this.texts.typed +  '<span style="margin-right: 15px; color: #a3a0a0;">'+ input.replace('&nbsp;','') + '</span>';
                                    this.counter.characters += text.length;
                                    this.counter.typed++;
                                    this.$refs.testInputDiv.innerHTML = '';
                                    this.counter.space++;

                                    this.counter.accuracy = parseInt(this.counter.typed / ( this.counter.typed + this.counter.errors )  * 100 );
                                    this.onCurrent = '';
                                    this.giveSpanText();
                                }
                                
                            } else {
                                this.$refs.testInputDiv.innerHTML = '';
                            } 
                        }
                        else {
                            this.$refs.testInputDiv.classList.remove('wrongLine');
                            this.spans.span1 = text.substr(input.length, text.length);
                        }
                    }
                    if (parseInt((this.counter.characters + this.counter.space) * 100 / this.texts.original.length) > 100) {
                      this.counter.progress = 100;
                    } else {
                      this.counter.progress = parseInt((this.counter.characters + this.counter.space) * 100 / this.texts.original.length)
                    }
                } else {
                    this.$refs.testInputDiv.classList.add('wrongLine');
                }
            },

    updateTimer() {
      this.time.time_passed++;
    },
    finishGame() {
      clearInterval(timer);
      this.upgradeWpm();
      this.status.contentEdit = false;
      cash("#finish_modal").modal("show");
    },
    upgradePoint(point) {
      var rts = this;
      const token = localStorage.getItem('token');
      this.$axios({
        method: 'post',
        url: this.$appUrl+'/api/text/upgrade-wpm',
        headers: {
          "Authorization": `Bearer ${token}`
        },
        data: {
          point: point,
          wpm: parseInt((this.counter.characters / 5) / (this.time.time_passed / 60)),
          percent: 100
        }
      }).then(function(data){
        let user = JSON.parse(localStorage.getItem('user'));
        user.point = user.point + point;
        user.avg_wpm = data.data.avg_wpm;
        user.record_wpm = data.data.record;
        localStorage.user = JSON.stringify(user);
        rts.$emit('ChanePointEvent', point);
      }).catch(error => {
          if(error.response.status == 402) {
            rts.$router.push('/payment');
          }
      });
    },
    diffTime(time) {
      let last = new Date(time);
      // last = last.getFullYear() + '-' + (last.getMonth() + 1) + '-' + last.getDate() + ' ' + last.getHours() + ':' + last.getMinutes() + ':' + last.getSeconds();
      let current = new Date();
      let diff = (current - last) / 1000;
      let sec, min, hours, day, month, year;
      sec = Math.abs(Math.round(diff));
      if(sec > 60) {
        min = Math.abs(Math.round(sec / 60));
        if(min > 60) {
          hours = Math.abs(Math.round(min / 60))
          if(hours > 24) {
            day = Math.abs(Math.round(hours / 24))
            if(day > 30) {
              month = Math.abs(Math.round(day / 30))
              if(month > 12) {
                year = Math.abs(Math.round(month / 12))
                return year  + ' жилийн өмнө';
              } else {
                return month  + ' сарын өмнө';
              }
            } else {
                return day  + ' өдрийн өмнө';
            }
          } else {
            return hours  + ' цагийн өмнө';
          }
        } else {
          return min  + ' минутын өмнө';
        }
      } else {
        return 'хэдэн секундын өмнө';
      }
    },
    upgradeWpm() {
      var rts = this;
      const token = localStorage.getItem('token');
      this.$axios({
        method: 'post',
        url: this.$appUrl+'/api/user/upgrade-gift-race-wpm',
        headers: {
          "Authorization": `Bearer ${token}`
        },
        data: {
          raceId: this.status.raceId,
          wpm: parseInt((this.counter.characters / 5) / (this.time.time_passed / 60))
        }
      })
      .then(function(data){
        rts.tops.alltime = data.data.data;
        rts.upgradePoint(5);
      }).catch(error => {
          if(error.response.status == 402) {
            rts.$router.push('/payment');
          }
      });
    },
    startGame() {
      clearInterval(timer);
      this.time.start = true;
      timer = setInterval(this.updateTimer, 1000);
    }, 
    joinRoom(roomId) {
      this.status.inviteConrfirm = true;
        var rts = this;
        this.$socket.emit('joinRoom', {
            roomId: roomId
        });
    },
    IgnoreRoom(roomId, userId) {
      this.status.inviteConrfirm = true;
      this.$socket.emit('ignoreRoom', {
        roomId: roomId,
        userId: userId
      });
    },
    confirmInvite(event) {
        let roomId = event.target.classList[event.target.classList.length - 1];
        this.joinRoom(roomId);
        this.$router.push('/room');
      },
    ignoreInvite(event) {
      let roomId = event.toElement.classList[event.toElement.classList.length - 2];
      let fromUserId = event.toElement.classList[event.toElement.classList.length - 1];
      this.IgnoreRoom(roomId, fromUserId);
    }
  },
  beforeRouteLeave (to, from , next) {
     clearInterval(timer);
     clearInterval(countdown);
     next();
  },
  sockets: {
    inviteGame: function(data) {
      this.status.inviteConrfirm = false;
      var rts = this;
      let user = localStorage.getItem('user');
      if(user) {
        var id = JSON.parse(localStorage.getItem('user'));
        if(id.id === data.to) {
          const h = this.$createElement;
          rts.$notify({
            title: 'Урилга',
            showClose: false,
            dangerouslyUseHTMLString: true,
            duration: 6000,
            onClick: function() {
              this.close();
            },
            onClose: function() {
              if(rts.status.inviteConrfirm === false) {
                rts.IgnoreRoom(data.roomId, data.fromId);
              }
            },
            message: h('div', { 
              class: 'notify1' 
              }, [
                    h('div', 
                      { 
                        class: 'notify2'
                      }, data.from + ' таныг уралдаанд урьж байна'),
                      h('div', {
                          class: 'notify3'
                        }, [
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-9 text-white ${data.roomId}`,
                                  on: {
                                    click: rts.confirmInvite.bind()
                                  }
                                }, 'Зөвшөөрөх'
                              ),
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-6 text-white ${data.roomId} ${data.fromId}`,
                                  on: {
                                    click: rts.ignoreInvite.bind()
                                  }
                                }, 'Цуцлах'
                              )
                      ])
                    ])
          });

          
        }
      }
    }
  }
};
</script>
