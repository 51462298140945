<template></template>
<script>
export default {
    async check_auth(RenderedVue) {
        
        var user = localStorage.getItem('user');
        var result;

        if(user != null) {
            var user = JSON.parse(localStorage.getItem('user'));
            
            result = await RenderedVue.$axios.get(RenderedVue.$appUrl+'/api/user/auth-with-refresh-token', {
            params: {
                refresh_token: user.refresh_token
            }
            })
            .then(function(data){
                if(data.data.status === 200) {
                    localStorage.token = data.data.data[0].token;
                    localStorage.active = data.data.data[0].active;
                    localStorage.user = JSON.stringify(data.data.data[0]);
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    localStorage.removeItem('active');
                    RenderedVue.$router.push('/login');
                }
            }).catch(error => {
                console.log(error);
            });
            
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('active');
            RenderedVue.$router.push('/login');
        }
    }
}
</script>