<style scoped>
.wrongLine{
  text-decoration: line-through;
}
.chatbox {
  width: 400px;
  height: 400px;
  display: flex;
  flex-flow: column;
  border-radius: 10px 10px 0 0;
  background: white;
  transition: .1s ease-out;
}

.chatbox-top {
  position: relative;
  display: flex;
  padding: 15px 0;
  border-radius: 10px 10px 0 0;
  background: rgba(0, 0, 0, .05);
}

.chat-partner-name {
  flex: 1;
  font-size: 15px;
  font-weight: bold;
  color: #30649c;
  text-shadow: 1px 1px 0 white;
  transition: .1s ease-out;
  cursor: pointer;
}

.chatbox-min {
  height: 50px;
}
</style>
<template>
    <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
        <div class="col-span-12 lg:col-span-8">
            <div class="intro-y box p-5 mt-12 sm:mt-5" style="height: 300px;">
            <div class="flex flex-col xl:flex-row xl:items-center">
                <div class="intro-y" @click="setFocus" :style="{'width': '100%', 'height': '300px', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': '700px 350px', 'cursor': 'auto'}" ref="fingerBack">
                    
                    <div v-for="(users, index) in room.players" class="mb-2 wizard flex-col justify-center lg:flex-row flex px-5"
                    :key="index">
                            <div class="intro-x lg:block flex-1 z-10" :style="{'padding-left': `${users.percent}%`}">
                                <Tippy tag="div" class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden" :content="users.name">
                                    <img    
                                    :src="`${users.image}`"
                                    />
                                </Tippy>
                            </div>
                        <div class="wizard__line hidden lg:block w-full bg-gray-200 dark:bg-dark-1 absolute mt-5" align="right"
                        >
                        <!-- <br>
                        <span v-if="users.place != 0">{{users.place}}-р байр</span> -->
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-span-12 lg:col-span-1">
            <div class="intro-y box p-5 mt-5" style="height: 300px;">
                <div class="flex flex-col xl:flex-row xl:items-center">
                    <div class="intro-y" @click="setFocus" :style="{'width': '100%', 'height': '300px', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': '700px 350px', 'cursor': 'auto'}" ref="fingerBack">
                        
                        <div v-for="(users, index) in room.players" class="mb-2 wizard flex-col justify-center lg:flex-row flex px-5"
                        :key="index">
                                <div class="intro-x lg:block flex-1 z-10">
                                    <Tippy v-if="users.place != 0" tag="div" class="w-10 h-10 flex-none image-fit overflow-hidden" :content="users.name">
                                        <img    
                                        :src="`${require(`@/assets/images/rank/${users.place}.png`)}`"
                                        />
                                    </Tippy>
                                    <Tippy v-else-if="users.place == 0" tag="div" class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden" :content="users.name">
                                        <!-- <img    
                                        :src="`${require(`@/assets/images/rank/no-rank.png`)}`"
                                        /> -->

                                        <div
                                            class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                                        >
                                            <LoadingIcon icon="three-dots" class="w-8 h-8" />
                                        </div>
                                    </Tippy>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-span-12 lg:col-span-3">
            <div class="col-span-12 sm:col-span-12 lg:col-span-12">
                <div class="intro-y box p-5 mt-5" style="height: 300px;">
                    <ReportDonutChart :height="210" class="mt-3" style="margin-top: 0px;" :now="this.time.time_passed" :left="this.time.time_left" />
                <div
                    class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0"
                    style="font-size: 60px;"
                >
                    <p class="-mt-10">{{this.time.time_left}}</p>
                </div>

                <div class="mt-3">
                    <div class="flex items-center">
                        <div class="w-2 h-2 bg-theme-9 rounded-full mr-3"></div>
                        <span class="truncate">CPM</span>
                        <div
                        class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                        ></div>
                        <span class="font-medium xl:ml-auto">{{ this.counter.characters }}</span>
                    </div>

                    <div class="flex items-center">
                        <div class="w-2 h-2 bg-theme-6 rounded-full mr-3"></div>
                        <span class="truncate">WPM</span>
                        <div
                        class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                        ></div>
                        <span class="font-medium xl:ml-auto">{{parseInt((this.counter.characters / 5) / ((180 - this.time.time_left) / 60))}}</span>
                    </div>
                </div>
                </div>
            </div>
        </div>

    <div class="col-span-12 lg:col-span-12">
        <div class="intro-y box p-5 mt-12 sm:mt-5">
          <div class="flex flex-col xl:flex-row xl:items-center">
            <div class="flex" @click="setFocus" :style="{'width': '100%', 'height': '100px', 'background-repeat': 'no-repeat', 'background-position': 'center', 'cursor': 'auto'}" ref="fingerBack">
                <div style="width:100%; height: 100px; margin: auto; display: flex; flex-wrap: nowrap;">
                    <div style="width: 50%; text-align: right; line-height: 100px; font-size: 50px; text-align: right; white-space: nowrap; overflow: hidden;">
                        <span class="typed-text-content" ref="typedTextContent" v-html="build"></span>
                        <div style="display: inline-block; text-align: center; line-height: 100px; font-size: 50px; outline-width: 0; min-width: 10px; color: #6f8fc9; " @keypress.enter.prevent :contenteditable="this.status.contentEdit" @input="this.type" id="testInputDiv" ref="testInputDiv"></div>
                    </div>
                    
                    <div style="width: 50%; line-height: 100px; font-size: 50px; text-align: left; overflow: hidden; white-space: nowrap;" class="main-text-content" ref="mainTextContent">
                      <span style="margin-right: 15px;">{{this.spans.span1}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span2}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span3}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span4}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span5}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span6}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span7}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span8}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span9}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span10}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span11}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span12}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span13}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span14}}</span>
                      <span style="margin-right: 15px;">{{this.spans.span15}}</span>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div id="finish_modal" ref="finish_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false" @click.self="hiddenResultModal">
     <div class="modal__content">
         <div class="p-5 text-center">
             <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
             <div class="text-3xl mt-5">Амжилттай!</div>
             <div class="grid grid-cols-12">
               <div class="col-span-12 xl:col-span-12 mt-6">
                <div class="text-gray-600 mt-2"> Та <span style="font-weight: 700;">{{parseInt((this.counter.characters / 5) / ((180 - this.time.time_left) / 60))}} WPM ({{this.counter.characters}} CPM)</span> бичсэн байна. Таны чанарын үзүүлэлт <span style="font-weight: 700;">{{ this.counter.accuracy }}%</span> байна. Амжилт хүсье!.</div>
               </div>
             </div>
         </div>
         <div class="px-5 pb-8 text-center"><button type="button" data-dismiss="modal" @click="hiddenResultModal" class="button w-24 bg-theme-1 text-white"> За </button> </div>
     </div>
    </div>

    <div id="all_finish_modal" ref="finish_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false" @click.self="hiddenResultModal">
     <div class="modal__content">
         <div class="p-5 text-center">
             <WatchIcon class="w-16 h-16 text-theme-7 mx-auto mt-3" />
             <div class="text-3xl mt-5">Цаг дууслаа!</div>
             <div class="grid grid-cols-12">
               <div class="col-span-12 xl:col-span-12 mt-6">
                <div class="text-gray-600 mt-2"> Та <span style="font-weight: 700;">{{parseInt((this.counter.characters / 5) / ((180 - this.time.time_left) / 60))}} WPM ({{this.counter.characters}} CPM)</span> бичсэн байна. Таны чанарын үзүүлэлт <span style="font-weight: 700;">{{ this.counter.accuracy }}%</span> байна. Амжилт хүсье!.</div>
               </div>
             </div>
         </div>
         <div class="px-5 pb-8 text-center"><button type="button" data-dismiss="modal" @click="hiddenResultModalAll" class="button w-24 bg-theme-1 text-white"> За </button> </div>
     </div>
    </div>

    <div id="all_finish_modal_without" ref="finish_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false" @click.self="hiddenResultModal">
     <div class="modal__content">
         <div class="p-5 text-center">
             <WatchIcon class="w-16 h-16 text-theme-7 mx-auto mt-3" />
             <div class="text-3xl mt-5">Уралдаан дууслаа!</div>
             <div class="grid grid-cols-12">
               <div class="col-span-12 xl:col-span-12 mt-6">
               </div>
             </div>
         </div>
         <div class="px-5 pb-8 text-center"><button type="button" data-dismiss="modal" @click="hiddenResultModalAll" class="button w-24 bg-theme-1 text-white"> За </button> </div>
     </div>
    </div>



    <div class="chatbox fixed bottom-0 left-0 shadow-md ml-10 box border z-50" v-bind:class="{'chatbox-min': chatMinus}">
        <div class="chatbox-top" @click="MinusOrMaximum">
          <div v-if="room.chatNotify == true" class="w-6 h-6 bg-theme-6 absolute rounded-full border-2 border-white" :style="{'right': '-5px', 'top': '-5px'}"></div>
          <div class="chat-partner-name" align="center">
            <h2 class="font-medium text-base">Group chat</h2>
          </div>    
        </div>
        <div class="chat-messages">
          <div class="chat__box box" :style="{'height': '350px'}">
                <!-- BEGIN: Chat Active -->
                <div class="h-full flex flex-col">
                  <div class="overflow-y-scroll scrollbar-hidden px-5 pt-5 flex-1" :style="{'max-height': '100%', 'padding-bottom': '80px'}" ref="pageBackground">
                    <div v-for="(items, index) in room.chats" :key="index">
                      <div v-if="items.user.id == room.me" class="chat__box__text-box flex items-end float-right mb-4">
                        <div
                          class="bg-theme-1 px-4 py-3 text-white rounded-l-md rounded-t-md"
                        >
                          {{items.message}}
                        </div>
                        <div
                          class="w-10 h-10 hidden sm:block flex-none image-fit relative ml-5"
                        >
                          <img
                            :alt="items.user.name"
                            class="rounded-full"
                            :src="items.user.image"
                          />
                        </div>
                      </div>

                      <div v-else class="chat__box__text-box flex items-end float-left mb-4">
                        <div
                          class="w-10 h-10 hidden sm:block flex-none image-fit relative mr-5"
                        >
                          <img
                            alt="Midone Tailwind HTML Admin Template"
                            class="rounded-full"
                            :src="items.user.image"
                          />
                        </div>
                        <div
                          class="bg-gray-200 dark:bg-dark-5 px-4 py-3 text-gray-700 dark:text-gray-300 rounded-r-md rounded-t-md"
                        >
                          <div class="mt-1 text-xs text-gray-600" align="left">{{items.user.name}}</div>
                          {{items.message}}
                        </div>
                      </div>
                      <div class="clear-both"></div>
                    </div>
                    
                  </div>
                  <div
                    class="pt-4 pb-10 sm:py-4 flex items-center border-t border-gray-200 dark:border-dark-5"
                  >
                    <input
                      type="text"
                      class="chat__box__input input dark:bg-dark-3 w-full h-8 resize-none border-transparent px-5 py-3 focus:shadow-none"
                      rows="1"
                      placeholder="50 тэмдэгтэнд багтааж бичнэ үү"
                      maxlength="50"
                      v-model="room.message"
                      @keyup.enter="sendMessage"
                    />
                    <a
                      href="javascript:;"
                      @click="sendMessage"
                      class="w-8 h-8 block bg-theme-1 text-white rounded-full flex-none flex items-center justify-center mr-5"
                    >
                      <SendIcon class="w-4 h-4" />
                    </a>
                  </div>
                </div>
              </div>
        </div>
      </div>

  </div>
</template>
<script>
import ReportLineChart from "@/components/ReportLineChart";
import ReportDonutChart from "@/components/ReportDonutChart";
import ReportPieChart from "@/components/ReportPieChart";
import ReportMap from "@/components/ReportMap";
import ReportDonutChart1 from "@/components/ReportDonutChart1";
import SimpleLineChart1 from "@/components/SimpleLineChart1";
import VueSlickCarousel from "vue-slick-carousel";

let timer = null;
let notifySound;
let correctSound;
let errorSound;
export default {
    components: {
        ReportDonutChart
    },
    computed: {
        build: function() {
        return this.texts.typed;
        }
    },
    mounted() {
        notifySound = new Audio(require(`@/assets/sound/notification/notification.mp3`));
        correctSound = new Audio(require(`@/assets/sound/key/correct.mp3`));
        errorSound = new Audio(require(`@/assets/sound/key/error.mp3`));
        this.setinfo();
        let user = JSON.parse(localStorage.getItem('user'));
        this.room.me = user.id;
        // this.begin();
    },
    data() {
        return {
            chatMinus: true,
            room: {
                id: '',
                players: [],
                start: false,
                chats: [],
                message: '',
                me: 0,
                chatNotify: false
            },
            texts: {
                original: '',
                typed : '',
                now: '',
                splitted: '',
                splittedOneWord: [],
                currentWord: ''
            },
            info: {
                accuracy: 0,
                wpm: 0,
                record: 0,
                rank_name: '',
                rank_img: 'beginner.png',
                point: 0
            },
            counter: {
                space: 0,
                typed: 0,
                errors: 0,
                accuracy: 0,
                characters: 0,
                words: 0
            },
            status: {
                contentEdit: false,
                lang: 'Мон',
                finger : true,
                endPlayer: false,
                start: false
            },
            time: {
                time_left: 0,
                time_passed: 0,
                start: false
            },
            spans: {
                span1: '',
                span2: '',
                span3: '',
                span4: '',
                span5: '',
                span6: '',
                span7: '',
                span8: '',
                span9: '',
                span10: '',
                span11: '',
                span12: '',
                span13: '',
                span14: '',
                span15: ''
            }
        }
    },
    methods: {
        MinusOrMaximum() {
          if(this.chatMinus == false) {
            this.chatMinus = true;
          } else {
            this.chatMinus = false;
            this.room.chatNotify = false;
            this.$refs.pageBackground.scrollTop = this.$refs.pageBackground.scrollHeight;
          }
        },
        sendMessage() {
          if(this.room.message != '') {
            let user = JSON.parse(localStorage.getItem('user'));
            this.$socket.emit('chatRoomMessage', {
                  roomId: this.room.id,
                  user,
                  message: this.room.message
            });
            this.room.message = '';
          }
        },
        setinfo() {
            this.room.id = this.$route.params.roomId;
            this.checkRoom();
        },
        joinRoom() {
            var rts = this;
            this.$socket.emit('joinRoom', {
                roomId: rts.room.id
            });
            this.getPlayers();
        },
        checkRoom() {
            const token = localStorage.getItem('token');
            var rts = this;
            this.$axios({
                method: 'post',
                url: this.$appUrl+'/api/room/check-room',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    roomId: rts.room.id
                }
            }).then(function(data) {
                if(data.data.data > 0) {
                    rts.getText();
                    rts.joinRoom();
                }
            }).catch(error => {
                if(error.response.status == 402) {
                    rts.$router.push('/payment');
                }
            });
        },
        makeSpanText() {
            var current_content = '';
            this.texts.splitted = this.texts.original.split(' ');
            this.giveSpanText();    
        },
        getPlayers() {
            const token = localStorage.getItem('token');
            var rts = this;
            this.$axios({
                method: 'post',
                url: this.$appUrl+'/api/room/get-players',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    roomId: rts.room.id
                }
            }).then(function(data) {
                rts.room.players = data.data.data;
            }).catch(error => {
                if(error.response.status == 402) {
                    rts.$router.push('/payment');
                }
            });
        },
        getText() {
            const token = localStorage.getItem('token');
            this.counter.typed = 0;
            this.counter.space = 0;
            this.counter.errors = 0;
            this.counter.accuracy = 0;
            this.counter.characters = 0;
            this.time.time_passed = 0;
            this.time.start = false;
            this.texts.typed = '';
            this.$refs.testInputDiv.innerHTML = '';

            var rts = this;

            this.$axios({
                method: 'post',
                url: rts.$appUrl +'/api/text/selected-text',
                headers: {
                    'Authorization': `Bearer ${token}`
                }, 
                data: {
                    roomId: rts.room.id
                }
            })
            .then(function(data){
                rts.texts.original = data.data.text;
                rts.makeSpanText();
            }).catch(error => {
            if(error.response.status == 402) {
                rts.$router.push('/payment');
            }
            });
        },
        giveSpanText(){
            this.spans.span1 = '';
            this.spans.span2 = '';
            this.spans.span3 = '';
            this.spans.span4 = '';
            this.spans.span5 = '';
            this.spans.span6 = '';
            this.spans.span7 = '';
            this.spans.span8 = '';
            this.spans.span9 = '';
            this.spans.span10 = '';
            this.spans.span11 = '';
            this.spans.span12 = '';
            this.spans.span13 = '';
            this.spans.span14 = '';
            this.spans.span15 = '';
            

            if(this.counter.space < this.texts.splitted.length) {
                for(let i=this.counter.space; i < this.texts.splitted.length; i++){
                switch(i) {
                    case this.counter.space : this.spans.span1 = this.texts.splitted[i]; break;
                    case this.counter.space + 1 : this.spans.span2 = this.texts.splitted[i]; break;
                    case this.counter.space + 2: this.spans.span3 = this.texts.splitted[i]; break;
                    case this.counter.space + 3: this.spans.span4 = this.texts.splitted[i]; break;
                    case this.counter.space + 4: this.spans.span5 = this.texts.splitted[i]; break;
                    case this.counter.space + 5: this.spans.span6 = this.texts.splitted[i]; break;
                    case this.counter.space + 6: this.spans.span7 = this.texts.splitted[i]; break;
                    case this.counter.space + 7: this.spans.span8 = this.texts.splitted[i]; break;
                    case this.counter.space + 8: this.spans.span9 = this.texts.splitted[i]; break;
                    case this.counter.space + 9: this.spans.span10 = this.texts.splitted[i]; break;
                    case this.counter.space + 10: this.spans.span11 = this.texts.splitted[i]; break;
                    case this.counter.space + 11: this.spans.span12 = this.texts.splitted[i]; break;
                    case this.counter.space + 12: this.spans.span13 = this.texts.splitted[i]; break;
                    case this.counter.space + 13: this.spans.span14 = this.texts.splitted[i]; break;
                    case this.counter.space + 14: this.spans.span15 = this.texts.splitted[i]; break;
                    default: break;
                }
                }
                this.splitWord();
            } else {
                this.finishGame();
            }
        },
        splitWord() {
            this.texts.splittedOneWord = this.texts.splitted[this.counter.space].split('');
            this.texts.currentWord =  this.texts.splittedOneWord[0];
        },
        hiddenResultModal() {
            document.getElementById("testInputDiv").focus();
        },
        hiddenResultModalAll() {
            this.$router.push('/races');
        },
        type(){
            var text = this.texts.splitted[this.counter.space];
            var input = this.$refs.testInputDiv.innerHTML.replace('&nbsp;','').replace('&amp;','&');
            
            var space = this.$refs.testInputDiv.innerHTML.substr(this.$refs.testInputDiv.innerHTML.length - 6, this.$refs.testInputDiv.innerHTML.length);

            var input_array = input.split('');
            var permission = true;
            

            for(let i=-1; i < input_array.length; i++) {
                if(input_array[i] != this.texts.splittedOneWord[i]) {
                permission = false; break;
                }
                this.texts.currentWord = this.texts.splittedOneWord[i + 1];
            }
                if(permission) {
                    correctSound.pause();
                    correctSound.currentTime = 0;
                    correctSound.play();
                    if(this.texts.splitted.length === this.counter.space + 1) {
                        if(input == this.texts.splitted[this.counter.space]) {
                            this.texts.typed = this.texts.typed +  '<span style="margin-right: 15px; color: #a3a0a0;">'+ input.replace('&nbsp;','') + '</span>';
                                this.counter.characters += text.length;
                                this.counter.typed++;

                                this.$refs.testInputDiv.innerHTML = '';
                                this.counter.space++;

                                this.counter.accuracy = parseInt(this.counter.typed / ( this.counter.typed + this.counter.errors )  * 100 );

                                this.giveSpanText();
                                this.SetWrite(parseInt(this.counter.space * 100 / this.texts.splitted.length));
                        } else {
                            this.$refs.testInputDiv.classList.remove('wrongLine');
                            this.spans.span1 = text.substr(input.length, text.length);
                        }
                    } else {
                        if(space === '&nbsp;'){

                            if(input != '') {
                                if(text === input.replace('&nbsp;','')) {
                                    this.texts.typed = this.texts.typed +  '<span style="margin-right: 15px; color: #a3a0a0;">'+ input.replace('&nbsp;','') + '</span>';
                                    this.counter.characters += text.length;
                                    this.counter.typed++;

                                    this.$refs.testInputDiv.innerHTML = '';
                                    this.counter.space++;

                                    this.counter.accuracy = parseInt(this.counter.typed / ( this.counter.typed + this.counter.errors )  * 100 );

                                    this.giveSpanText();
                                    
                                    this.SetWrite(parseInt(this.counter.space * 100 / this.texts.splitted.length));
                                }
                                
                            } else {
                                this.$refs.testInputDiv.innerHTML = '';
                            } 
                        }
                        else {
                            this.$refs.testInputDiv.classList.remove('wrongLine');
                            this.spans.span1 = text.substr(input.length, text.length);
                        }
                    }

                } else {
                    errorSound.pause();
                    errorSound.currentTime = 0;
                    errorSound.play();
                    this.$refs.testInputDiv.classList.add('wrongLine');
                }
            },
        setFocus() {
        this.$refs.testInputDiv.focus();
        },
        SetWrite(percent) {
            if(percent == 100) {
                clearInterval(timer);
            }
            this.$socket.emit('write', {
                roomId: this.room.id,
                percent: percent
          });
        },
        finishGame() {
            this.status.contentEdit = false;
            let wpm = parseInt((this.counter.characters / 5) / ((180 - this.time.time_left) / 60));
            let percent = this.counter.accuracy;

            this.$socket.emit('finishPlayer', {
                roomId: this.room.id,
                wpm: wpm,
                percent: percent
            });
            this.status.endPlayer = true;
            cash("#finish_modal").modal("show");
        },
        endInterval() {
            clearInterval(timer);
            if(this.status.endPlayer === false) {
                cash("#all_finish_modal").modal("show");
            } else {
                cash("#all_finish_modal_without").modal("show");
            }
        },
        timeUpdate() {
            this.time.time_left--;
            this.time.time_passed++;
        }
    },
    beforeRouteLeave (to, from , next) {
        localStorage.removeItem(this.room.id);
        clearInterval(timer);
        next();
    },
    sockets: {
        onMessage: function(data) {
            notifySound.pause();
            notifySound.currentTime = 0;
            notifySound.play();
            this.$refs.pageBackground.scrollTop = this.$refs.pageBackground.scrollHeight;
            if (data.user.id != this.room.me) {
                if(this.chatMinus == true) {
                this.room.chatNotify = true;
                }
            }

            let cookie = JSON.parse(localStorage.getItem(this.room.id)); let chts = [];
            if(cookie) {
                for (let i = 0; i < cookie.length; i++) {
                chts.push(cookie[i]);
                }
                chts.push(data);
                localStorage[this.room.id] = JSON.stringify(chts);
                this.room.chats = chts;
            } else {
                chts.push(data);
                localStorage[this.room.id] = JSON.stringify(chts);
                this.room.chats = chts;
            }
        },
        onWrite: function(data) {
            this.room.players.forEach(element => {
                if(element.id === data.user) {
                    element.percent = data.percent;
                }
            });
        },
        onFinish: function(data) {
            let user = JSON.parse(localStorage.getItem('user'));
            if(user.id == data.user) {
                user.point = data.point;
                user.avg_wpm = data.wpm;
                localStorage.user = JSON.stringify(user);
                this.$emit('SetPointFull', data.point);
            }
            this.room.players.forEach(element => {
                if(element.id === data.user) {
                    if (element.place === 0) {
                        element.place = data.place;
                    }
                }
            });
        },
        endGame: function(data) {
            this.endInterval();
        },
        Interval: function(data) {
            if(this.status.endPlayer === false) {
                this.status.contentEdit = data.start;
                if(data.start == true) {
                    if(this.status.start == false) {
                        this.time.time_left = data.time_left;
                        this.time.time_passed = data.time_passed;
                        this.status.start = true;
                        timer = setInterval(this.timeUpdate, 1000);
                    }
                } else {
                    this.time.time_left = data.time_left;
                    this.time.time_passed = data.time_passed;
                }
            }
            if(data.start) {
                if(this.chatMinus == true) {
                    this.$refs.testInputDiv.focus();
                }
            }
        }
    }
}
</script>