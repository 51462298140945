import Vue from "vue";
import VueRouter from "vue-router";
import TopMenu from "../layouts/TopMenu";
import ProfileOverview2 from "../views/ProfileOverview2";
import Login from "../views/Login";
import Register from "../views/Register";
import ErrorPage from "../views/ErrorPage";
import UpdateProfile from "../views/UpdateProfile";
import ChangePassword from "../views/ChangePassword";
import Beginner from "../views/Beginner";
import Middle from "../views/Middle";
import Advanced from "../views/Advanced";
import CreateRace from "../views/CreateRace";
import Room from "../views/Room";
import Races from "../views/Races";
import Race from "../views/Race";
import PaymentText from "../views/PaymentText";
import Ranking from "../views/GiftRace";
import RankingRooms from "../views/GiftRaceList";
import Helper from "../views/helpers";
import TermsCondition from "../views/TermsCondition";
import LessonList from "../views/LessonList";
import Boxed from "../views/Boxed";
import successLesson from "../views/successLesson";
import introduction from "../views/introduction";
import review from "../views/Review";
import holded from "../views/Holded";
import lessonStep from "../views/lessonStep";
import certificate from "../views/certificate";
import checkCerti from "../views/checkCerti";

function AuthCheck(to, from, next) {
  var token = localStorage.getItem("token");
  if (token) {
    next();
  } else {
    next();
  }
}

function LoginCheck(to, from, next) {
  var isAuthenticated = true;
  var token = localStorage.getItem("token");
  if (token) {
    next("/beginner");
  } else {
    next();
  }
}

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: TopMenu,
    beforeEnter: AuthCheck,
    children: [
      {
        path: "/certificate",
        name: "top-menu-certificate",
        meta: {
          title: 'СЕРТИФИКАТ',
        },
        component: certificate
      },
      {
        path: "/lesson-step/:id",
        name: "top-menu-step-lesson",
        meta: {
          title: 'Хурдан хуруу',
        },
        component: lessonStep
      },
      {
        path: "holded/:id",
        name: "top-menu-holded-lesson",
        meta: {
          title: 'Хурдан хуруу',
        },
        component: holded
      },
      {
        path: "review/:id",
        name: "top-menu-review-lesson",
        meta: {
          title: 'Хурдан хуруу'
        },
        component: review
      },
      {
        path: "intro/:id",
        name: "top-menu-intro-lesson",
        meta: {
          title: 'Хурдан хуруу'
        },
        component: introduction
      },
      {
        path: "/result/:prev",
        name: "top-menu-success-lesson",
        meta: {
          title: 'Хурдан хуруу'
        },
        component: successLesson
      },
      {
        path: "/boxed/:id",
        name: "top-menu-boxed-lesson",
        meta: {
          title: 'Хурдан хуруу'
        },
        component: Boxed
      },
      {
        path: "/lesson",
        name: "top-menu-lesson",
        meta: {
          title: "Хичээл"
        },
        component: LessonList
      },
      {
        path: "/payment",
        name: "top-menu-payment",
        meta: {
          title: 'Төлбөр'
        },
        component: PaymentText
      },
      {
        path: "/",
        name: "top-menu-beginner",
        meta: {
          title: 'Жижиг үсэг'
        },
        component: Beginner
      },
      {
        path: "/help",
        name: "top-menu-help",
        meta: {
          title: 'Тусламж & Ашиглах заавар'
        },
        component: Helper
      },
      {
        path: "/beginner",
        name: "top-menu-beginner",
        meta: {
          title: 'Жижиг үсэг'
        },
        component: Beginner
      },
      {
        path: "/profile",
        name: "top-menu-profile-overview-2",
        meta: {
          title: 'Хувийн мэдээлэл'
        },
        component: ProfileOverview2
      },
      {
        path: "/update-profile",
        name: "top-menu-update-profile",
        meta: { 
          title: 'Хувийн мэдээлэл шинэчлэх'
        },
        component: UpdateProfile
      },
      {
        path: "/change-password",
        meta: {
          title: 'Нууц үг шинэчлэх'
        },
        name: "top-menu-change-password",
        component: ChangePassword
      },
      {
        path: "/ranking/:raceId",
        name: "top-menu-ranking",
        meta: {
          title: 'Шагналтай уралдаан'
        },
        component: Ranking
      },
      {
        path: "/ranking-rooms",
        name: "top-menu-ranking-rooms",
        meta: {
          title: 'Шагналтай уралдаан'
        },
        component: RankingRooms
      },
      {
        path: "/middle",
        name: "top-menu-middle",
        meta: {
          title: 'Том үсэг, цэг, таслал'
        },
        component: Middle
      },
      {
        path: "/advanced",
        name: "top-menu-advanced",
        meta: {
          title: 'Тоо, тэмдэгт'
        },
        component: Advanced
      },
      {
        path: "/create-race/:lang",
        name: "top-menu-create-race",
        meta: {
          title: 'Холбогдож тоглох'
        },
        component: CreateRace
      },
      {
        path: "/room",
        name: "top-menu-room",
        meta: {
          title: 'Холбогдож тоглох'
        },
        component: Room
      },
      {
        path: "/races",
        name: "top-menu-races",
        meta: {
          title: 'Онлайн уралдаан'
        },
        component: Races
      },
      {
        path: "/race/:roomId",
        name: "top-menu-race",
        meta: {
          title: 'Уралдаан'
        },
        component: Race
      },
    ]
  },
  {
    path: "/login",
    beforeEnter: LoginCheck,
    name: "login",
    meta: {
      title: 'Нэвтрэх'
    },
    component: Login
  },
  {
    path: "/register",
    beforeEnter: LoginCheck,
    name: "register",
    meta: {
      title: 'Бүртгүүлэх'
    },
    component: Register
  },
  {
    path: "/check-certificate",
    name: "check-certificate",
    meta: {
      title: 'Гэрчилгээ шалгах'
    },
    component: checkCerti
  },
  {
    path: "/terms",
    name: "terms",
    meta: {
      title: 'Үйлчилгээний нөхцөл'
    },
    component: TermsCondition
  },
  {
    path: "/error-page",
    name: "error-page",
    meta: {
      title: 'Алдаа'
    },
    component: ErrorPage
  },
  {
    path: "/*",
    meta: {
      title: 'Алдаа'
    },
    component: ErrorPage
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});
export default router;
