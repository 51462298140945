<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/css/font-awesome.min.css?fbclid=IwAR0AavK9LlUCRFV7QuuLBSLzicyCci_l8ryq0mWJgqwhP395fYjgFbEFwyo');

.right {
  float: right;
}

.left {
  float: left;
}

.rating .stars {
    position: absolute;
    text-align: center;
    margin-top: -65px;
}


.rating .stars .star:before {
  font-family: 'FontAwesome';
  content: '\f005';
  color: #d0d2d6;
  font-size: 25px;
}

.rating .stars .center {
    float: none;
    margin-top: 10px;
}

.rating .stars .star.to_rate:before,
.rating .stars .star.rated:before {
  color: orange;
}


.rating {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


.lesson-name {
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: -35px;
    /* border-top: 1px solid grey; */
    padding-top: 4px;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 8px;
    font-family: 'Exo 2', sans-serif;
}
.lesson-box {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
}
.lesson-index {
    font-family: 'Exo 2', sans-serif;
    font-size: 22px;
    font-weight: 700;
    position: absolute;
    padding-left: 15px;
    color: grey;
}
.lesson-title {
    font-size: 30px;
    font-weight: 700;
    color: grey;
    font-family: 'Exo 2', sans-serif;
}
.lesson-lock {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #d0d2d6;
}

.progress-title { 
    font-size: 18px;
    color: grey;
    font-family: 'Exo 2', sans-serif;
    padding-right: 10px;
}

.hide-box {
    position: absolute;
}
</style>
<template>
    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6 pt-10">
            <div class="col-span-12 grid grid-cols-12 gap-6 progress-title" :style="{'padding-left': '15%', 'padding-right': '15%'}">
                <div class="col-span-12 sm:col-span-12 xxl:col-span-12 intro-y">
                    <span class="progress-title">Гүйцэтгэл <span :style="{'color': 'black', 'font-weight': '700'}">{{this.userProcess}}%</span></span><span :style="{'border-left': '1px solid grey', 'padding-left': '10px', 'padding-right': '10px'}"><span :style="{'color': 'black', 'font-weight': '700'}">{{this.userStar}}</span> од</span><span :style="{'border-left': '1px solid grey', 'padding-left': '10px'}"><span :style="{'color': 'black', 'font-weight': '700'}">{{Number(userPoint).toLocaleString()}}</span> оноо</span>
                </div>
            </div>
            <div class="col-span-12 grid grid-cols-12 gap-6 listScroll">
                <div v-for="(title, titleIndex) in lessonGroup" :key="titleIndex" class="col-span-12 grid grid-cols-12 gap-6" :style="{'padding-left': '15%', 'padding-right': '15%'}">
                    <div class="col-span-12 sm:col-span-12 xxl:col-span-12 intro-y">
                        <span class="lesson-title">{{title.groupName}}</span>
                    </div>
                    <div v-for="(less, lessIndex) in lessons" :key="lessIndex" v-bind:class="{'col-span-12 sm:col-span-2 xxl:col-span-2 intro-y': less.groupId == title.id, 'hide-box': less.groupId != title.id}">
                        <div v-if="less.groupId == title.id" class="box zoom-in" :style="{'position': 'relative'}" @click="startLess(less.id)">
                            <div class="lesson-index">{{less.lvl}}</div>
                            <div class="lesson-lock" v-if="less.lvl > (currentUserLesson + 1)"><LockIcon/></div>
                            <div class="lesson-box">
                                <img :src="`${$appUrl}/images/lessonBox/${less.image}`" :style="{'width': '150px', 'height': 'auto', 'margin-top': '22%', 'margin-bottom': '28%'}">
                            </div>
                            <div class="rating" :style="{'overflow': 'hidden'}">
                                <div class="stars" align="center" :style="{'width': '100%'}">
                                    <a v-for="(i, l) in checkUserLesson(less.id)" :key="l" class="star rated"></a>
                                    <a v-for="(i, l) in (5 - checkUserLesson(less.id))" :key="'A'+l" class="star"></a>
                                </div>
                            </div>
                            <div class="lesson-name">{{less.lessonname}}</div>
                        </div>
                    </div>               
                </div>
            </div>
            <div id="err_modal" ref="err_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div class="modal__content">
                    <div class="p-5 text-center">
                        <LockIcon class="w-12 h-12 mx-auto mt-3" :style="{'color': 'grey'}"/>
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 xl:col-span-12 mt-2" :style="{'border-bottom': '1px solid #d0d2d6', 'padding-bottom': '20px'}">
                                <div class="text-gray-600 mt-2 text-xl"> Өмнөх хичээлээ дуусгана уу </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 pb-8 text-center"><button type="button" data-dismiss="modal" class="button w-24 bg-theme-1 text-white"> OK </button> </div>
                </div>
            </div>  
            <div id="login_modal" ref="login_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div class="modal__content">
                    <div class="p-5 text-center">
                        <LockIcon class="w-12 h-12 mx-auto " :style="{'color': 'grey'}"/>
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 xl:col-span-12 mt-2" :style="{'border-bottom': '1px solid #d0d2d6', 'padding-bottom': '20px'}">
                                <div class="text-gray-600 mt-2 text-xl"> Та нэвтэрсний дараа хичээлээ үргэлжлүүлнэ үү. </div>
                                <div class="p-5 grid grid-cols-12 gap-4 row-gap-3">
                                <div class="col-span-12 sm:col-span-12"> <input type="email" v-model="login.Uname" required class="input w-full border mt-2 flex-1" placeholder="И-мэйл хаяг" /> </div>
                                <div class="col-span-12 sm:col-span-12"> <input type="password" v-model="login.Upass" required class="input w-full border mt-2 flex-1" placeholder="Нууц үг" /> </div>

        </div>
    </div>
</div>
</div>
<div class="intro-x mt-5 xl:mt-1 text-center pb-6">
                <button
                  class="button button--lg w-full xl:w-40 text-white bg-theme-1 xl:mr-3 align-top"
                  :disabled='texts.isDisabled'
                  v-html="buttontext"
                  @click="Login"
                >
                </button>
                
                <button
                  class="button button--lg w-full xl:w-40 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
                  @click="toRegister"
                >
                  Бүртгүүлэх
                </button>
            </div>
                </div>
            </div>  

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            row: {
                index: 0
            },
            login:{
                Uname:'',
                Upass:''
            },
            texts:{
                disabled:false
            },
            buttontext:"нэвтрэх",
            lessons: [],
            lessonGroup: [],
            userLesson: [],
            currentUserLesson: 0,
            userStar: 0,
            userProcess: 0,
            userPoint : 0
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        toRegister(){
            cash("#login_modal").modal("hide");
            this.$router.push("/register");
        },
        Login() {
        var rts = this;
        rts.texts.disabled = true;
        this.buttontext = 'Уншиж байна..';
        this.$axios({
          method: 'POST',
          url: rts.$appUrl +'/api/user/login',
          headers: {
            'Content-Type': 'application/json'
          }, 
          data: {
            email: this.login.Uname,
            password: this.login.Upass
          }
        }).then(function(data){
            rts.buttontext = 'Нэвтрэх';
            rts.texts.disabled = false;
            if(data.data.result === 'fail') {

              rts.$msgbox({
                message: data.data.data,
                type: 'error'
              });

            } else if(data.data.result === 'success') {
                cash("#login_modal").modal("hide");
              rts.$msgbox({
                message: 'Системд амжилттай нэвтэрлээ.',
                type: 'success'
              });

              // rts.$axios.defaults.headers.common.authorization = `Bearer ${data.data.data[0].token}`;
              localStorage.token = data.data.token;
              localStorage.user = JSON.stringify(data.data.data);
              // rts.$router.push("/beginner");
              setTimeout(() => {
                window.location.reload();

              }, 1000);
            }
        });
    },
        Loading(val) {
            if(val == true) {
                this.$loading({
                lock: true,
                text: 'Түр хүлээнэ үү',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
                });
            } else {
                this.$loading().close();
            }
        },
        checkUserLesson(id) {
            var score = 0;
            let ex = this.userLesson.some(function(field) {
                return field.lessonId === id
            });

            if(!ex) {
                return score;
            } else {
                this.userLesson.forEach(element => {
                    if(element.lessonId == id) {
                        score = element.score;
                    }
                });
                
                if (score > 160) {
                    // this.userStar = this.userStar + 5;
                    return 5;
                } else if(score > 120) {
                    // this.userStar += 4;
                    return 4;
                } else if (score > 80) {
                    // this.userStar += 3;
                    return 3;
                } else if(score > 40) {
                    // this.userStar += 2;
                    return 2;
                } else if (score > 0) {
                    // this.userStar += 1;
                    return 1;
                } else {
                    return 0;
                }
            }
        },
        getList() {
            var rts = this;
            const token = localStorage.getItem('token');
            if(token) {
                this.Loading(true);
                this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/get-lesson-list',
                    headers: {
                    "Authorization": `Bearer ${token}`
                    }
                })
                .then(function(data){
                    rts.Loading(false);
                    rts.lessons = data.data.lesson;
                    rts.lessonGroup = data.data.lessonGroup;
                    rts.userLesson = data.data.userlesson;
                    rts.currentUserLesson = data.data.user.lesson;

                    rts.userProcess = parseInt((data.data.userlesson.length * 100) / data.data.lesson.length);
                    

                    data.data.star.forEach(element => {
                        var star = parseInt(element.score / 40);
                        var sc = element.score % 40;
                        if(sc > 0) {
                            star = star + 1;
                        }
                        rts.userStar = rts.userStar + star;
                        rts.userPoint = rts.userPoint + element.score;
                    });
                    
                }).catch(error => {
                    rts.Loading(false);
                    if(error.response.status == 402) {
                        rts.$router.push('/payment');
                    }
                });
            }else{
                this.Loading(true);
                this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/get-lesson-list',
                })
                .then(function(data){
                    rts.Loading(false);
                    rts.lessons = data.data.lesson;
                    rts.lessonGroup = data.data.lessonGroup;
                    rts.currentUserLesson = 30;

                    data.data.star.forEach(element => {
                        var star = parseInt(element.score / 40);
                        var sc = element.score % 40;
                        if(sc > 0) {
                            star = star + 1;
                        }
                        rts.userStar = rts.userStar + star;
                        rts.userPoint = rts.userPoint + element.score;
                    });
                    
                }).catch(error => {
                    rts.Loading(false);
                    if(error.response.status == 402) {
                        rts.$router.push('/payment');
                    }
                });
            }
        },
        startLess(id) {
        const token=localStorage.getItem("token");
        if(token){
            var selected;
            this.lessons.forEach(element => {
                if (element.id == id) {
                    selected = element;
                }
            });
            if(selected.lvl > this.currentUserLesson + 1) {
                cash("#err_modal").modal("show");
            } else {
                if(selected.type == 'boxed') {
                    this.$router.push({name: 'top-menu-boxed-lesson', params: {id: selected.id}});
                } else if(selected.type == 'intro') {
                    this.$router.push({name: 'top-menu-intro-lesson', params: {id: selected.id}});
                } else if(selected.type == 'review') {
                    this.$router.push({name: 'top-menu-review-lesson', params: {id: selected.id}});
                } else if(selected.type == 'hold') {
                    this.$router.push({name: 'top-menu-holded-lesson', params: {id: selected.id}});
                }
                else if(selected.type == 'start') {
                    this.$router.push({name: 'top-menu-step-lesson', params: {id: selected.id}});
                }
                else {
                    console.log(selected.type);
                }
            }
        }else{
            if(id==76){
                cash("#login_modal").modal("show");
            }else{
                var selected;
            this.lessons.forEach(element => {
                if (element.id == id) {
                    selected = element;
                }
            });
            if(selected.lvl > this.currentUserLesson + 1) {
                cash("#err_modal").modal("show");
            } else {
                if(selected.type == 'boxed') {
                    this.$router.push({name: 'top-menu-boxed-lesson', params: {id: selected.id}});
                } else if(selected.type == 'intro') {
                    this.$router.push({name: 'top-menu-intro-lesson', params: {id: selected.id}});
                } else if(selected.type == 'review') {
                    this.$router.push({name: 'top-menu-review-lesson', params: {id: selected.id}});
                } else if(selected.type == 'hold') {
                    this.$router.push({name: 'top-menu-holded-lesson', params: {id: selected.id}});
                }
                else if(selected.type == 'start') {
                    this.$router.push({name: 'top-menu-step-lesson', params: {id: selected.id}});
                }
                else {
                    console.log(selected.type);
                }
            }
            }
        }
        }
    }
}
</script>