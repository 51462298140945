const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "top-menu-beginner",
        title: "Нүүр",
      },
      {
        icon: "PlayIcon",
        pageName: "top-menu-lesson",
        title: "Хичээл",
      },
      {
        icon: "UsersIcon",
        pageName: "top-menu-races",
        title: "Онлайн уралдаан"
      },
      {
        icon: "GiftIcon",
        pageName: "top-menu-ranking-rooms",
        title: "Шагналтай уралдаан"
      },
      {
        icon: "HelpCircleIcon",
        pageName: "top-menu-components",
        title: "Тусламж",
        subMenu: [
          {
            icon: "",
            pageName: "top-menu-payment",
            title: "Төлбөрийн мэдээлэл"
          },
          {
            icon: "",
            pageName: "top-menu-help",
            title: "Ашиглах заавар"
          }
        ]
      }
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
