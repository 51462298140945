<style scoped>
.wrongLine{
  text-decoration: line-through;
}
.notify1 {
  width: 280px;
}
.notify2 {
  text-align: right; 
  width: 100%;
}
.notify3 {
  margin-top: 8px;
  text-align: right;
}
</style>
<template>
  <div class="grid col-span-6">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Хувийн мэдээлэл</h2>
    </div>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
      >
        <div
          class="flex flex-1 px-5 items-center justify-center lg:justify-start"
        >
          <div
            class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative"
          >
            <img
              class="rounded-full"
              :src="this.user.image"
            />
          </div>
          <div class="ml-5">
            <div
              class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
            >
              {{this.user.name}}
            </div>
            <div class="text-gray-600">#{{this.user.id }}</div>
          </div>
        </div>
        <div
          class="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            <MailIcon class="w-4 h-4 mr-2" />
            {{ this.user.email }}
          </div>
          <div class="truncate sm:whitespace-normal flex items-center mt-3">
            <PhoneIcon class="w-4 h-4 mr-2" />
            {{ this.user.phone }}
          </div>
          <!-- <div class="truncate sm:whitespace-normal flex items-center mt-3">
            <InstagramIcon class="w-4 h-4 mr-2" /> Instagram
            {{ $f()[0].users[0].name }}
          </div> -->
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"
        >
          <div class="text-center rounded-md w-20 py-3">
            <img :src="`${$appUrl}/images/rank/${this.user.rank_image}`" :style="{'width': 'auto', 'height': '40px', 'margin': 'auto'}"/>
            <div class="text-gray-600">{{ this.user.rank_name }}</div>
          </div>
          <div class="text-center rounded-md w-20 py-3">
            <div class="font-semibold text-theme-1 dark:text-theme-10" :style="{'font-size': '27px'}">
              {{ this.user.point }}
            </div>
            <div class="text-gray-600">Point</div>
          </div>
          <div class="text-center rounded-md w-20 py-3">
            <div class="font-semibold text-theme-1 dark:text-theme-10" :style="{'font-size': '27px'}">
              {{ this.user.avg_wpm }}
            </div>
            <div class="text-gray-600">WPM</div>
          </div>
        </div>
      </div>
      <div
        class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start"
      >
        <router-link
          data-toggle="tab"
          data-target="#profile"
          to="/profile"
          class="py-4 sm:mr-8 flex items-center active"
        >
          <UserIcon class="w-4 h-4 mr-2" /> Хувийн мэдээлэл
        </router-link>
        <router-link
          data-toggle="tab"
          data-target="#account"
          to="/update-profile"
          class="py-4 sm:mr-8 flex items-center"
        >
          <ShieldIcon class="w-4 h-4 mr-2" /> Бүртгэл засварлах
        </router-link>
        <router-link
          data-toggle="tab"
          data-target="#change-password"
          to="/change-password"
          class="py-4 sm:mr-8 flex items-center"
        >
          <LockIcon class="w-4 h-4 mr-2" /> Нууц үг шинэчлэх
        </router-link>
        <a
          data-toggle="tab"
          data-target="#settings"
          href="javascript:;"
          class="py-4 sm:mr-8 flex items-center"
        >
          <SettingsIcon class="w-4 h-4 mr-2" /> Settings
        </a>
      </div>
    </div>
    <!-- END: Profile Info -->
    <div class="tab-content mt-5">
      <div id="profile" class="tab-content__pane active">
        <div class="grid grid-cols-12 gap-6">

          <!-- BEGIN: Latest Tasks -->
          <div class="intro-y box col-span-12 lg:col-span-9" :style="{'height': '700px', 'max-height': '700px', 'overflow-y': 'auto'}">
            <div
              class="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200 dark:border-dark-5"
            >
              
              <div class="nav-tabs ml-auto hidden sm:flex">
                <a
                  data-toggle="tab"
                  data-target="#latest-tasks-new"
                  href="javascript:;"
                  class="py-5 ml-6"
                  >Онлайн уралдаан</a
                >

                <a
                  data-toggle="tab"
                  data-target="#latest-tasks-race"
                  href="javascript:;"
                  class="py-5 ml-6 active"
                  >Шагналтай уралдаан</a
                >
              </div>
            </div>
            <div class="p-5">
              <div class="tab-content">
                <div id="latest-tasks-new" class="tab-content__pane">
                  <div v-for="(item, index) in lastRace" :key="index" class="flex items-center mt-5">
                    <div class="border-l-2 border-theme-1 pl-4">
                      <a href="" class="font-medium">#{{item.roomId}}</a>
                      <div class="text-gray-600">{{item.percent}}%</div>
                    </div>

                    <div class="pl-4 ml-auto" :style="{'font-size': '18px'}">
                      {{item.wpm}} WPM
                    </div>
                  
                    <div v-if="item.place != 0" class="w-10 h-10 flex-none ml-auto">
                        <img    
                          :src="`${require(`@/assets/images/rank/${item.place}.png`)}`"
                        />
                    </div>

                    <div v-else class="w-10 h-10 flex-none ml-auto image-fit rounded-full">
                        Гарсан
                    </div>
                  </div>
                </div>

                <div id="latest-tasks-race" class="tab-content__pane active">
                  <div v-for="(item, index) in lastMainRace" :key="index" class="flex items-center mt-5">
                    <div class="border-l-2 border-theme-1 pl-4">
                      <img :src="item.image" alt="" :style="{'width': '60px', 'height': 'auto'}">
                    </div>

                    <div class="pl-10" style="width: 30%;">
                      <a href="javascript:;" class="font-medium">{{item.name}}</a>
                      <div class="text-gray-600">#{{item.raceId}}</div>
                    </div>
                    
                    <div class="pl-10" style="width: 30%;">
                      <a href="javascript:;" class="font-medium">Уралдааны төлөв</a>
                      <div v-if="item.active == 1" class="text-gray-600">Идэхвхитэй</div>
                      <div v-else class="text-gray-600">Дууссан</div>
                    </div>

                     <div class="pl-10" style="width: 10%;">
                      <a href="javascript:;" class="font-medium">Хурд</a>
                      <div class="text-gray-600">{{item.wpm}} WPM</div>
                    </div>

                    <div class="pl-10 ml-auto" align="right">
                      <a href="javascript:;" class="font-medium">Оролцсон огноо</a>
                      <div class="text-gray-600">{{item.date.split('T')[0]}}</div>
                    </div>
                  <!--

                    <div v-else class="w-10 h-10 flex-none ml-auto image-fit rounded-full">
                        Гарсан
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Latest Tasks -->

          <!-- BEGIN: Work In Progress -->
          <div class="intro-y box col-span-12 lg:col-span-3" :style="{'height': '700px', 'max-height': '700px', 'overflow-y': 'auto'}">
            <div
              class="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto py-5">Онлайн хэрэглэгчид</h2>
            </div>
            <div class="p-5">
              <div class="tab-content">
                <div
                  v-for="(friends, index) in this.friends.list"
                  :key="index"
                  class="intro-y"
                >
                  <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
                    <div
                      class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
                    >
                      <img
                        :src="`${friends.image}`"
                      />
                    </div>
                    <div class="ml-4 mr-auto">
                      <div class="font-medium">{{ friends.name }}</div>
                      <div class="text-gray-600 text-xs">
                        #{{ friends.id }}
                      </div>
                    </div>
                    <div v-if="friends.online === 1"
                      class="w-4 h-4 bg-theme-9 absolute rounded-full border-2 border-white"
                        :style="{'right': '10px'}"
                    >
                    
                    </div>

                    <div v-else
                      class="w-4 h-4 bg-theme-7 absolute rounded-full border-2 border-white"
                      :style="{'right': '10px'}"
                    >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      friends: {
        list: []
      },
      user: {        
        name: '',
        id: '',
        phone: '',
        point: 0,
        image: 'default.png',
        rank_image: 'beginner.png',
        rank_name: 'Beginner',
        record_wpm: 0,
        avg_wpm: 0,
        avg_perent: 0,
        email: ''
      },
      status: {
        inviteConrfirm: false
      },
      lastRace: [],
      lastMainRace: []
    }
  },
  mounted() {
    this.GetUser();
  },
  methods: {
    leaveRoom() {
      this.$socket.emit('leaveRoom',{
          roomId: this.room.id
      });
    },
    prevNewProducts() {
      this.$refs["new-products"].prev();
    },
    getFriends() {
      const token = localStorage.getItem('token');
      var rts = this;
      if(token) {
        this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/user/get-friends',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(function(data) {
          rts.Loading(false);
          rts.friends.list = data.data.data;
        }).catch(error => {
          if(error.response.status == 402) {
            rts.$router.push('/text');
          }
        });
      }
    },
    nextNewProducts() {
      this.$refs["new-products"].next();
    },
    prevNewAuthors() {
      this.$refs["new-authors"].prev();
    },
    nextNewAuthors() {
      this.$refs["new-authors"].next();
    },
    getLastRace(){
      const token = localStorage.getItem('token');
      let rts = this;
      this.$axios({
        method: 'post',
        url: this.$appUrl+'/api/user/last-races',
        headers: {
            'Authorization': `Bearer ${token}`
          }
      }).then(function(data) {
        rts.lastRace = data.data.data;
        rts.lastMainRace = data.data.main;
        rts.getFriends();
      }).catch(error => {
          if(error.response.status == 402) {
            rts.$router.push('/text');
          }
      });
    },
    Loading(val) {
      if(val == true) {
        this.$loading({
          lock: true,
          text: 'Түр хүлээнэ үү',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      } else {
        this.$loading().close();
      }
    },
    GetUser() {
      const token = localStorage.getItem('token');
      this.Loading(true);
      var rts = this;
      this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/user/get-user-info',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(function(data) {
          if(data.data.result == 'success') {
            console.log(data);
            let info = data.data.data.info;
            rts.user.name = info.name;
            rts.user.image = info.image;
            rts.user.id = info.id;
            rts.user.phone = info.phone;
            rts.user.email = info.email;
            rts.user.point = info.point;
            rts.user.rank_image = info.rank_image;
            rts.user.rank_name = info.rank_name;
            rts.user.avg_wpm = info.avg_wpm;
            rts.user.avg_perent = info.avg_perent;
            rts.user.record_wpm = info.record_wpm;

            let user = JSON.parse(localStorage.getItem('user'));
            user.point = info.point;
            localStorage.user = JSON.stringify(user);
            rts.$emit('SetPointFull', info.point);
          }
          rts.getLastRace();
        }).catch(error => {
          if(error.response.status == 402) {
            rts.$router.push('/text');
          }
        });
    },
    joinRoom(roomId) {
        this.status.inviteConrfirm = true;
        this.$socket.emit('joinRoom', {
            roomId: roomId
        });
    },
    IgnoreRoom(roomId, userId) {
      this.status.inviteConrfirm = true;
      this.$socket.emit('ignoreRoom', {
        roomId: roomId,
        userId: userId
      });
    },
    confirmInvite(event) {
        let roomId = event.target.classList[event.target.classList.length - 1];
        this.joinRoom(roomId);
        this.$router.push('/room');
      },
    ignoreInvite(event) {
      let roomId = event.toElement.classList[event.toElement.classList.length - 2];
      let fromUserId = event.toElement.classList[event.toElement.classList.length - 1];
      this.IgnoreRoom(roomId, fromUserId);
    }
  },
  sockets: {
    onlineUser: function(data) {
      let ex = this.friends.list.some(function(field) {
        return field.id === data.user.id
      });
      
      if(ex) {
        this.friends.list.forEach(element => {
          if(element.id === data.id) {
            element.online = 1;
          }
        });
      } else {
        this.friends.list.push(data.user);
      }
    },
    offlineUser: function(data) {
      let ex = this.friends.list.some(function(field) {
        return field.id === data.id
      });
      
      if(ex) {
        this.friends.list.forEach((element, index) => {
          if(element.id === data.id) {
            this.friends.list.splice(index, 1);
            //element.online = 0;
          }
        });
      } 
    },
    inviteGame: function(data) {
      this.status.inviteConrfirm = false;
      var rts = this;
      let user = localStorage.getItem('user');
      if(user) {
        var id = JSON.parse(localStorage.getItem('user'));
        if(id.id === data.to) {
          const h = this.$createElement;
          rts.$notify({
            title: 'Урилга',
            showClose: false,
            dangerouslyUseHTMLString: true,
            duration: 6000,
            onClick: function() {
              this.close();
            },
            onClose: function() {
              if(rts.status.inviteConrfirm === false) {
                rts.IgnoreRoom(data.roomId, data.fromId);
              }
            },
            message: h('div', { 
              class: 'notify1' 
              }, [
                    h('div', 
                      { 
                        class: 'notify2'
                      }, data.from + ' таныг уралдаанд урьж байна'),
                      h('div', {
                          class: 'notify3'
                        }, [
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-9 text-white ${data.roomId}`,
                                  on: {
                                    click: rts.confirmInvite.bind()
                                  }
                                }, 'Зөвшөөрөх'
                              ),
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-6 text-white ${data.roomId} ${data.fromId}`,
                                  on: {
                                    click: rts.ignoreInvite.bind()
                                  }
                                }, 'Цуцлах'
                              )
                      ])
                    ])
          });

          
        }
      }
    }
  }
};
</script>
