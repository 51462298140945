<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
.big-title {
  margin-top: -210px;
  font-family: 'Exo 2', sans-serif;
}
.default-font {
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
}
</style>
<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Register Info -->
        <div class="hidden xl:flex flex-col min-h-screen" :style="{'overflow': 'hidden'}">
          <router-link to="/beginner" class="-intro-x flex items-center pt-5">
            <img
              class="w-6"
              src="@/assets/images/logo/logo.png"
              style="width: 120px; height: auto;"
            />
            <span class="-intro-x text-lg text-white dark:text-gray-500 pl-10">Бүх нийтийн боловсролд хуруу нэмнэ.</span>
          </router-link>
        
          <div class="my-auto" :style="{'position': 'relative'}">
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight big-title"
            >
              Хурдан хуруу
            </div>
            <div class="-intro-x mt-2 text-lg text-white dark:text-gray-500 default-font">
              <span class="typer" id="main" data-colors="#fff" data-loop="false" data-words="Хурдан зөв бичих нь өнөө цагийн хүн бүрийн заавал <br> эзэмших ёстой чадваруудын нэг" data-delay="50" data-deleteDelay="1000"></span>
              <span class="cursor" data-cursor-display=" |" data-owner="main"></span>
            </div>
            <img
              :style="{'margin-bottom': '0', 'position': 'absolute', 'width': '500px', 'height': 'auto', 'margin-top': '120px'}"
              class="-intro-x w-1/2"
              :src="require(`@/assets/images/logo/login.png`)"
            />
          </div>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Register Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Бүртгүүлэх
            </h2>
            <div
              class="intro-x mt-2 text-gray-500 dark:text-gray-500 xl:hidden text-center"
            >
              Шаардлагатай талбаруудыг бөглөснөөр таны цахим хаягт баталгаажуулах <b>и-мэйл</b> очих болно.
            </div>
            <form @submit.prevent="onSubmit ">
              <div class="intro-x mt-8">
              <input
                type="text"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="Нэр"
                v-model="model.name"
                required
              />
              <input
                type="email"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="И-мэйл хаяг"
                v-model="model.email"
                required
              />
              <input
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="Нууц үг"
                v-model="model.password"
                @keyup="SecurePassword"
                required
                pattern=".{6,}"
              />
              <div class="intro-x w-full grid grid-cols-12 gap-4 h-1 mt-3">
                <div class="col-span-3 h-full rounded bg-gray-200" ref="secure1"></div>
                <div class="col-span-3 h-full rounded bg-gray-200" ref="secure2"></div>
                <!-- <div class="col-span-3 h-full rounded bg-grey-200 bg-theme-9"></div> -->
                <div class="col-span-3 h-full rounded bg-gray-200" ref="secure3"></div>
                <div
                  class="col-span-3 h-full rounded bg-gray-200" ref="secure4"
                ></div>
              </div>
              
              <input
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="Давтан нууц үг"
                v-model="model.repassword"
                @keyup="ConfirmPassword"
                required
                pattern=".{6,}"
              />

              <div class="intro-x w-full grid grid-cols-12 gap-4 h-1 mt-3">
                <div class="col-span-3 h-full rounded bg-gray-200" ref="repassword"></div>
              </div>
              
            </div>
            <div
              class="intro-x flex items-center text-gray-700 dark:text-gray-600 mt-4 text-xs sm:text-sm"
            >
              <input
                id="remember-me"
                type="checkbox"
                class="input border mr-2"
                required
                v-model="model.agree"
              />
              <router-link class="text-theme-1 dark:text-theme-10 ml-1" to="/terms"
                >Үйлчилгээний нөхцөл</router-link
              >
              <label class="cursor-pointer select-none" for="remember-me"
                >-ийг зөвшөөрч байна.</label
              >
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center">
              <button
                type="submit"
                class="button button--lg w-full xl:w-40 text-white bg-theme-7 xl:mr-3 align-top"
                v-html="registerBtn"
                :disabled='texts.isDisabled'
              >
                Бүртгүүлэх
              </button>
              <button
                class="button button--lg w-full xl:w-40 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
                @click="Login"
              >
                Нэвтрэх
              </button>
            </div>

            <div class="intro-x mt-5 xl:mt-8 text-center">
              <el-button @click="logInWithFacebook" :disabled="facebookButton.disabled" type="primary" class="button button--lg w-full xl:w-full text-white bg-theme-4 xl:mr-3 align-top" :loading="facebookButton.load">{{facebookButton.text}}</el-button>
            </div>

            </form>
          </div>
        </div>
        <!-- END: Register Form -->
      </div>
    </div>
  </div>
</template>

<script>
import DarkModeSwitcher from "@/components/DarkModeSwitcher";

export default {
  components: {
    DarkModeSwitcher
  },
  data() {
    return {
      facebookButton: {
          text: 'Facebook -ээр нэвтрэх',
          load: false,
          disabled: false
      },
      model: {
          name: '',
          email: '',
          password: '',
          repassword: '',
          agree: false,
        },
        texts: {
          registerBtn: 'Бүртгүүлэх',
          isDisabled: false
        }
    }
  },
  async mounted() {
    cash("body")
      .removeClass("app")
      .addClass("login");
      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', 'https://unpkg.com/typer-dot-js@0.1.0/typer.js')
      document.head.appendChild(externalScript)

      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
  }, 
  computed: {
      registerBtn: function() {
        return this.texts.registerBtn;
      }
    },
  methods: {
    async logInWithFacebook() {
      let rts = this;
      this.facebookButton.text = 'Түр хүлээнэ үү...';
      this.facebookButton.load = true;
      this.facebookButton.disabled = true;
      const FB = window.FB;
      FB.login(function(response) {
        if (response.authResponse) {
          rts.$axios({
            method: 'GET',
            url: `https://graph.facebook.com/v9.0/me?fields=id%2Cname%2Cemail%2Cpicture&access_token=${response.authResponse.accessToken}`,
          }).then(function(data) {
            rts.withFacebookLogin(data);
          }).catch(error => {
                rts.facebookButton.text = 'Facebook -ээр нэвтрэх';
                rts.facebookButton.load = false;
                rts.facebookButton.disabled = false;
            rts.$msgbox({
              message: 'Уучлаарай. Алдаа гарлаа.',
              type: 'error'
            });
          })
        } else {
          rts.facebookButton.text = 'Facebook -ээр нэвтрэх';
          rts.facebookButton.load = false;
          rts.facebookButton.disabled = false;
        }
      },{scope: 'public_profile,email'});
    },
    withFacebookLogin(data) {
      let rts = this;
      rts.$axios({
              method: 'POST',
              url: rts.$appUrl + '/api/user/facebook-login',
              headers: {},
              data: {
                user: data.data
              }
            }).then(data => {
              if(data.data.result === 'success') {
                rts.$msgbox({
                  message: 'Системд амжилттай нэвтэрлээ.',
                  type: 'success'
                });

                rts.facebookButton.text = 'Facebook -ээр нэвтрэх';
                rts.facebookButton.load = false;
                rts.facebookButton.disabled = true;

                // rts.$axios.defaults.headers.common.authorization = `Bearer ${data.data.data[0].token}`;
                localStorage.token = data.data.token;
                localStorage.active = data.data.data.active;
                localStorage.user = JSON.stringify(data.data.data);
                // rts.$router.push("/beginner");
                setTimeout(() => {
                  window.location.href = window.location.protocol + '//' + window.location.host + '/lesson';
                }, 1000);
              }
      });
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: "385747126158265", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          xfbml: true,
          version: "v13.0"
        });
        FB.AppEvents.logPageView();   
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
      return true;
    },
    SecurePassword() {
      var len = this.model.password.length;
      if(len < 6) 
      {
        this.$refs.secure1.classList.add('bg-red-400');
        this.$refs.secure1.classList.remove('bg-theme-9');
        this.$refs.secure2.classList.remove('bg-theme-9');
        this.$refs.secure3.classList.remove('bg-theme-9');
        this.$refs.secure4.classList.remove('bg-theme-9');
      } else if(len <= 6 || len < 9) {
        this.$refs.secure1.classList.add('bg-theme-9');
        this.$refs.secure2.classList.add('bg-theme-9');
        this.$refs.secure3.classList.remove('bg-theme-9');
        this.$refs.secure4.classList.remove('bg-theme-9');
      } else if(len <= 9 || len < 13) {
        this.$refs.secure1.classList.add('bg-theme-9');
        this.$refs.secure2.classList.add('bg-theme-9');
        this.$refs.secure3.classList.add('bg-theme-9');
        this.$refs.secure4.classList.remove('bg-theme-9');
      } else if(len > 12) {
        this.$refs.secure1.classList.add('bg-theme-9');
        this.$refs.secure2.classList.add('bg-theme-9');
        this.$refs.secure3.classList.add('bg-theme-9');
        this.$refs.secure4.classList.add('bg-theme-9');
      }
      this.ConfirmPassword();
    }, 
    ConfirmPassword() {
      var pass = this.model.password;
      var repass = this.model.repassword;
      if(pass === repass) {
        this.$refs.repassword.classList.add('bg-theme-9');
        this.$refs.repassword.classList.remove('bg-red-400');
      } else {
        this.$refs.repassword.classList.remove('bg-theme-9');
        this.$refs.repassword.classList.add('bg-red-400');
      }
    },
    Login() {
      this.$router.push('/login');
    },
    onSubmit() {
      var rts = this; 
      rts.texts.registerBtn = 'Уншиж байна..'
      rts.texts.isDisabled = true;
        this.$axios({
          method: 'post',
          url: rts.$appUrl +'/api/user/register',
          headers: {}, 
          data: {
            name: this.model.name,
            email: this.model.email,
            password: this.model.password
          }
        }).then(function(data){
          rts.texts.registerBtn = 'Бүртгүүлэх'
          rts.texts.isDisabled = false;
          if(data.data.result === 'fail') {
              rts.$toast({
                text: data.data.data,
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "center",
                backgroundColor: "#f59314",
                stopOnFocus: true
              }).showToast();

            } else if(data.data.result === 'success') {
              rts.$toast({
                text: data.data.data,
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "center",
                backgroundColor: "#216fcf",
                stopOnFocus: true
              }).showToast();
            }
        });
    }
  }
};
</script>
