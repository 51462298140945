<style scoped>
.wrongLine{
  text-decoration: line-through;
}
.notify1 {
  width: 280px;
}
.notify2 {
  text-align: right; 
  width: 100%;
}
.notify3 {
  margin-top: 8px;
  text-align: right;
}
</style>
<template>
  <div class="grid  col-span-6">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Хурдан хуруу гэж юу вэ?</h2>
    </div>

      <div class="tab-content mt-5">
      <div id="profile" class="tab-content__pane active">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
          <!-- BEGIN: Latest Tasks -->
          <div class="intro-y box col-span-12 lg:col-span-9 listScroll" :style="{'height': '500px', 'max-height': '700px', 'overflow-y': 'auto'}">
            <div
              class="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200 dark:border-dark-5"
            >
              
              <div class="nav-tabs ml-auto hidden sm:flex">
                <a
                  data-toggle="tab"
                  data-target="#latest-tasks-new"
                  href="javascript:;"
                  class="py-5 ml-6 active"
                  ></a
                >
              </div>
            </div>
            <div class="p-5">
              <div class="tab-content">
                <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
                  <div class="col-span-3" :style="{'height': '100px', 'padding-top': '10px'}" align="center">
                    <img
                      class="w-6 mb-5"
                      src="@/assets/images/logo/like.svg"
                      style="width: 100px; height: auto;"
                    />
                  </div>
                  <div class="tab-content__pane active pl-10 pr-10 col-span-9 text-justify">
                      <!-- <b>Дадлага - Жижиг үсэг</b> : Анхлан суралцагчдад зориулагдсан бөгөөд хуруу тус бүрийн комьпютерийн гар дээрх даралтуудын хувиарыг тогтоолгож дадлагажуулах үндсэн зорилготой ба зөвхөн жижиг үсгээр бичнэ.
                      <br><b>Дадлага - Том үсэг, цэг, таслал</b> :  Та энэ шатанд том жижиг үсэг цэг таслал орсон техтүүд бичиж сурах ба дадлагыг Монголын сонгодог утга зохиолуудын дээж болсон бүтээлүүд болон танин мэдэхүйн богино эхүүд дээр хийх болно.
                      <br><b>Дадлага - Тоо, тэмдэгт</b> : Цэг таслалаас том жижиг үсгээс гадна хаалт, хашилт, тоо, гадаад үг орсноороо энэ шат илүү хүнд бөгөөд бүх төрлийн текстийг хурдан бичиж сургах дадлагад сургана.  -->
                      Арван хуруугаар хурдан бичиж сурахаар биднийг сонгосон танд баярлалаа. 
                      <br><strong>Хичээл</strong> - Манай сайтын “Хичээл” хэсэгт нийт 12 бүлэг 278 хичээл багтсан байгаа бөгөөд та хэдийгээр огт бичиж чаддагүй байсан ч сонирхолтой байдлаар таны хуруу бүрийг дадлагажуулах замаар сургах болно. Хичээл бүрт таны үр дүнгээс хамаарсан <strong>50 - 200</strong> оноо өгөх бөгөөд тэрхүү онооноос хамаарагдах шагналтай уралдаанд оролцож Шагнал авах мөн онооны “Цол” авах боломжтой.
                      Та нийт 266 хичээл хийсний дараа Сертификат авах 12 шалгалтийн текстийг бичих боломж нээгдэх бөгөөд энэ шалгалтыг амжилттай давснаар та 10 хуруугаар бичих техникийг олон улсын жишгийн дагуу суралцсан суралцагч болж онлайн “СЕРТИФИКАТ” авах эрхтэй болно. 
                  </div>
                  <div class="col-span-3" :style="{'height': '60px', 'padding-top': '30px'}" align="center">
                    <img
                      class="w-6 mb-5"
                      src="@/assets/images/logo/success.svg"
                      style="width: 100px; height: auto;"
                    />
                  </div>
                  <div class="tab-content__pane active pl-10 pr-10 col-span-9 text-justify">
                      <b>Онлайн уралдаан</b> – Сайтын энэ цэс танд онлайнаар халз уралдах боломж олгож байгаа бөгөөд уралдааны өрөө үүсгэх, эсвэл хэн нэгний өрөөнд нэгдэж өөрөөсөө гадна 1-4 хүнтэй уралдах боломжийг олгоно. Уралдаанд орсноор мөн оноогоо өсгөж цолоо нэмэх боломжтой.
                      <!-- <br><br> <b>Шагналтай уралдаан</b> – Сайтын хөгжүүлэгчдийн зүгээс энэхүү уралдааныг сар бүр зохиох бөгөөд олон сонирхолтой шагналыг сар бүр хамгийн өндөр WPM авч чадсан хэрэглэгчид бэлэглэх болно. Уралдаанд оролцонгоо оноогоо өсгөх цолоо нэмэх давхар боломжууд бас байгааг битгий мартаарай. -->
                      <br><br> <b>Шагналтай уралдаан</b> – Сайтын хөгжүүлэгчдийн зүгээс олон сонирхолтой уралдааныг байнга зохиох бөгөөд бүх суралцагчиддаа эрх тэгш боломж олгох үүднээс онооны босго болон хурдны хязгаараар өөртэйгөө ижил түвшний хүмүүстэй уралдах боломжийг шударгаар бий болгож байна.
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="intro-y box col-span-12 lg:col-span-3 listScroll" :style="{'height': '500px', 'max-height': '700px', 'overflow-y': 'auto'}">
            <div
              class="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto py-5"></h2>
            </div>
            <div class="p-5">
              <div class="tab-content" align="center">
                <img
                  class="w-6 mb-5"
                  src="@/assets/images/logo/huruu.png"
                  style="width: 150px; height: auto;"
                />
                <div class="pl-10 pr-10" :style="{'text-align': 'justify'}"><span :style="{'width': '10px', 'opacity': '0'}">sadf</span>Хурдан хуруу бол 10 хуруугаар алдаагүй хурдан бичиж сургах вэб сайт ба дэлхий нийтэд хурдан бичих техникийг WPM (Word per minute) гэсэн нэгжээр хэмжин хурдыг тодорхойлж байна. Манай сайт дунд сургуулийн сурагч, дээд сургуулийн оюутан, ажилтан, албан хаагч өөрийгөө хөгжүүлэхийг хүссэн хэн бүхэнд нээлттэй бөгөөд бага зардлаар маш чухал чадварыг эзэмших боломжийг бид хангаж байгаадаа баяртай байна. 
                </div>
                <div class="mt-5">
                  Бүх нийтийн боловсролд хуруу нэмцгээе !
                </div>
              </div>
            </div>
          </div>

        </div>
          
        </div>
      </div>
    </div>


    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Цолны мэдээлэл</h2>
    </div>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
      >
        <div
          class="flex flex-1 px-5 items-center justify-center lg:justify-start"
        >
          <div class="text-center rounded-md w-20 h-20 sm:w-24 sm:h-24 lg:w-32 lg:h-32 py-3">
            <img :src="`${$appUrl}/images/rank/Expert.png`" :style="{'width': '120px', 'height': 'auto', 'margin': 'auto'}"/>
          </div>
          <div class="ml-5">
            <div
              class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
            >
            ГАРАМГАЙ ХУРУУ
            </div>
            <div class="text-gray-600">EXPERT</div>
          </div>
        </div>
        <div
          class="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div class="sm:whitespace-normal flex items-center">
            <span>Таныг онлайн уралдаан, шагналтай уралдаанд оролцох болон <strong>хичээлийн үе</strong>-үүдийг давах явцад таны амжилтаас хамааран оноо нэмэгдсээр 50,000 болсоноор таны цол нэмэгдэж "<strong>Expert</strong>" буюу "<strong>Гарамгай хуруу</strong>" болно. </span>
          </div>
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"
        >
          <div class="text-center rounded-md w-20 py-3">
            <img :src="`${$appUrl}/images/rank/Expert.png`" :style="{'width': 'auto', 'height': '40px', 'margin': 'auto'}"/>
            <div class="text-gray-600"> Expert </div>
          </div>
          <div class="text-center rounded-md w-20 py-3">
            <div class="font-semibold text-theme-1 dark:text-theme-10" :style="{'font-size': '25px'}">
              50,000
            </div>
            <div class="text-gray-600">Оноо</div>
          </div>
        </div>
      </div>
    </div>


    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
      >
        <div
          class="flex flex-1 px-5 items-center justify-center lg:justify-start"
        >
          <div class="text-center rounded-md w-20 h-20 sm:w-24 sm:h-24 lg:w-32 lg:h-32 py-3">
            <img :src="`${$appUrl}/images/rank/specialist.png`" :style="{'width': '120px', 'height': 'auto', 'margin': 'auto'}"/>
          </div>
          <div class="ml-5">
            <div
              class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
            >
              ГАВШГАЙ ХУРУУ
            </div>
            <div class="text-gray-600">SPECIALIST</div>
          </div>
        </div>
        <div
          class="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div class="sm:whitespace-normal flex items-center">
            <span>Таныг онлайн уралдаан, шагналтай уралдаанд оролцох болон <strong>хичээлийн үе</strong>-үүдийг давах явцад таны амжилтаас хамааран оноо нэмэгдсээр 30,000 болсоноор таны цол нэмэгдэж "<strong>Specialist</strong>" буюу "<strong>Гавшгай хуруу</strong>" болно. </span>
          </div>
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"
        >
          <div class="text-center rounded-md w-20 py-3">
            <img :src="`${$appUrl}/images/rank/specialist.png`" :style="{'width': 'auto', 'height': '40px', 'margin': 'auto'}"/>
            <div class="text-gray-600"> Specialist </div>
          </div>
          <div class="text-center rounded-md w-20 py-3">
            <div class="font-semibold text-theme-1 dark:text-theme-10" :style="{'font-size': '25px'}">
              30,000
            </div>
            <div class="text-gray-600">Оноо</div>
          </div>
        </div>
      </div>
    </div>


    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
      >
        <div
          class="flex flex-1 px-5 items-center justify-center lg:justify-start"
        >
          <div class="text-center rounded-md w-20 h-20 sm:w-24 sm:h-24 lg:w-32 lg:h-32 py-3">
            <img :src="`${$appUrl}/images/rank/skilled.png`" :style="{'width': '75px', 'height': 'auto', 'margin': 'auto'}"/>
          </div>
          <div class="ml-5">
            <div
              class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
            >
              ШАЛАМГАЙ ХУРУУ
            </div>
            <div class="text-gray-600">SKILLED</div>
          </div>
        </div>
        <div
          class="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div class="sm:whitespace-normal flex items-center">
            <span>Таныг онлайн уралдаан, шагналтай уралдаанд оролцох болон <strong>хичээлийн үе</strong>-үүдийг давах явцад таны амжилтаас хамааран оноо нэмэгдсээр 15,000 болсоноор таны цол нэмэгдэж "<strong>Skilled</strong>" буюу "<strong>Шаламгай хуруу</strong>" болно. </span>
          </div>
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"
        >
          <div class="text-center rounded-md w-20 py-3">
            <img :src="`${$appUrl}/images/rank/skilled.png`" :style="{'width': 'auto', 'height': '40px', 'margin': 'auto'}"/>
            <div class="text-gray-600"> Skilled </div>
          </div>
          <div class="text-center rounded-md w-20 py-3">
            <div class="font-semibold text-theme-1 dark:text-theme-10" :style="{'font-size': '25px'}">
              15,000
            </div>
            <div class="text-gray-600">Оноо</div>
          </div>
        </div>
      </div>
    </div>


    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
      >
        <div
          class="flex flex-1 px-5 items-center justify-center lg:justify-start"
        >
          <div class="text-center rounded-md w-20 h-20 sm:w-24 sm:h-24 lg:w-32 lg:h-32 py-3">
            <img :src="`${$appUrl}/images/rank/Average.png`" :style="{'width': '75px', 'height': 'auto', 'margin': 'auto'}"/>
          </div>
          <div class="ml-5">
            <div
              class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
            >
              ХУРДАН ХУРУУ
            </div>
            <div class="text-gray-600">AVERAGE</div>
          </div>
        </div>
        <div
          class="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div class="sm:whitespace-normal flex items-center">
            <span>Таныг онлайн уралдаан, шагналтай уралдаанд оролцох болон <strong>хичээлийн үе</strong>-үүдийг давах явцад таны амжилтаас хамааран оноо нэмэгдсээр 8,000 болсоноор таны цол нэмэгдэж "<strong>Average</strong>" буюу "<strong>Хурдан хуруу</strong>" болно. </span>
          </div>
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"
        >
          <div class="text-center rounded-md w-20 py-3">
            <img :src="`${$appUrl}/images/rank/Average.png`" :style="{'width': 'auto', 'height': '40px', 'margin': 'auto'}"/>
            <div class="text-gray-600"> Average </div>
          </div>
          <div class="text-center rounded-md w-20 py-3">
            <div class="font-semibold text-theme-1 dark:text-theme-10" :style="{'font-size': '25px'}">
              8,000
            </div>
            <div class="text-gray-600">Оноо</div>
          </div>
        </div>
      </div>
    </div>


    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
      >
        <div
          class="flex flex-1 px-5 items-center justify-center lg:justify-start"
        >
          <div class="text-center rounded-md w-20 h-20 sm:w-24 sm:h-24 lg:w-32 lg:h-32 py-3">
            <img :src="`${$appUrl}/images/rank/beginner.png`" :style="{'width': '65px', 'height': 'auto', 'margin': 'auto'}"/>
          </div>
          <div class="ml-5">
            <div
              class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
            >
              АРВАН ХУРУУ
            </div>
            <div class="text-gray-600">BEGINNER</div>
          </div>
        </div>
        <div
          class="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div class="sm:whitespace-normal flex items-center">
            <span>Та манай вэб сайтад бүртгүүлснээр "<strong>Beginner</strong>" буюу "<strong>Арван хуруу</strong>  " цолноос эхлэх бөгөөд онлайн уралдаан, шагналтай уралдаанд оролцох болон <strong>хичээлийн үе</strong>-үүдийг давах явцад таны амжилтаас хамааран оноо нэмэгдсээр дараагийн цолонд хүрэх боломжтой. Танд амжилт хүсье!</span>
          </div>
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"
        >
          <div class="text-center rounded-md w-20 py-3">
            <img :src="`${$appUrl}/images/rank/beginner.png`" :style="{'width': 'auto', 'height': '40px', 'margin': 'auto'}"/>
            <div class="text-gray-600"> Average </div>
          </div>
          <div class="text-center rounded-md w-20 py-3">
            <div class="font-semibold text-theme-1 dark:text-theme-10" :style="{'font-size': '25px'}">
               0+
            </div>
            <div class="text-gray-600">Оноо</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      friends: {
        list: []
      },
      user: {        
        name: '',
        id: '',
        phone: '',
        point: 0,
        image: 'default.png',
        rank_image: 'beginner.png',
        rank_name: 'Beginner',
        record_wpm: 0,
        avg_wpm: 0,
        avg_perent: 0,
        email: ''
      },
      status: {
        inviteConrfirm: false
      },
      lastRace: []  
    }
  },
  mounted() {
    this.GetUser();
  },
  methods: {
    leaveRoom() {
      this.$socket.emit('leaveRoom',{
          roomId: this.room.id
      });
    },
    prevNewProducts() {
      this.$refs["new-products"].prev();
    },
    nextNewProducts() {
      this.$refs["new-products"].next();
    },
    prevNewAuthors() {
      this.$refs["new-authors"].prev();
    },
    nextNewAuthors() {
      this.$refs["new-authors"].next();
    },
    GetUser() {
      const token = localStorage.getItem('token');
      var rts = this;
      this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/user/get-user-info',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(function(data) {
          if(data.data.result == 'success') {
            let info = data.data.data.info;
            rts.user.name = info.name;
            rts.user.image = info.image;
            rts.user.id = info.id;
            rts.user.phone = info.phone;
            rts.user.email = info.email;
            rts.user.point = info.point;
            rts.user.rank_image = info.rank_image;
            rts.user.rank_name = info.rank_name;
            rts.user.avg_wpm = info.avg_wpm;
            rts.user.avg_perent = info.avg_perent;
            rts.user.record_wpm = info.record_wpm;
          }
        }).catch(error => {
          if(error.response.status == 402) {
            rts.$router.push('/text');
          }
        });
    },
    joinRoom(roomId) {
        this.status.inviteConrfirm = true;
        this.$socket.emit('joinRoom', {
            roomId: roomId
        });
    },
    IgnoreRoom(roomId, userId) {
      this.status.inviteConrfirm = true;
      this.$socket.emit('ignoreRoom', {
        roomId: roomId,
        userId: userId
      });
    },
    confirmInvite(event) {
        let roomId = event.target.classList[event.target.classList.length - 1];
        this.joinRoom(roomId);
        this.$router.push('/room');
      },
    ignoreInvite(event) {
      let roomId = event.toElement.classList[event.toElement.classList.length - 2];
      let fromUserId = event.toElement.classList[event.toElement.classList.length - 1];
      this.IgnoreRoom(roomId, fromUserId);
    }
  },
  sockets: {
    onlineUser: function(data) {
      let ex = this.friends.list.some(function(field) {
        return field.id === data.user.id
      });
      
      if(ex) {
        this.friends.list.forEach(element => {
          if(element.id === data.id) {
            element.online = 1;
          }
        });
      } else {
        this.friends.list.push(data.user);
      }
    },
    offlineUser: function(data) {
      let ex = this.friends.list.some(function(field) {
        return field.id === data.id
      });
      
      if(ex) {
        this.friends.list.forEach((element, index) => {
          if(element.id === data.id) {
            this.friends.list.splice(index, 1);
            //element.online = 0;
          }
        });
      } 
    },
    inviteGame: function(data) {
      this.status.inviteConrfirm = false;
      var rts = this;
      let user = localStorage.getItem('user');
      if(user) {
        var id = JSON.parse(localStorage.getItem('user'));
        if(id.id === data.to) {
          const h = this.$createElement;
          rts.$notify({
            title: 'Урилга',
            showClose: false,
            dangerouslyUseHTMLString: true,
            duration: 6000,
            onClick: function() {
              this.close();
            },
            onClose: function() {
              if(rts.status.inviteConrfirm === false) {
                rts.IgnoreRoom(data.roomId, data.fromId);
              }
            },
            message: h('div', { 
              class: 'notify1' 
              }, [
                    h('div', 
                      { 
                        class: 'notify2'
                      }, data.from + ' таныг уралдаанд урьж байна'),
                      h('div', {
                          class: 'notify3'
                        }, [
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-9 text-white ${data.roomId}`,
                                  on: {
                                    click: rts.confirmInvite.bind()
                                  }
                                }, 'Зөвшөөрөх'
                              ),
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-6 text-white ${data.roomId} ${data.fromId}`,
                                  on: {
                                    click: rts.ignoreInvite.bind()
                                  }
                                }, 'Цуцлах'
                              )
                      ])
                    ])
          });

          
        }
      }
    }
  }
};
</script>
