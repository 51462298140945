<template>
  <DonutChart
    :width="width"
    :height="height"
    :chart-data="data"
    :options="options"
  />
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    percent: {
      type: Number, 
      default: 0
    }
  },
  computed: {
    data() {
      return {
        labels: [],
        datasets: [
          {
            data: [100 - this.percent, this.percent],
            backgroundColor: ["#c1cad9", "#84de5b"],
            hoverBackgroundColor: ["#c1cad9", "#84de5b"],
            borderWidth: 2,
            borderColor: this.$store.state.main.darkMode ? "#303953" : "#fff"
          }
        ]
      };
    },
    options() {
      return {
        legend: {
          display: false
        },
        cutoutPercentage: 83
      };
    }
  }
};
</script>
