import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from 'axios';
import store from "./store";
import "./global-components";
import "./utils";
import "./libs";
import Toastify from "toastify-js";
import refreshToken from "@/components/refreshToken";
import VueSocketio from 'vue-socket.io';
import { Message, Notification, MessageBox, Carousel, CarouselItem, Button, Icon, Input, Image, Switch, Loading, Tooltip, Progress, Backtop  } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import "./assets/sass/app.scss";

Vue.use(Loading.directive);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Button);
Vue.use(Progress);
Vue.use(Icon);
Vue.use(Image);
Vue.use(Input);
Vue.use(Switch);
Vue.use(Tooltip);
Vue.use(Backtop);
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// var AppURL = 'http://localhost:8002';
var AppURL = 'https://api.hurdanhuruu.mn';
Vue.config.productionTip = false;
Vue.prototype.$appUrl = AppURL;
Vue.prototype.$axios = axios;
Vue.prototype.$toast = Toastify;
Vue.prototype.$checkAuth = refreshToken;
Vue.prototype.$msgbox = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
Vue.prototype.$newSocket = VueSocketio;
Vue.prototype.$loading = Loading.service;

const token = localStorage.getItem('token');
if(token) {
  Vue.use(new VueSocketio({
    debug: false, 
    connection: 'https://api.hurdanhuruu.mn',
    // connection : 'http://localhost:8002',
    options : { query: { token: token } },
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
    }
  }));
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
