<style>
.listScroll::-webkit-scrollbar {
    width: 4px;
}
.listScroll::-webkit-scrollbar-thumb {
    border-radius: 20px;
    /* background: #1c3faa; */
    background: #91969e;
}
</style>
<template>

  <div>
    <DarkModeSwitcher />
    <MobileMenu />
    <!-- BEGIN: Top Bar -->
    <div
      class="border-b border-theme-10 -mt-10 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 pt-3 md:pt-0 mb-10"
    >
      <div class="top-bar-boxed flex items-center" >
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'top-menu-lesson' }"
          tag="a"
          class="-intro-x hidden md:flex"
        >
          <img
            alt="Midone Tailwind HTML Admin Template"
            class="w-6"
            src="@/assets/images/logo/logo.png"
            style="width: auto; height: 30px;"
          />
        </router-link>
        <!-- END: Logo -->
        <!-- BEGIN: Breadcrumb -->
        <div class="-intro-x breadcrumb breadcrumb--light mr-auto " style="border:none;">
          <!-- <router-link  to="/beginner" class=""><HomeIcon class="breadcrumb__icon" /></router-link >
          <ChevronRightIcon class="breadcrumb__icon" />
          <a href="javascript:;" class="breadcrumb--active">{{ $route.title }}</a> -->
          <p style="color: #fff; font-size: 14px;">НИЙТ {{Number(points).toLocaleString()}} ОНОО</p>
        </div>
        <!-- END: Breadcrumb -->
        <!-- BEGIN: Search -->
        <div class="intro-x relative mr-3 sm:mr-6">
          <div class="search hidden sm:block">
            <span style="color: #fff; font-style: italic; font-size: 18px;">Бүх нийтийн боловсролд хуруу нэмнэ.</span>
          </div>
        </div>
        <div class="intro-x dropdown relative mr-4 sm:mr-6">
          <div
            class="dropdown-toggle notification notification--light cursor-pointer" v-bind:class="{'notification--bullet': notification.length > 0}"
          >
            <BellIcon class="notification__icon dark:text-gray-300" />
          </div>
          <div class="notification-content pt-2 dropdown-box">
            <div
              class="notification-content__box dropdown-box__content box dark:bg-dark-6"
            >
              <div class="notification-content__title">Мэдэгдлүүд</div>
              <div v-if="notification.length > 0">
                <div
                  v-for="(notify, index) in notification"
                  :key="index"
                  class="cursor-pointer relative flex items-center"
                  :class="{ 'mt-5': index }"
                  @click="showNotify(index)"
                >
                  <div class="w-12 h-12 flex-none image-fit mr-1">
                    <img
                      alt=""
                      class="rounded-full"
                      src="@/assets/images/logo/gift.png"
                    />
                    
                    <div
                      class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
                    ></div>
                  </div>
                  <div class="ml-2 overflow-hidden">
                    <div class="flex items-center">
                      <a href="javascript:;" class="font-medium truncate mr-5">
                        {{notify.title}}
                      </a>
                    </div>
                    <div class="w-full truncate text-gray-600">
                      <b>{{notify.raceId}}</b> {{notify.message}}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="cursor-pointer relative flex items-center">
                  <div class="w-full truncate text-gray-600" align="center">
                      Мэдэгдэл байхгүй байна
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Notifications -->
        <!-- BEGIN: Account Menu -->
        <div class="intro-x dropdown w-8 h-8 relative">
          <div
          
            class="w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110" ref="dropdowntoggle"
            
          >
          <img
              alt="Midone Tailwind HTML Admin Template"
              :src="user_image"
        />
            
          </div>
          <div class="dropdown-box w-56"  style="border:solid 2px brown">
            <div
              class="dropdown-box__content box bg-theme-3 dark:bg-dark-6 text-white" 
            >
              <div class="p-4 border-b border-theme-10 dark:border-dark-3">
                <div class="font-medium">Сайн уу?</div>
                <div class="text-xs text-theme-41 dark:text-gray-600">
                  {{name}}
                </div>
              </div>
              <div class="p-2">
                <div class="items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md w-full" align="center">Үлдсэн хоног : {{left_day}}</div>
                <div style="cursor: pointer;"
                  @click="toSub('/profile')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                >
                  <UserIcon class="w-4 h-4 mr-2" /> Хувийн мэдээлэл
              </div>
                <div style="cursor: pointer;"
                  @click="toSub('/change-password')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                >
                  <LockIcon class="w-4 h-4 mr-2" /> Нууц үг шинэчлэх
               </div>
                <div style="cursor: pointer;"
                  @click="toSub('/certificate')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                >
                   <CreditCardIcon class="w-4 h-4 mr-2" /> сертификат
                </div>
                <div style="cursor:pointer;">
                <a href="javascript:;" 
                class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                 @click="toSub('/help')"
                >   
                <HelpCircleIcon class="w-4 h-4 mr-2" />
                  Тусламж</a>

               </div>
              </div>
              <div class="p-2 border-t border-theme-10 dark:border-dark-3">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                  @click="Logout"
                >
                  <ToggleRightIcon class="w-4 h-4 mr-2" />
                  Гарах
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Account Menu -->
      </div>
    </div>
    <!-- END: Top Bar -->
    <!-- BEGIN: Top Menu -->
    <nav class="top-nav">
      <ul :style="{'margin-top': '-20px'}">
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu">
          <li :key="menuKey">
            <a
              href="javascript:;"
              class="top-menu"
              :class="{
                'top-menu--active': menu.active
              }"
              @click="linkTo(menu)"
            >
              <div class="top-menu__icon">
                <component :is="menu.icon" />
              </div>
              <div class="top-menu__title">
                {{ menu.title }}
                <ChevronDownIcon
                  v-if="$h.isset(menu.subMenu)"
                  class="top-menu__sub-icon"
                />
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <ul v-if="$h.isset(menu.subMenu)" :style="{'margin-top': '0px'}">
              <li
                v-for="(subMenu, subMenuKey) in menu.subMenu"
                :key="subMenuKey"
              >
                <a
                  href="javascript:;"
                  class="top-menu"
                  @click="linkTo(subMenu)"
                >
                  <div class="top-menu__icon">
                    <component :is="subMenu.icon" />
                  </div>
                  <div class="top-menu__title">
                    {{ subMenu.title }}
                    <ChevronDownIcon
                      v-if="$h.isset(subMenu.subMenu)"
                      class="top-menu__sub-icon"
                    />
                  </div>
                </a>
                <!-- BEGIN: Third Child -->
                <ul v-if="$h.isset(subMenu.subMenu)">
                  <li
                    v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                    :key="lastSubMenuKey"
                  >
                    <a
                      href="javascript:;"
                      class="top-menu"
                      @click="linkTo(lastSubMenu)"
                    >
                      <div class="top-menu__icon">
                        <component :is="'zap-icon'" />
                      </div>
                      <div class="top-menu__title">
                        {{ lastSubMenu.title }}
                      </div>
                    </a>
                  </li>
                </ul>
                <!-- END: Third Child -->
              </li>
            </ul>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </nav>
    <!-- END: Top Menu -->
    <!-- BEGIN: Content -->
    <div class="content">
      <router-view v-on:ChanePointEvent="changePoint" v-on:ChangeProfileImage="changeProfile" v-on:SetPointFull="setPoint" v-on:calcEndDate="calcDate"/>
    </div>
    <!-- END: Content -->

    <div id="notify_show" ref="notify_show" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
     <div class="modal__content modal__content--lg p-5 text-center">
         <div class="p-5 text-center">
             <!-- <WatchIcon class="w-16 h-16 text-theme-7 mx-auto mt-3" /> -->
             <div align="center">
                <img alt="" class="rounded-full" src="@/assets/images/logo/gift.png" :style="{'width': '100px', 'height': '100px'}"/>
              </div>
             <div v-if="CurrentNotifyIndex != null" class="text-xl mt-5">{{notification[CurrentNotifyIndex].title}}</div>
             <div class="grid grid-cols-12">
               <div class="col-span-12 xl:col-span-12 mt-1">
                <div v-if="CurrentNotifyIndex != null" class="text-gray-600 mt-2" :style="{'font-size': '15px'}">
                  <strong>{{notification[CurrentNotifyIndex].raceId}}</strong> дугаартай <span>{{notification[CurrentNotifyIndex].message}}</span>
                </div>
               </div>
             </div>
         </div>
         <div class="text-gray-600" :style="{'font-size': '13px'}">Шагнал хүлээн авахын тулд бүртгэл хэсэгт <strong>утасны дугаар</strong> бүртгүүлсэн байх шаардлагатайг анхаарна уу!</div>
         <div align="right">
            <button type="button" data-dismiss="modal" class="button w-24 bg-theme-1 text-white"> Ойлголоо </button>
            <button type="button" data-dismiss="modal" class="button w-60 bg-theme-6 text-white ml-1" @click="removeNotify"> Устгах </button>
         </div>
     </div>
    </div> 
    <div id="login_modal" ref="login_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div class="modal__content">
                    <div class="p-5 text-center">
                        <LockIcon class="w-12 h-12 mx-auto " :style="{'color': 'grey'}"/>
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 xl:col-span-12 mt-2" :style="{'border-bottom': '1px solid #d0d2d6', 'padding-bottom': '20px'}">
                                <div class="text-gray-600 mt-2 text-xl"> Та нэвтэрсний дараа хичээлээ үргэлжлүүлнэ үү. </div>
                                <div class="p-5 grid grid-cols-12 gap-4 row-gap-3">
                                <div class="col-span-12 sm:col-span-12"> <input type="email" v-model="login.Uname" required class="input w-full border mt-2 flex-1" placeholder="И-мэйл хаяг" /> </div>
                                <div class="col-span-12 sm:col-span-12"> <input type="password" v-model="login.Upass" required class="input w-full border mt-2 flex-1" placeholder="Нууц үг" /> </div>

        </div>
    </div>
</div>
</div>
<div class="intro-x mt-5 xl:mt-1 text-center pb-6">
                <button
                  class="button button--lg w-full xl:w-40 text-white bg-theme-1 xl:mr-3 align-top"
                  :disabled='texts.isDisabled'
                  v-html="buttontext"
                  @click="Login"
                >
                </button>
                
                <button
                  class="button button--lg w-full xl:w-40 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
                  @click="toRegister"
                >
                  Бүртгүүлэх
                </button>
            </div>
                </div>
            </div>  

  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import MobileMenu from "@/components/MobileMenu";
import DarkModeSwitcher from "@/components/DarkModeSwitcher";

export default {
  components: {
    DarkModeSwitcher,
    MobileMenu,
    TopBar
  },
  data() {
    return {
      login:{
                Uname:'',
                Upass:''
            },
            texts:{
                disabled:false
            },
            buttontext:"нэвтрэх",
      points: 0,
      formattedMenu: [],
      searchDropdown: false,
      name: '',
      user_image : require(`@/assets/images/menu/sandwich.png`),
      left_day: 0,
      notification: [],
      CurrentNotifyIndex: null
    };
  },
  computed: {
    topMenu() {
      return this.nestedMenu(this.$store.state.topMenu.menu);
    },
    CurrentRouteName() {
      return this.$router.name;
    }
  },
  watch: {
    $route() {
      this.formattedMenu = this.$h.assign(this.topMenu);
    }
  },
  mounted() {
    this.setMenu(this);
    cash("body")
      .removeClass("login")
      .addClass("app");
    this.formattedMenu = this.$h.assign(this.topMenu);
    this.$refs.dropdowntoggle.classList.add('dropdown-toggle');
    this.calcDate();
    this.getNotify();
  },
  methods: {
    toSub(to){
      var user = localStorage.getItem('user');
      if(!user) {
        cash("#login_modal").modal("show");
      }else{
        this.$router.push(`${to}`)
      }
    },
    toRegister(){
            cash("#login_modal").modal("hide");
            this.$router.push("/register");
        },
        Login() {
          console.log("pizda");
        var rts = this;
        rts.texts.disabled = true;
        this.buttontext = 'Уншиж байна..';
        this.$axios({
          method: 'POST',
          url: rts.$appUrl +'/api/user/login',
          headers: {
            'Content-Type': 'application/json'
          }, 
          data: {
            email: this.login.Uname,
            password: this.login.Upass
          }
        }).then(function(data){
            rts.buttontext = 'Нэвтрэх';
            rts.texts.disabled = false;
            if(data.data.result === 'fail') {

              rts.$msgbox({
                message: data.data.data,
                type: 'error'
              });

            } else if(data.data.result === 'success') {
                cash("#login_modal").modal("hide");
              rts.$msgbox({
                message: 'Системд амжилттай нэвтэрлээ.',
                type: 'success'
              });

              // rts.$axios.defaults.headers.common.authorization = `Bearer ${data.data.data[0].token}`;
              localStorage.token = data.data.token;
              localStorage.user = JSON.stringify(data.data.data);
              // rts.$router.push("/beginner");
              setTimeout(() => {
                window.location.reload();

              }, 1000);
            }
        });
    },
    removeNotify() {
      var notify = this.notification[this.CurrentNotifyIndex].raceId;
      // console.log(notify);
      const token = localStorage.getItem('token');
      var rts = this;
      this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/user/remove-notify',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {
            raceId: notify
          }
        }).then(data => {
          if(data.data.result == 'success') {
            rts.CurrentNotifyIndex = null;
            rts.notification.splice(rts.CurrentNotifyIndex, 1);
          }
        });
    },
    changePoint(value) {
      this.points = this.points + value;
      var user = JSON.parse(localStorage.getItem('user'));
      user.point = this.points;
      localStorage.user = JSON.stringify(user);
    },
    setPoint(value) {
      this.points = value;
      var user = JSON.parse(localStorage.getItem('user'));
      user.point = value;
      localStorage.user = JSON.stringify(user);
    },
    changeProfile(value) {
      this.user_image = value;
    },
    checkUser() {
      var user = localStorage.getItem('user');
      if(!user) {
        cash("#login_modal").modal("show");
      }
    },
    setMenu() {
      var user = localStorage.getItem('user');
      if(user) {
        var user = JSON.parse(localStorage.getItem('user'));
        this.name = user.name;
        this.user_image = user.image;
        this.points = user.point;
      }
    },
    showSearchDropdown() {
      this.searchDropdown = true;
    },
    hideSearchDropdown() {
      this.searchDropdown = false;
    },
    nestedMenu(menu) {
      menu.forEach((item, key) => {
        if (typeof item !== "string") {
          menu[key].active =
            (item.pageName == this.$route.name ||
              (this.$h.isset(item.subMenu) &&
                this.findActiveMenu(item.subMenu))) &&
            !item.ignore;
        }

        if (this.$h.isset(item.subMenu)) {
          menu[key] = {
            ...item,
            ...this.nestedMenu(item.subMenu)
          };
        }
      });

      return menu;
    },
    findActiveMenu(subMenu) {
      let match = false;
      subMenu.forEach(item => {
        if (item.pageName == this.$route.name && !item.ignore) {
          match = true;
        } else if (!match && this.$h.isset(item.subMenu)) {
          match = this.findActiveMenu(item.subMenu);
        }
      });
      return match;
    },
    linkTo(menu) {
      if (!this.$h.isset(menu.subMenu)) {
        this.$router.push({
          name: menu.pageName
        });
      }
    }, 
    Logout() {
      // this.$socket.emit('disconnect');
      this.$msgbox({
        message: 'Системээс гарлаа.',
        type: 'warning'
      });
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('active');
      this.$router.push('/login');
    },
    calcDate() {
      var user = localStorage.getItem('user');
      if(user) {
        var user = JSON.parse(localStorage.getItem('user'));
        var dt1 = new Date();
        
        var dt2 = new Date(user.end_at);
        var diff = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
        if(diff > 0) {
          this.left_day = diff;
        } else {
          this.left_day = 0;
        }
      }
    },
    getNotify() {
      const token = localStorage.getItem('token');
      var rts = this;
      if(token) {
        this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/user/get-notify',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(function(data) {
          let obj = data.data.data;
          obj.forEach(element => {
            if (element.type == 'race') {
              let notify = {title: 'Шагналтай уралдаан', raceId: element.ckey, message: ' уралдаанд ' + element.value + ' -р байр эзэлсэн байна. Танд баяр хүргэе.', seen: element.seen}
              rts.notification.push(notify);
            }
          });
        });
      }
    },
    showNotify(index) {
      let obj = this.notification[index];
      if(obj.title.toUpperCase() == 'ШАГНАЛТАЙ УРАЛДААН'){ 
        this.CurrentNotifyIndex = index;
        cash("#notify_show").modal("show");
      }
    },
  },
  sockets: {
    raceNotify: function(data) {
      var user = localStorage.getItem('user');
      if(user) {
        let id = JSON.parse(localStorage.getItem('user')).id;
        data.users.forEach(element => {
          if(element.id == id) {
            let ex = this.notification.some(function(field) {
              return field.raceId == data.raceId
            });
            if(!ex) {
              let notify = {title: 'Шагналтай уралдаан', raceId: data.raceId, message: ' уралдаанд ' + element.stage + ' -р байр эзэлсэн байна. Танд баяр хүргэе.', seen: false}
              this.notification.push(notify);
            }
          }
        });
      }
    },
  }
};
</script>
