<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Pattaya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@100&display=swap');
.wpm {
  position: absolute;
  color: #fff;
  font-size: 50px;
  top: 190px;
  font-weight: 700;
  right: 135px;
  width: 150px;
}
.title {
  padding-top: 300px;
  text-align: left;
  font-size: 30px;
  color: grey;
  font-weight: 300;
}
.username {
  font-size: 50px;
  /* margin-top: -10px; */
  font-family: 'Pattaya', sans-serif;
  color: #000;
}
.bodies {
  padding-left: 125px;
  text-align: left;
}
.texts {
  font-size: 19px;
  color: grey;
  font-weight: 300;
  /* margin-top: -10px; */
}
.qr-text {
  margin-top: 40px;
}
.qr-text2 {
  margin-top: 60px;
  padding-left: 20px;
  font-weight: 400;
  font-size: 15px;
  color: #000;
}
</style>
<template>
  <div>
    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        filename="certificate"
        :pdf-quality="3"
        :manual-pagination="true"
        :paginate-elements-by-height="1"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="1123px"
        @progress="onProgress($event)"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <section class="pdf-content">
              <div align="center">
                <div :style="{'margin-left': '-17px', 'position': 'relative', 'width': '1122.5px', 'height': '793.7px', 'background-image': `url(${require(`@/assets/images/logo/certificate.png`)})`, 'background-size': '1122.5px 793.7px'}">
                  <!-- <img src="@/assets/images/logo/certificate.png" alt=""> -->
                  <div class="wpm">
                    {{wpm}}<p :style="{'font-size': '30px', 'margin-top': '-20px'}">WPM</p>
                  </div>
                  <div class="bodies">
                    <div class="title">
                      Эрхэм хүндэт
                    </div>
                    <div class="username">
                      {{name}}
                    </div>
                    <div class="texts">
                      танд {{new Date(start).getFullYear()}} оны {{new Date(start).getMonth() + 1}} сараас {{new Date(end).getFullYear()}} оны {{new Date(end).getMonth() + 1}} сарын хооронд www.hurdanhuruu.mn сайтын арван<br>
                      хуруугаар алдаагүй, хурдан бичиж сурах сургалтанд хамрагдаж, нийт 278 хичээлийг<br>амжилттай үзэж {{wpm}} WPM-ийн дундаж хурдтайгаар бичиж сурсан тул гэрчилгээ олгов.
                    </div>
                    <div class="flex">
                      <div class="qr-text col-auto">
                        <qrcode-vue :value="'https://www.hurdanhuruu.mn/check-certificate?token='+token" :size="150" level="H"></qrcode-vue>
                      </div>
                      <div class="qr-text2 col-auto">
                        Хөгжүүлэгч: iCBC LLC, ITLAB Developer team<br>
                        Вэб: www.hurdanhuruu.mn<br>
                        Цахим шуудан: support@itlab.mn<br>
                        Утас: 75117733
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </section>
        </section>
    </vue-html2pdf>

    <section>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
              <h2 class="text-lg font-medium mr-auto">Сертификат</h2>
              <div v-if="available == true" class="w-full sm:w-auto flex mt-4 sm:mt-0">
                <div class="dropdown ml-auto sm:ml-0">
                  <button
                    class="dropdown-toggle button px-2 box text-gray-700 dark:text-gray-300"
                  >
                    <span class="w-5 h-5 flex items-center justify-center">
                      <PlusIcon class="w-4 h-4" />
                    </span>
                  </button>
                  <div class="dropdown-box w-40">
                    <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                      <a v-if="!textShow"
                        href="javascript:;"
                        @click="toPdf"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >
                        <FileIcon class="w-4 h-4 mr-2" /> PDF <br>
                      </a>
                      <el-progress v-if="textShow" :percentage="progress" :color="'grey'" :show-text="false"></el-progress>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- BEGIN: Certificate -->
            <div class="overflow-hidden mt-5 intro-y" align="center">
                <div ref="cert-body" class="box" :style="{'position': 'relative', 'width': '1122.5px', 'height': '793.7px', 'background-image': `url(${require(`@/assets/images/logo/certificate.png`)})`, 'background-size': '1122.5px 793.7px'}">
                  <!-- <img src="@/assets/images/logo/certificate.png" alt=""> -->
                  <div class="wpm">
                    {{wpm}}<p :style="{'font-size': '30px', 'margin-top': '-20px'}">WPM</p>
                  </div>
                  <div class="bodies">
                    <div class="title">
                      Эрхэм хүндэт
                    </div>
                    <div class="username">
                      {{name}}
                    </div>
                    <div class="texts">
                      танд {{new Date(start).getFullYear()}} оны {{new Date(start).getMonth() + 1}} сараас {{new Date(end).getFullYear()}} оны {{new Date(end).getMonth() + 1}} сарын хооронд www.hurdanhuruu.mn сайтын арван<br>
                      хуруугаар алдаагүй, хурдан бичиж сурах сургалтанд хамрагдаж, нийт 278 хичээлийг<br>амжилттай үзэж {{wpm}} WPM-ийн дундаж хурдтайгаар бичиж сурсан тул гэрчилгээ олгов.
                    </div>
                    <div class="flex">
                      <div class="qr-text col-auto">
                        <qrcode-vue :value="'https://www.hurdanhuruu.mn/check-certificate?token='+token" :size="150" level="H"></qrcode-vue>
                      </div>
                      <div class="qr-text2 col-auto">
                        Хөгжүүлэгч: iCBC LLC, ITLAB Developer team<br>
                        Вэб: www.hurdanhuruu.mn<br>
                        Цахим шуудан: support@itlab.mn<br>
                        Утас: 75117733
                      </div>
                    </div>
                  </div>
                </div>
            </div>
    </section>
    
    <!-- END: Certificate -->

    <div id="payment-required-modal" class="modal">
            <div class="modal__content">
                <div class="p-5 text-center">
                    <div align="center"><img src="@/assets/images/logo/payment.png" class="w-24 h-24"></div>
                    <div class="text-gray-600 mt-2">
                        <span :style="{'font-size': '16px'}"><b>Амжилтгүй</b> </span><br>
                        Та сертификат авахын тулд <b>цааш</b> товчийг дарж үйлчилгээний эрхээ сунгана уу.
                    </div>
                </div>
                <div class="px-5 pb-8 text-center"> 
                    <button type="button" data-dismiss="modal" @click="paymentBack" class="button w-24 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"> Буцах </button>
                    <button type="button" data-dismiss="modal" @click="paymentNext" class="button w-24 bg-theme-9 text-white"> Цааш </button> 
                </div>
            </div>
        </div>

      <div id="not-allowed-modal" class="modal">
            <div class="modal__content">
                <div class="p-5 text-center">
                    <div align="center"><img src="@/assets/images/logo/key.svg" class="w-24 h-24"></div>
                    <div class="text-gray-600 mt-2">
                        <span :style="{'font-size': '16px'}"><b>Амжилтгүй</b> </span><br>
                        Танд сертификат авах эрх үүсээгүй байна. <br> Та хичээлээ бүрэн дуусгана уу.
                    </div>
                </div>
                <div class="px-5 pb-8 text-center"> 
                    <button type="button" data-dismiss="modal" @click="lesson" class="button w-24 bg-theme-9 text-white"> Ойлголоо </button> 
                </div>
            </div>
        </div>
        <div id="pdf-progress-modal" class="modal">
            <div class="modal__content">
                <div class="p-5 text-center">
                    hello
                </div>
            </div>
        </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  components: {
    QrcodeVue,
    VueHtml2pdf
  },
  data() {
    return {
      available: false,
      wpm: 0,
      start: '',
      end: '',
      name: '',
      token: '',
      progress: 0,
      textShow: false
    }
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    onProgress(progress) {
      this.progress = progress;
      if(progress == 100) {
        this.textShow = false;
      }
    },
    toPdf() {
      this.textShow = true;
      this.$refs.html2Pdf.generatePdf();
    },
    lesson() {
      this.$router.push('/lesson');
    },
    paymentBack() {
      this.$router.push('/beginner');
    },
    paymentNext() {
      this.$router.push('/payment');
    },
    getInfo() {
      var rts = this;
      const token = localStorage.getItem('token');
      this.token = token;
      if(token) {
        this.$axios({
            method: 'post',
            url: rts.$appUrl +`/api/user/get-certi`,
            headers: {
              "Authorization": `Bearer ${token}`
            }
          })
        .then(function(data){
          
          if(data.data.result == 'failed') {
            cash('#not-allowed-modal').modal('show');
            rts.lesson()
          } else {
            rts.wpm = data.data.info[0].wpm;
            rts.name = data.data.info[0].name;
            rts.start = data.data.info[0].start;
            rts.end = data.data.info[0].end;
            rts.available = true;
          }
        }).catch(error => {
          if(error.response.status == 402) {
            cash('#payment-required-modal').modal('show');
            rts.lesson();
          }
        });
      }
    }
  }
}
</script>
