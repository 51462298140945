<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
.m-font {
    font-family: 'Exo 2', sans-serif;
}
.advice-title {
    font-size: 18px;
}

</style>
<template>
  <div class="m-font">
    <div class="flex items-center mt-1">
      <!-- <h2 class="intro-y text-lg font-medium mr-auto">Wizard Layout</h2> -->
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div class="flex justify-center">
        <button
          class="intro-y w-10 h-10 rounded-full button text-white mx-2" v-bind:class="{'bg-theme-9 text-white': step == 1, 'bg-gray-200 dark:bg-dark-1 text-gray-600': step != 1}"
          @click="changeStep(1)"
        >
          1
        </button>
        <button
          class="intro-y w-10 h-10 rounded-full button mx-2" v-bind:class="{'bg-theme-9 text-white': step == 2, 'bg-gray-200 dark:bg-dark-1 text-gray-600': step != 2}"
          @click="changeStep(2)"
        >
          2
        </button>
        <button
          class="intro-y w-10 h-10 rounded-full button mx-2" v-bind:class="{'bg-theme-9 text-white': step == 3, 'bg-gray-200 dark:bg-dark-1 text-gray-600': step != 3}"
          @click="changeStep(3)"
        >
          3
        </button>
      </div>
      <div class="px-5 mt-5">
        <div class="font-medium text-center" :style="{'font-size': '30px'}">
            <p v-if="step == 1">Зөвлөгөө 1</p>
            <p v-else-if="step == 2">Зөвлөгөө 2</p>
            <p v-else-if="step == 3">Зөвлөгөө 3</p>
        </div>
        <div class="text-gray-600 text-center mt-1" :style="{'font-size': '20px'}">
          <span v-if="step == 1">Гарын үндсэн байрлал</span>
          <p v-else-if="step == 2">Компьютерийн гар руугаа хэзээ ч битгий хараарай!</p>
          <p v-else-if="step == 3">Хүн бүр өөрийн гэсэн хэрэглэгчийн бүртгэл үүсгээрэй!</p>
        </div>
      </div>

        <div class="tab-content">
            <div id="step-1" class="tab-content__pane" v-bind:class="{'active': step == 1}">
                <div class="px-5 sm:px-20 mt-6 pt-10 border-t border-gray-200 dark:border-dark-5">
                    <div class="grid grid-cols-12 gap-4 row-gap-5">
                        <div class="intro-y col-span-12 sm:col-span-12 pr-10 pl-10" align="center" :style="{'margin-top': '-20px'}">
                            <div class="text-gray-600 text-center advice-title">
                                <p id="step1Text"></p>
                            </div>
                            <img class="mt-5" :src="require(`@/assets/images/lesson/main_position.svg`)" :style="{'width': '40%', 'height': 'auto', 'max-width': '100%'}">
                        </div>
                        <div class="intro-y col-span-12 flex items-center justify-center sm:justify-end">
                            <button @click="previousStep" class="button w-24 justify-center block bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-300">
                                Өмнөх
                            </button>
                            <button @click="nextStep" class="button w-24 justify-center block bg-theme-9 text-white ml-2">
                                Дараах    
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="step-2" class="tab-content__pane" v-bind:class="{'active': step == 2}">
                <div class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
                    <div class="grid grid-cols-12 gap-4 row-gap-5 mt-5">
                        <div class="intro-y col-span-12 sm:col-span-12 pr-10 pl-10" align="center" :style="{'margin-top': '-20px'}">
                            <div class="text-gray-600 text-center advice-title">                                    
                                    <p id="step2Text"></p>
                            </div>
                            <img :src="require(`@/assets/images/lesson/nud.svg`)" :style="{'width': '25%', 'height': 'auto', 'max-width': '100%'}">
                        </div>
                        <div class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                            <button @click="previousStep" class="button w-24 justify-center block bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-300">
                                Өмнөх
                            </button>
                            <button @click="nextStep" class="button w-24 justify-center block bg-theme-9 text-white ml-2">
                                Дараах    
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="step-3" class="tab-content__pane" v-bind:class="{'active': step == 3}">
                  <div class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5">
                    <div class="grid grid-cols-12 gap-4 row-gap-5 mt-5">
                        <div class="intro-y col-span-12 sm:col-span-12 pr-10 pl-10" align="center" :style="{'margin-top': '-20px'}">
                            <div class="text-gray-600 text-center advice-title">
                                    <p id="step3Text"></p>
                            </div>
                            <img class="mt-5" :src="require(`@/assets/images/lessonBox/teamwork.svg`)" :style="{'width': '10%', 'height': 'auto', 'max-width': '10%'}">
                        </div>
                        <div class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
                            <button @click="previousStep" class="button w-24 justify-center block bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-300">
                                Өмнөх
                            </button>
                            <button @click="finishGame" class="button w-24 justify-center block bg-theme-9 text-white ml-2">
                                Дуусгах    
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>
<script>


import TypeIt from "typeit";
export default {
    data() {
        return {
            step: 1,
            text: {
                step1: '',
                step2: '',
                step3: ''
            },
            lesson : {
                id: '',
                lvl: ''
            }
        }
    },
    mounted() {
        this.lesson.id = this.$route.params.id;
        this.getText();
        this.step1 =  new TypeIt("#step1Text", {
            speed: 50,
            waitUntilVisible: true
          })
          .type("Гарын хуруунуудаа зураг дээр үзүүлсэнтэй адил байршуулаад", {delay: 300})
          .break({delay: 500})
          .type('гараа зөөхгүйгээр бичээрэй.')
          .pause(500);

        this.step2 = new TypeIt("#step2Text", {
            speed: 50,
            waitUntilVisible: true
        })
        .type("Компьютерийн гар руугаа харах нь гараа хэвшүүлэхэд саад болдог тул", {delay: 300})
        .break({delay: 500})
        .type('харахгүйгээр бичиж гарын зөв байршлыг эзэмшээрэй.')
        .pause(500);

        this.step3 = new TypeIt("#step3Text", {
            speed: 50,
            waitUntilVisible: true
        })
        .type("Олон хүн дундаа нэг бүртгэлтэй байх нь хичээл алгасах шалтгаан болдог тул", {delay: 300})
        .break({delay: 500})
        .type('хувийн бүртгэлээрээ манай хичээлүүдийг бичиж дуусгаснаар та <b>ГАРАМГАЙ</b> бичээч болох болно')
        .break({delay: 500})
        .type('<b>Танд амжилт хүсье!</b>')
        .pause(500);

        this.step1.reset();
        this.step1.go();
    },
    methods: {
        changeStep(val) {
            if(val == 1) {
                this.step = 1;
                this.step1.reset();
                this.step1.go();
            } else if (val == 2) {
                this.step = 2;
                this.step2.reset();
                this.step2.go();
            } else if (val == 3) {
                this.step = 3;
                this.step3.reset();
                this.step3.go();
            }
        },
        nextStep() {
            this.step++;
            if(this.step > 3) {
                this.finishGame();
            } else {
                if(this.step == 2) {
                    this.step2.reset();
                    this.step2.go();
                } else if (this.step == 3) {
                    this.step3.reset();
                    this.step3.go();
                }
            }
        },
        getText() {
            var rts = this;
            const token = localStorage.getItem('token');
            if(token) {
                this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/get-lesson',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        id: this.lesson.id
                    }
                })
                .then(function(data){
                    if(data.data.result == 'success') {
                        rts.lesson.lvl = data.data.data.lvl;
                        console.log(data);
                    } else {
                        rts.$router.push('/lesson');
                    }
                    
                }).catch(error => {
                    rts.Loading(false);
                    if(error.response.status == 402) {
                        rts.$router.push('/payment');
                    }
                });
            }else{
                this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/get-lesson',
                    data: {
                        id: this.lesson.id
                    }
                })
                .then(function(data){
                    if(data.data.result == 'success') {
                        rts.lesson.lvl = data.data.data.lvl;
                    } else {
                        rts.$router.push('/lesson');
                    }
                    
                }).catch(error => {
                    rts.Loading(false);
                    if(error.response.status == 402) {
                        rts.$router.push('/payment');
                    }
                });
            }
            
        },
        previousStep() {
            if(this.step > 1) {
                this.step--;
                if(this.step == 2) {
                    this.step2.reset();
                    this.step2.go();
                } else if(this.step == 1) {
                    this.step1.reset();
                    this.step1.go();
                }
            }
        },
        finishGame() {
            var score = 200;
                var rts = this;
                const token = localStorage.getItem('token');
                if(token) {
                    this.$axios({
                        method: 'post',
                        url: rts.$appUrl +'/api/lesson/update-user-lesson',
                        headers: {
                            "Authorization": `Bearer ${token}`
                        },
                        data: {
                            lessonId: this.lesson.id,
                            wpm: 30,
                            accuracy: 100,
                            score,
                            level: this.lesson.lvl
                        }
                    })
                    .then(function(data){
                        if(data.data.point > 0) {
                            rts.$emit('SetPointFull', data.data.point);
                        }
                        rts.$router.push({name: 'top-menu-success-lesson', params: {prev: rts.lesson.lvl}});
                    });
                }else{
                    this.$axios({
                        method: 'post',
                        url: rts.$appUrl +'/api/lesson/update-user-lesson',
                        data: {
                            lessonId: this.lesson.id,
                            wpm: 30,
                            accuracy: 100,
                            score,
                            level: this.lesson.lvl
                        }
                    })
                    .then(function(data){
                        if(data.data.point > 0) {
                            rts.$emit('SetPointFull', data.data.point);
                        }
                        rts.$router.push({name: 'top-menu-success-lesson', params: {prev: rts.lesson.lvl}});
                    });
                }
        }
    }
}
</script>