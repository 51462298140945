<style scoped>
.wrongLine{
  text-decoration: line-through;
}
.notify1 {
  width: 280px;
}
.notify2 {
  text-align: right; 
  width: 100%;
}
.notify3 {
  margin-top: 8px;
  text-align: right;
}
</style>
<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Update Profile</h2>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Profile Menu -->
      <div
        class="col-span-12 lg:col-span-4 xxl:col-span-3 flex lg:block flex-col-reverse"
      >
        <div class="intro-y box mt-5">
          <div class="relative flex items-center p-5">
            <div class="w-12 h-12 image-fit">
              <img
                class="rounded-full"
                :src="this.user.image"
              />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium text-base">
                {{ this.user.name }}
              </div>
              <div class="text-gray-600">#{{ this.user.id }}</div>
            </div>
            <div class="dropdown">
              <a class="dropdown-toggle w-5 h-5 block" href="javascript:;">
                <MoreHorizontalIcon
                  class="w-5 h-5 text-gray-700 dark:text-gray-300"
                />
              </a>
              <div class="dropdown-box w-56">
                <div class="dropdown-box__content box dark:bg-dark-1">
                  <div
                    class="p-4 border-b border-gray-200 dark:border-dark-5 font-medium"
                  >
                    Export Options
                  </div>
                  <div class="p-2">
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <ActivityIcon
                        class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"
                      />
                      English
                    </a>
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <BoxIcon
                        class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"
                      />
                      Indonesia
                      <div
                        class="text-xs text-white px-1 rounded-full bg-theme-6 ml-auto"
                      >
                        10
                      </div>
                    </a>
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <LayoutIcon
                        class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"
                      />
                      English
                    </a>
                    <a
                      href=""
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <SidebarIcon
                        class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"
                      />
                      Indonesia
                    </a>
                  </div>
                  <div
                    class="px-3 py-3 border-t border-gray-200 dark:border-dark-5 font-medium flex"
                  >
                    <button
                      type="button"
                      class="button button--sm bg-theme-1 text-white"
                    >
                      Settings
                    </button>
                    <button
                      type="button"
                      class="button button--sm bg-gray-200 text-gray-600 dark:bg-dark-5 dark:text-gray-300 ml-auto"
                    >
                      View Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <router-link
              class="flex items-center"
              to="/profile"
            >
              <UserIcon class="w-4 h-4 mr-2" /> Хувийн мэдээлэл
            </router-link>
            <router-link class="mt-5 flex items-center text-theme-1 dark:text-theme-10 font-medium" to="/update-profile">
              <BoxIcon class="w-4 h-4 mr-2" /> Бүртгэл засварлах
            </router-link>
            <router-link class="flex items-center mt-5" to="/change-password">
              <LockIcon class="w-4 h-4 mr-2" /> Нууц үг шинэчлэх
            </router-link>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <a class="flex items-center" href="">
              <ActivityIcon class="w-4 h-4 mr-2" /> Email Settings
            </a>
            <a class="flex items-center mt-5" href="">
              <BoxIcon class="w-4 h-4 mr-2" /> Saved Credit Cards
            </a>
            <a class="flex items-center mt-5" href="">
              <LockIcon class="w-4 h-4 mr-2" /> Social Networks
            </a>
            <a class="flex items-center mt-5" href="">
              <SettingsIcon class="w-4 h-4 mr-2" /> Tax Information
            </a>
          </div>
          <div class="p-5 border-t flex dark:border-dark-5">
            <button
              type="button"
              class="button button--sm block bg-theme-1 text-white"
            >
              New Group
            </button>
            <button
              type="button"
              class="button button--sm border text-gray-700 dark:border-dark-5 dark:bg-dark-5 dark:text-gray-300 ml-auto"
            >
              New Quick Link
            </button>
          </div>
        </div>
      </div>
      <!-- END: Profile Menu -->
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
        <!-- BEGIN: Display Information -->
        <div class="intro-y box lg:mt-5">
          <div
            class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Дэлгэрэнгүй мэдээлэл</h2>
          </div>
          <div class="p-5">
            <div class="grid grid-cols-12 gap-5">
              <div class="col-span-12 xl:col-span-4">
                <div
                  class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
                >
                  <div
                    class="w-40 h-40 relative image-fit cursor-pointer zoom-in mx-auto"
                  >
                    <img
                      class="rounded-md"
                      :src="this.user.image"
                    />
                    <!-- <Tippy
                      tag="div"
                      content="Remove this profile photo?"
                      class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2"
                    >
                      <xIcon class="w-4 h-4" />
                    </Tippy> -->
                  </div>
                  <div class="w-40 mx-auto cursor-pointer relative mt-5">
                    <button
                      type="button"
                      class="button w-full bg-theme-1 text-white"
                    >
                      Зураг солих
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      class="w-full h-full top-0 left-0 absolute opacity-0"
                      @change="onProfileImageSelected"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-12 xl:col-span-8">
                <form @submit.prevent="updateInfo">
                  <div>
                  <label>Нэр</label>
                  <input
                    type="text"
                    class="input w-full border bg-gray-100 cursor-not-allowed mt-2"
                    placeholder="Input text"
                    :value="this.user.name"
                    disabled
                  />
                </div>
                <div class="mt-3">
                  <label>ID</label>
                  <div class="mt-2">
                    <input
                      type="text"
                      class="input w-full border bg-gray-100 cursor-not-allowed mt-2"
                      placeholder="Input text"
                      :value="'#'+this.user.id"
                      disabled
                    />
                  </div>
                </div>
                <div class="mt-3">
                  <label>И-Мэйл</label>
                  <div class="mt-2">
                    <input
                      type="text"
                      class="input w-full border bg-gray-100 cursor-not-allowed mt-2"
                      placeholder="email"
                      :value="this.user.email"
                      disabled
                    />
                  </div>
                </div>

                <div class="mt-3">
                  <label>Утасны дугаар</label>
                  <div class="mt-2">
                    <input
                      type="text"
                      required
                      pattern="[0-9]{8}"
                      class="input w-full border mt-2"
                      placeholder="Phone number"
                      v-model="user.phone"
                      @keyup.enter="updateInfo"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  class="button w-40 bg-theme-1 text-white mt-3"
                >
                  Хадгалах
                </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      select: "1",
      user: {        
        name: '',
        id: '',
        phone: '',
        image: 'default.png',
        email: '',
      },
      selectedFile: null,
      status: {
        inviteConrfirm: false
      }
    };
  },
  mounted() {
    this.GetUser();
  },
  methods: {
    onProfileImageSelected (event) {
      const file = event.target.files[0];
      this.selectedFile = file;
      let kb = file.size / 1024;
      if(kb > 50) {
        this.$msgbox({
          showClose: true,
          message: 'Зурагны файл 50kb ихгүй байх ёстой.',
          type: 'error'
        });
      } else {
        this.updateProfile();
      }
    },
    GetUser() {
      const token = localStorage.getItem('token');
      var rts = this;
      this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/user/get-user-info',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(function(data) {
          if(data.data.result == 'success') {
            let info = data.data.data.info;
            rts.user.name = info.name;
            rts.user.image = info.image;
            rts.user.id = info.id;
            rts.user.phone = info.phone;
            rts.user.email = info.email;
          }
        }).catch(error => {
                if(error.response.status == 402) {
                    rts.$router.push('/payment');
                }
        });
    },
    Loading(val) {
      if(val == true) {
        this.$loading({
          lock: true,
          text: 'Түр хүлээнэ үү',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      } else {
        this.$loading().close();
      }
    },
    updateProfile() {
      var rts = this;
      this.Loading(true);
      const token = localStorage.getItem('token');
      if(this.selectedFile) {
        const fd = new FormData();
        fd.append('photo', this.selectedFile, this.selectedFile.name);
        this.$axios.post(this.$appUrl+'/api/user/update-profile', 
        fd,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        )
        .then(function(data) {
          rts.Loading(false);
          let user = localStorage.getItem('user');
          if(user) {
            user = JSON.parse(localStorage.getItem('user'));
            user.image = data.data.image;
            localStorage.user = JSON.stringify(user);
            rts.user.image = data.data.image;
            rts.$emit('ChangeProfileImage', data.data.image);
          }
        }).catch(error => {
                if(error.response.status == 402) {
                    rts.$router.push('/payment');
                }
        });
      }
    },
    updateInfo() {
      var rts = this;
      const token = localStorage.getItem('token');
      if(token) {
        this.$axios({
          method: 'post',
          url:this.$appUrl+'/api/user/update-profile-info', 
          headers: {
            'Authorization': `Bearer ${token}`
            },
          data: {
            phone: this.user.phone
            }
          }
        )
        .then(function(data) {
          let user = localStorage.getItem('user');
          if(user) {
            user = JSON.parse(localStorage.getItem('user'));
            user.phone = data.data.data;
            localStorage.user = JSON.stringify(user);
          }

          rts.$msgbox({
              message: 'Амжилттай шинэчлэгдлээ',
              type: 'success'
          });
        }).catch(error => {
                if(error.response.status == 402) {
                    rts.$router.push('/payment');
                }
        });
      }
    },
    joinRoom(roomId) {
        this.status.inviteConrfirm = true;
        this.$socket.emit('joinRoom', {
            roomId: roomId
        });
    },
    IgnoreRoom(roomId, userId) {
      this.status.inviteConrfirm = true;
      this.$socket.emit('ignoreRoom', {
        roomId: roomId,
        userId: userId
      });
    },
    confirmInvite(event) {
        let roomId = event.target.classList[event.target.classList.length - 1];
        this.joinRoom(roomId);
        this.$router.push('/room');
      },
    ignoreInvite(event) {
      let roomId = event.toElement.classList[event.toElement.classList.length - 2];
      let fromUserId = event.toElement.classList[event.toElement.classList.length - 1];
      this.IgnoreRoom(roomId, fromUserId);
    }
  },
  sockets: {
    inviteGame: function(data) {
      this.status.inviteConrfirm = false;
      var rts = this;
      let user = localStorage.getItem('user');
      if(user) {
        var id = JSON.parse(localStorage.getItem('user'));
        if(id.id === data.to) {
          const h = this.$createElement;
          rts.$notify({
            title: 'Урилга',
            showClose: false,
            dangerouslyUseHTMLString: true,
            duration: 6000,
            onClick: function() {
              this.close();
            },
            onClose: function() {
              if(rts.status.inviteConrfirm === false) {
                rts.IgnoreRoom(data.roomId, data.fromId);
              }
            },
            message: h('div', { 
              class: 'notify1' 
              }, [
                    h('div', 
                      { 
                        class: 'notify2'
                      }, data.from + ' таныг уралдаанд урьж байна'),
                      h('div', {
                          class: 'notify3'
                        }, [
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-9 text-white ${data.roomId}`,
                                  on: {
                                    click: rts.confirmInvite.bind()
                                  }
                                }, 'Зөвшөөрөх'
                              ),
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-6 text-white ${data.roomId} ${data.fromId}`,
                                  on: {
                                    click: rts.ignoreInvite.bind()
                                  }
                                }, 'Цуцлах'
                              )
                      ])
                    ])
          });

          
        }
      }
    }
  }
};
</script>
