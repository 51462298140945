<style scoped>
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800);
.snip1214 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #000000;
  text-align: center;
  font-size: 16px;
  width: 100%;
  max-width: 1000px;
  margin: 40px 10px;
}
.snip1214 .plan {
  margin: 0;
  width: 220px;
  position: relative;
  float: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.snip1214 .plan-free {
  margin: 0;
  width: 220px;
  position: relative;
  float: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.snip1214 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.snip1214 header {
  position: relative;
}
.snip1214 .plan-title {
  border-radius: 5px;
  position: relative;
  top: 0;
  font-weight: 800;
  padding: 5px 15px;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  display: inline-block;
  background-color: #222f3d;
  color: #ffffff;
  text-transform: uppercase;
}
.snip1214 .plan-cost {
  padding: 0px 10px 20px;
}
.snip1214 .plan-price {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 800;
  font-size: 2.4em;
  color: #34495e;
}
.snip1214 .plan-type {
  opacity: 0.6;
}
.snip1214 .plan-features {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: outside none none;
  font-size: 0.8em;
}
.snip1214 .plan-features li {
  border-top: 1px solid #d2d7e2;
  padding: 10px 5%;
}
.snip1214 .plan-features li:nth-child(even) {
  background: rgba(0, 0, 0, 0.08);
}
.snip1214 .plan-features i {
  margin-right: 8px;
  opacity: 0.4;
}
.snip1214 .plan-select {
  border-top: 1px solid #d2d7e2;
  padding: 10px 10px 0;

}
.snip1214 .plan-select a {
  background-color: #fff;
  color: #000;
  /* background-image: url('@assets/images/logo/qpay.png'); */
  background-repeat: no-repeat;
  text-decoration: none;
  /* padding: 0.5em 1em; */
  padding: 5px;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 5px;
}
.snip1214 .plan-select a:hover {
  background-color: #46627f;
}
.snip1214 .featured {
  margin-top: -10px;
  background-color: #1d40aa;
  color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.snip1214 .featured .plan-title,
.snip1214 .featured .plan-price {
  color: #ffffff;
}
.snip1214 .featured .plan-cost {
  padding: 10px 10px 20px;
}
.snip1214 .featured .plan-features li {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.snip1214 .featured .plan-select {
  padding: 20px 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 767px) {
  .snip1214 .plan {
    width: 50%;
    margin-bottom: 10px;
  }
  .snip1214 .plan-free {
    width: 50%;
    margin-bottom: 10px;
  }
  .snip1214 .plan-title,
  .snip1214 .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .snip1214 .plan-cost,
  .snip1214 .featured .plan-cost {
    padding: 20px 10px 20px;
  }
  .snip1214 .plan-select,
  .snip1214 .featured .plan-select {
    padding: 10px 10px 10px;
  }
  .snip1214 .featured {
    margin-top: 0;
  }
}
@media only screen and (max-width: 440px) {
  .snip1214 .plan {
    width: 100%;
    margin-bottom: 10px;
  }
  .snip1214 .plan-free {
    width: 100%;
    margin-bottom: 10px;
  }
}
.wrongLine{
  text-decoration: line-through;
}
.notify1 {
  width: 280px;
}
.notify2 {
  text-align: right; 
  width: 100%;
}
.notify3 {
  margin-top: 8px;
  text-align: right;
}
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    text-align: center;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .pricing-tabs .active {
    background: #5787e4;
    border-color: #5787e4;
  }
</style>
<template>
  <div>
    <h2 class="intro-y text-2xl font-medium mt-10 text-center mr-auto">
      {{title}}
    </h2>
    <!-- BEGIN: Pricing Tab -->
    <div class="intro-y flex justify-center mt-6">
      <div class="pricing-tabs nav-tabs box rounded-full overflow-hidden flex">
        
        <a
          data-toggle="tab"
          data-target="#layout-1-annual-fees"
          href="javascript:;"
          class="flex-1 w-32 lg:w-40 py-2 lg:py-3 whitespace-no-wrap text-center active"
          >Төлбөр төлөх</a
        >
        <a
          data-toggle="tab"
          data-target="#layout-3-coupon"
          href="javascript:;"
          class="flex-1 w-32 lg:w-40 py-2 lg:py-3 whitespace-no-wrap text-center"
          >Купон код ашиглах</a
        >

      </div>
    </div>
    <!-- END: Pricing Tab -->
    <!-- BEGIN: Pricing Content -->
    <div class=" mt-10">
      <div class="tab-content">
        <div
          id="layout-1-annual-fees"
          ref="layout2"
          class="tab-content__pane flex flex-col lg:flex-row active"
          
        >
        
          <div
            class="intro-y flex justify-center flex-col flex-1 text-center sm:px-10 lg:px-5 pb-10 lg:pb-0"
          >
          
            <div class="font-medium text-lg"  :style="{'padding-left': '50px', 'padding-right': '50px', 'width': '400px'}">Төлбөр төлөх заавар</div>
            <div class="mt-3 text-justify text-gray-700 dark:text-gray-600" :style="{'padding-left': '50px', 'padding-right': '50px', 'width': '400px'}">
              <p>
                Та төлөх товчийг дарснаар QR код үүсэх ба 
                Голомт банкнаас бусад бүх банкны аппликэшнээр төлөх боломжтой. 
                Төлбөр төлсөнөөс хойш 1 минутийн дотор таны төлбөр баталгаажиж үйлчилгээний эрх сунгагдана. 
              </p>
              <p class="mt-2">
                Лавлах утас: <b>75117733</b> <br>Ажлын өдрүүдэд 09:00-18:00 цаг <br>Имайл хаяг: <b>support@itlab.mn</b>
                <br><br>Танд амжилт хүсье. <br> ITLAB Developer team
              </p>
            </div>
          </div>

          <div class="snip1214" :style="{'width': '100%'}" align="center">
            <div class="plan-free"  ref="plan0" @mouseover="OverPlan('plan0')">
              <h3 class="plan-title">
                Үнэгүй
              </h3>
              <div class="plan-cost"><span class="plan-price">₮0</span></div>
              <ul class="plan-features">
                <li>0 хоног</li>
                <li><i class="ion-checkmark"> </i>51 ХИЧЭЭЛ</li>
                <li><i class="ion-close"> </i>Шагналтай уралдааны эрх</li>
                <li><i class="ion-checkmark"> </i>Техникийн тусламж</li>
                <li><i class="ion-close"> </i>Онлайн сертификат</li>
              </ul>
              <div class="plan-select"><a href="javascript:;" style="opacity: 0;">Төлөх</a></div>
            </div>
            <div class="plan" ref="plan1" @mouseover="OverPlan('plan1')">
              <h3 class="plan-title">
                Энгийн
              </h3>
              <div class="plan-cost"><span class="plan-price">₮9,900</span></div>
              <ul class="plan-features">
                <li><i class="ion-checkmark"> </i>30 ХОНОГ</li>
                <li><i class="ion-checkmark"> </i>278 ХИЧЭЭЛ</li>
                <li><i class="ion-checkmark"> </i>Шагналтай уралдааны эрх</li>
                <li><i class="ion-checkmark"> </i>Техникийн тусламж</li>
                <li><i class="ion-checkmark"> </i>Онлайн сертификат</li>
              </ul>
              <div class="plan-select">
                <div class="plan-select"><a href="javascript:;" @click="purchase(1)">Төлөх</a></div>
              </div>
            </div>
            <div class="plan featured" ref="plan2" @mouseover="OverPlan('plan2')">
              <h3 class="plan-title">
                Илүү ашигтай
              </h3>
              <div class="plan-cost"><span class="plan-price">₮14,900</span></div>
              <ul class="plan-features">
                <li><i class="ion-checkmark"> </i>90 ХОНОГ</li>
                <li><i class="ion-checkmark"> </i>278 ХИЧЭЭЛ</li>
                <li><i class="ion-checkmark"> </i>Шагналтай уралдааны эрх</li>
                <li><i class="ion-checkmark"> </i>Техникийн тусламж</li>
                <li><i class="ion-checkmark"> </i>Онлайн сертификат</li>
                
              </ul>
              <div class="plan-select"><a href="javascript:;" @click="purchase(2)">Төлөх</a></div>
            </div>
          </div>
        </div>

        <div id="layout-3-coupon" class="tab-content__pane flex flex-col lg:flex-row">
          <div class="grid grid-cols-12 gap-6" :style="{'margin-top': '1%'}">
            <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
              <div class="col-span-12 lg:col-span-12" align="center">
                <p class="intro-y text-xl font-medium mb-5 text-center mr-auto">
                Купон кодоо оруулна уу
                </p>
                <div :style="{'width': '150px', 'height': '130px', 'display': 'inline-block'}">
                  <input ref="coupon1" placeholder="0000" :disabled="inputDisable" @keyup="checkInput1" class="intro-y box p-5 sm:mt-5 border input" maxlength="4" :style="{'font-size': '25px', 'width': '100%', 'text-align': 'center', 'text-transform': 'uppercase'}"/>
                </div>

                <div :style="{'width': '150px', 'height': '130px', 'display': 'inline-block'}" class="sm:ml-5">
                  <input ref="coupon2" placeholder="0000" :disabled="inputDisable" @keyup="checkInput2" class="intro-y box p-5 sm:mt-5 border input" maxlength="4" :style="{'font-size': '25px', 'width': '100%', 'text-align': 'center', 'text-transform': 'uppercase'}"/>
                </div>

                <div :style="{'width': '150px', 'height': '130px', 'display': 'inline-block'}" class="sm:ml-5">
                  <input ref="coupon3" placeholder="0000" :disabled="inputDisable" class="intro-y box p-5 sm:mt-5 border input" maxlength="4" :style="{'font-size': '25px', 'width': '100%', 'text-align': 'center', 'text-transform': 'uppercase'}"/>
                </div>

                <!-- <div :style="{'width': '150px', 'height': '130px', 'display': 'inline-block'}" class="sm:ml-5">
                  <button class="button bg-theme-4 intro-y text-white p-5 sm:mt-5" @click="useCouponCode" :style="{'height': '85px', 'width': '100%', 'text-align': 'center'}">
                      <span :style="{'font-size': '20px'}">Ашиглах</span>
                  </button>
                </div> -->
                <br>
                <button class="button bg-theme-4 intro-y text-white" @click="useCouponCode" :style="{'height': '50px', 'width': '28%', 'text-align': 'center'}">
                      <span :style="{'font-size': '15px'}">Ашиглах</span>
                </button>

              </div>

              <div class="col-span-12 lg:col-span-12 mt-8" :style="{'padding-left': '200px', 'padding-right': '200px', 'opacity': '0'}">
                <div class="intro-y box p-5 mt-12 sm:mt-5">
                  <div class="flex flex-col xl:flex-row xl:items-center">
                    <div class="flex" :style="{'width': '100%', 'height': '100px', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': '700px 350px', 'cursor': 'auto'}">
                        <div style="width:100%; height: 100px; margin: auto; display: flex; flex-wrap: nowrap;">
                            <div style="width: 50%; line-height: 100px; font-size: 50px; text-align: left; overflow: hidden; white-space: nowrap;" class="main-text-content">
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                              <span style="margin-right: 15px;">hello</span>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="qpay_modal" ref="qpay_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false" @click.self="hideQpay">
     <div class="modal__content">
         <div class="p-5 text-center">
            <div class="mt-5" :style="{'font-size': '20px'}">Та ухаалаг банкны аппликэйшн хэрэглэж <span :style="{'font-weight': '700'}">QPAY</span> - ээр төлнө үү!</div>
             <div class="grid grid-cols-12" style="padding-left: 110px">
               <qrcode-vue :value="payment.qr" :size="200" level="H" style="width:70%; height:70%; margin-top: 20px; margin-bottom: 20px;"></qrcode-vue>
             </div>
             <div>
               <div align="center" :style="{'font-size': '18px'}">Шилжүүлгийн мэдээлэл</div>
               <div align="center">
                 <div> <span :style="{'width': '170px', 'display': 'inline-block', 'text-align': 'right', 'padding-right': '20px', 'margin-left': '-32px'}">ГОЛОМТ БАНК: </span><span :style="{'font-weight': '700'}">1410002791</span></div>
                 <div> <span :style="{'width': '170px', 'display': 'inline-block', 'text-align': 'right', 'padding-right': '20px'}">ГҮЙЛГЭЭНИЙ УТГА: </span><span :style="{'font-weight': '700'}">{{payment.invoiceId}}</span></div>
                 <div> <span :style="{'width': '170px', 'display': 'inline-block', 'text-align': 'right', 'padding-right': '20px'}">ХҮЛЭЭН АВАГЧ: </span><span :style="{'font-weight': '700'}">АЙ СИ БИ СИ ХХК</span></div>
                 <div class="mt-5"><span :style="{'font-weight': '700'}">АНХААРУУЛГА</span> Төлбөрийг дансанд шилжүүлсэн тохиолдолд <span :style="{'font-weight': '700'}">ажлын цагийн 30-60 минутанд</span> шалгагдаж баталгаажих болно.</div>
               </div>
             </div>
         </div>
         <!-- <div class="px-5 pb-8 text-center"><button type="button" data-dismiss="modal" @click="hiddenResultModal" class="button w-24 bg-theme-1 text-white"> За </button> </div> -->
     </div>
    </div>  

  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
let timer = null;
let checker = null;

export default {
  components: {
    QrcodeVue
  },
  data() {
    return {
      title: '',
      salesReportFilter: "",
      coupon: '',
      inputDisable: false,
      payment: {
        invoiceId: '',
        paymentId: '',
        qr: '',
        showModal: false
      }
    };
  },
  computed: {
    build: function() {
      return this.texts.typed;
    },
  },
  beforeRouteLeave (to, from , next) {
    clearInterval(checker);
    next();
  },
  mounted() {
    const token = localStorage.getItem('token');
      if(!token){
        this.$router.push("/login");
      }else{
        checker = setInterval(this.usingChecker, 5000);
        this.checkExpire();
        this.Loading(false);
      }
  },
  methods: {
    OverPlan(plan) {
      if(plan == 'plan1') {
        this.$refs['plan1'].classList.add('featured');
        this.$refs['plan2'].classList.remove('featured');
        this.$refs['plan0'].classList.remove('featured');
      } else if(plan == 'plan2') {
        this.$refs['plan1'].classList.remove('featured');
        this.$refs['plan2'].classList.add('featured');
        this.$refs['plan0'].classList.remove('featured');
      } else {
        this.$refs['plan1'].classList.remove('featured');
        this.$refs['plan2'].classList.remove('featured');
        this.$refs['plan0'].classList.add('featured');
      }
    },
    Loading(val) {
      if(val == true) {
        this.$loading({
          lock: true,
          text: 'Түр хүлээнэ үү',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      } else {
        this.$loading().close();
      }
    },
    checkExpire() {
      let user = JSON.parse(localStorage.getItem('user'));
      let available = new Date(user.end_at).toISOString().replace(/T/, ' ').replace(/\..+/, '');
      
      let current = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
      if(current > available) {
        this.title = 'Таны үйлчилгээний хугацаа дууссан байна';
      } else {
        this.title = 'Та доорх багцуудаас сонгож эрхээ сунгана уу';
      }
    },
    checkInput1() {
      if(event.path[0].value.length == 4) {
        this.$refs.coupon2.focus();
      }
    },
    checkInput2() {
      if(event.path[0].value.length == 4) {
        this.$refs.coupon3.focus();
      }
    },
    useCouponCode() {
      this.inputDisable = true;
      let coupon = this.$refs.coupon1.value + this.$refs.coupon2.value + this.$refs.coupon3.value;
      var rts = this;
      const token = localStorage.getItem('token');
      this.$axios({
          method: 'post',
          url: rts.$appUrl +'/api/purchase/use-coupon',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {
            coupon: coupon
          }
        })
      .then(function(data){
        rts.inputDisable = false;
        if(data.data.result == 'fail') {
          rts.$msgbox({
            showClose: true,
            message: 'Купон код буруу эсвэл ашиглагдсан байна.',
            type: 'error'
          });
        } else {
          rts.$confirm('Энэхүү купон кодыг ашиглахдаа итгэлтэй байна уу?', 'Санамж', {
            confirmButtonText: 'Тийм',
            cancelButtonText: 'Болих',
            type: 'warning',
            center: true
          }).then(() => {
            rts.$axios({
              method: 'post',
              url: rts.$appUrl +'/api/purchase/confirm-coupon',
              headers: {
                'Authorization': `Bearer ${token}`
              },
              data: {
                coupon: coupon,
                month: data.data.data.month
              }
            }).then(data => {
              
              let user = localStorage.getItem('user');
              if(user) {
                user = JSON.parse(localStorage.getItem('user'));
                user.end_at = data.data.end_at;
                localStorage.user = JSON.stringify(user);
                rts.$emit('calcEndDate');
              }
              rts.$router.push('/beginner');
            });
          });
        }
      }).catch(error => {
        console.log(error);
      });
    },
    usingChecker() {
      if(this.payment.invoiceId != '') {
        const token = localStorage.getItem('token');
        let rts = this;
        this.$axios({
          method: 'post',
          url: this.$appUrl +'/api/purchase/check-qpay-bill',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {
              invoiceId: this.payment.invoiceId,
          }
        })
        .then(function(data){
          if(data.data.data.status == 'paid') {
            let user = localStorage.getItem('user');
              if(user) {
                user = JSON.parse(localStorage.getItem('user'));
                user.end_at = data.data.data.end_at;
                localStorage.user = JSON.stringify(user);
                rts.$emit('calcEndDate');
              }
              rts.$notify({
                title: 'Амжилттай',
                message: 'Төлбөр амжилттай төлөгдлөө',
                type: 'success',
                duration: 0
              });
              rts.payment.invoiceId = '';
              rts.payment.paymentId = '';
              rts.payment.qr = '';
              rts.$router.push('/beginner');
          }
          
        });
      }
    },
    hideQpay() {
      this.payment.showModal = false;
    },
    purchase(val) {
      const token = localStorage.getItem('token');
      var rts = this;

      this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/purchase/qpay-create-bill',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {
              level: val,
          }
        }).then(function(data) {
          if(data.data.result == 'fail') {
            rts.$msgbox({
              message: data.data.data,
              type: 'warning'
            });
          } else {
            rts.payment.invoiceId = data.data.invoice_id;
            rts.payment.paymentId = data.data.payment_id;
            rts.payment.qr = data.data.qr;
            rts.payment.showModal = true;
            cash("#qpay_modal").modal("show");
          }
        }).catch(error => {
          console.log(error);
        });
    }
  }
}
</script>
