<style scoped>
.wrongLine{
  text-decoration: line-through;
}
.notify1 {
  width: 280px;
}
.notify2 {
  text-align: right; 
  width: 100%;
}
.notify3 {
  margin-top: 8px;
  text-align: right;
}
.dd-none {
  display: none;
}
</style>
<template>
    <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
      <div class="col-span-12 mt-6">
        <div class="intro-y block sm:flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Идэвхтэй өрөөнүүд</h2>
          <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
            <button
              class="button box flex items-center text-gray-700 dark:text-gray-300"
              @click="this.beforeCreateRoom"
            >
              <HomeIcon class="hidden sm:block w-4 h-4 mr-2" />
              Шинэ өрөө үүсгэх
            </button>
          </div>
        </div>
        <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
          <table v-if="room.list.length === 0" class="table table-report sm:mt-2">
            <tr>
              <td align="center">
                Одоогоор идэвхитэй өрөө алга байна
              </td>
            </tr>
          </table>
          <table v-else class="table table-report sm:mt-2"> 
            <thead>
              <tr>
                <th class="whitespace-no-wrap" align="center" width="3%"><HomeIcon class="w-5 h-5 mx-auto" /></th>
                <th class="whitespace-no-wrap" align="center">Зураг</th>
                <th class="whitespace-no-wrap">Үүсгэсэн тоглогч</th>
                <th class="whitespace-no-wrap">Цол</th>
                <th class="whitespace-no-wrap">Өрөөний ID</th>
                <th class="whitespace-no-wrap">Тоглогчид</th>
                <th class="text-center whitespace-no-wrap"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rooms, index) in room.list"
                :key="index"
                class="intro-x"
              >
              <td align="center">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="puff" class="w-6 h-6" />
                </div>
              </td>
                <td class="w-60" align="center">
                  <div class="flex">
                    <div class="w-10 h-10 image-fit zoom-in" :style="{'margin': 'auto'}">
                      <Tippy
                        tag="img"
                        class="rounded-full"
                        :src="`${rooms.image}`"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <a href="" class="font-medium whitespace-no-wrap">{{
                    rooms.name
                  }}</a>
                  <div class="text-gray-600 text-xs whitespace-no-wrap">
                    #{{ rooms.id }}
                  </div>
                </td>
                <td>
                    <img :src="`${$appUrl}/images/rank/${rooms.r_image}`" :style="{'width': 'auto', 'height': '40px', 'box-shadow': 'none'}"/>
                </td>
                <td>{{ rooms.roomId }}</td>
                <td>
                  <div v-for="(count, index) in room.players"
                  :key="index">
                  <span v-if="count.roomId === rooms.roomId">{{count.total}} / 5</span>
                  </div>
                </td>
                <td class="table-report__action w-56">
                  <div class="flex justify-center items-center">
                    <a href="javascript:;" class="" @click="joinRoomRequest(rooms.roomId)">
                      <div class="flex items-center mr-3 whitespace-no-wrap" :ref="'joinDiv'+rooms.roomId">
                        <LogInIcon class="w-4 h-4 mr-1" />
                        <span :style="{'padding-left': '10px'}">Нэгдэх</span>
                      </div>
                      <div class="flex items-center mr-3 whitespace-no-wrap dd-none" :ref="'joinDiv2'+rooms.roomId"><LoadingIcon icon="oval" class="w-4 h-4 mr-1" /> Хүлээгдэж байна</div>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
      <div id="question-modal" class="modal">
          <div class="modal__content">
              <div class="p-5 text-center">
                  <div class="mt-2"> 
                    <p class="mb-5">Хэлээ сонгоно уу</p>
                    <button class="button w-full inline-block mr-1 mb-2 border border-theme-1 text-theme-1 dark:border-theme-10 dark:text-theme-10" data-dismiss="modal" @click="createRoom('мон')"> Монгол </button>
                    <button class="button w-full inline-block mr-1 mb-2 border border-theme-1 text-theme-1 dark:border-theme-10 dark:text-theme-10" data-dismiss="modal" @click="createRoom('eng')"> English </button>
                  </div>
              </div>
          </div>
      </div>
      <div id="login_modal" ref="login_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div class="modal__content">
                    <div class="p-5 text-center">
                        <LockIcon class="w-12 h-12 mx-auto " :style="{'color': 'grey'}"/>
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 xl:col-span-12 mt-2" :style="{'border-bottom': '1px solid #d0d2d6', 'padding-bottom': '20px'}">
                                <div class="text-gray-600 mt-2 text-xl"> Та нэвтэрсний дараа өрөө үүсгэх боломжтой. </div>
                                <div class="p-5 grid grid-cols-12 gap-4 row-gap-3">
                                <div class="col-span-12 sm:col-span-12"> <input type="email" v-model="login.Uname" required class="input w-full border mt-2 flex-1" placeholder="И-мэйл хаяг" /> </div>
                                <div class="col-span-12 sm:col-span-12"> <input type="password" v-model="login.Upass" required class="input w-full border mt-2 flex-1" placeholder="Нууц үг" /> </div>

                           </div>
                 </div>
            </div>
            </div>
                <div class="intro-x mt-5 xl:mt-1 text-center pb-6">
                <button
                  class="button button--lg w-full xl:w-40 text-white bg-theme-1 xl:mr-3 align-top"
                  :disabled='texts.isDisabled'
                  v-html="buttontext"
                  @click="Login"
                >
                </button>
                
                <button
                  class="button button--lg w-full xl:w-40 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
                  @click="toRegister"
                >
                  Бүртгүүлэх
                </button>
            </div>
                </div>
            </div> 
    </div>
</template>
<script>
export default {
    data() {
        return {
          login:{
                Uname:'',
                Upass:''
            },
            texts:{
                disabled:false
            },
            buttontext:"нэвтрэх",
            room: {
                list: [],
                players: []
            },
            status: {
              inviteConrfirm: false
            }
        }
    },
    mounted() {
        this.getPlayers();
    },
    methods: {
      toRegister(){
            cash("#login_modal").modal("hide");
            this.$router.push("/register");
        },
        Login() {
        var rts = this;
        rts.texts.disabled = true;
        this.buttontext = 'Уншиж байна..';
        this.$axios({
          method: 'POST',
          url: rts.$appUrl +'/api/user/login',
          headers: {
            'Content-Type': 'application/json'
          }, 
          data: {
            email: this.login.Uname,
            password: this.login.Upass
          }
        }).then(function(data){
            rts.buttontext = 'Нэвтрэх';
            rts.texts.disabled = false;
            if(data.data.result === 'fail') {

              rts.$msgbox({
                message: data.data.data,
                type: 'error'
              });

            } else if(data.data.result === 'success') {
                cash("#login_modal").modal("hide");
              rts.$msgbox({
                message: 'Системд амжилттай нэвтэрлээ.',
                type: 'success'
              });

              // rts.$axios.defaults.headers.common.authorization = `Bearer ${data.data.data[0].token}`;
              localStorage.token = data.data.token;
              localStorage.user = JSON.stringify(data.data.data);
              // rts.$router.push("/beginner");
              setTimeout(() => {
                window.location.reload();

              }, 1000);
            }
        });
    },
      Loading(val) {
        if(val == true) {
          this.$loading({
            lock: true,
            text: 'Түр хүлээнэ үү',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
        } else {
          this.$loading().close();
        }
      },
        getRooms() {          
          const token = localStorage.getItem('token');
            var rts = this;
            if(token) {
                this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/room/get-all-room',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(function(data) {
                  rts.Loading(false);
                    rts.room.list= data.data.data;
                    }).catch(error => {
                      if(error.response.status == 402) {
                        rts.$router.push('/payment');
                      }
                    });
            }else{
              this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/room/get-all-room'
                }).then(function(data) {
                  rts.Loading(false);
                    rts.room.list= data.data.data;
                    }).catch(error => {
                      if(error.response.status == 402) {
                        rts.$router.push('/payment');
                      }
                    });
            }
        },
        getPlayers() {
          this.Loading(true);
          const token = localStorage.getItem('token');
            var rts = this;
            if(token) {
                this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/room/get-players-room',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(function(data) {
                    rts.room.players = data.data.data;
                    rts.getRooms();
                }).catch(error => {
                  if(error.response.status == 402) {
                    rts.$router.push('/payment');
                  }
                });
            }else{
              this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/room/get-players-room'
                }).then(function(data) {
                    rts.room.players = data.data.data;
                    rts.getRooms();
                }).catch(error => {
                  if(error.response.status == 402) {
                    rts.$router.push('/payment');
                  }
                });
            }
        },
      joinRoom(roomId) {
        const token = localStorage.getItem('token');
        if(token){
          this.status.inviteConrfirm = true;
          this.$socket.emit('joinRoom', {
              roomId: roomId
          });
        }else{
          cash("#login_modal").modal("show");
        }
      },
      IgnoreRoom(roomId, userId) {
        this.status.inviteConrfirm = true;
        this.$socket.emit('ignoreRoom', {
          roomId: roomId,
          userId: userId
        });
      },
      confirmInvite(event) {
        let roomId = event.target.classList[event.target.classList.length - 1];
        this.joinRoom(roomId);
        this.$router.push('/room');
      },
      ignoreInvite(event) {
        let roomId = event.toElement.classList[event.toElement.classList.length - 2];
        let fromUserId = event.toElement.classList[event.toElement.classList.length - 1];
        this.IgnoreRoom(roomId, fromUserId);
      },
      joinRoomRequest(roomId) {
        const token = localStorage.getItem('token');
        if(token){
          this.$socket.emit("joinRequest", {
            roomId: roomId
          });
          this.$refs['joinDiv'+roomId][0].classList.add('dd-none');
          this.$refs['joinDiv2'+roomId][0].classList.remove('dd-none');
          setTimeout(() => {
            this.$refs['joinDiv'+roomId][0].classList.remove('dd-none');
            this.$refs['joinDiv2'+roomId][0].classList.add('dd-none');
          }, 5000);
        }else{
          cash("#login_modal").modal("show");
        }
      },
      createRoom(lang) {
          this.$router.push({name: 'top-menu-create-race', params: {lang: lang}});
      },
      beforeCreateRoom() {
        const token = localStorage.getItem('token');
        if(token){
          cash('#question-modal').modal('show');
        }else{
          cash("#login_modal").modal("show");
        }
      }
    },
    sockets: {
      newRoom: function(data) {
        let ex = this.room.list.some(function(field) {
          return field.roomId === data.data.roomId
        });
        
        if(!ex) {
          this.room.list.push(data.data);
        }
      },
      acceptedRequest: function(data) {
        let user = localStorage.getItem('user');
        if(user) {
          var id = JSON.parse(localStorage.getItem('user'));
          if(id.id == data.user) {
            this.joinRoom(data.roomId);
            this.$router.push('/room');
          }
        }
      },
      newRoomPlayers: function(data) {
        let ex = this.room.players.some(function(field) {
          return field.roomId === data.data.roomId
        });
        if(!ex) {
          this.room.players.push(data.data);
        } else {
          this.room.players.forEach(element => {
            if(element.roomId === data.data.roomId) {
              element.total = data.data.total;
            }
          });
        }
      },
      deletedRoom: function(data) {
          this.room.list.forEach((element, index) => {
            
            if(element.roomId == data.roomId) {
              this.room.list.splice(index, 1);
            }
          });
      },
      inviteGame: function(data) {
      this.status.inviteConrfirm = false;
      var rts = this;
      let user = localStorage.getItem('user');
      if(user) {
        var id = JSON.parse(localStorage.getItem('user'));
        if(id.id === data.to) {
          const h = this.$createElement;
          rts.$notify({
            title: 'Урилга',
            showClose: false,
            dangerouslyUseHTMLString: true,
            duration: 6000,
            onClick: function() {
              this.close();
            },
            onClose: function() {
              if(rts.status.inviteConrfirm === false) {
                rts.IgnoreRoom(data.roomId, data.fromId);
              }
            },
            message: h('div', { 
              class: 'notify1' 
              }, [
                    h('div', 
                      { 
                        class: 'notify2'
                      }, data.from + ' таныг уралдаанд урьж байна'),
                      h('div', {
                          class: 'notify3'
                        }, [
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-9 text-white ${data.roomId}`,
                                  on: {
                                    click: rts.confirmInvite.bind()
                                  }
                                }, 'Зөвшөөрөх'
                              ),
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-6 text-white ${data.roomId} ${data.fromId}`,
                                  on: {
                                    click: rts.ignoreInvite.bind()
                                  }
                                }, 'Цуцлах'
                              )
                      ])
                    ])
          });

          
        }
      }
    }
    }
}
</script>