<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  /* height: 120px; */
  border-radius: 10px;
}
.card-img {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  /* height: 120px; */
  border-radius: 50%;
}
.card-img:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.body {
    width: 100%;
    padding-bottom: 80px;
    margin-top: 50px;
    padding-top: 80px;
}
.user-info {
    width: 600px;
    max-width: 100%;
    position: relative;
}
.img {
    width: 150px;
    height: 150px;
    /* position: absolute; */
    margin-top: -60px;
}
.info {
    /* padding-top: 105px; */
}

.name {
    font-size: 30px;
    font-weight: 400;
}
.spant{
    margin-top: -10px;
    font-size: 18px;
}
table {
    width: 60%; 
    margin-bottom: 50px;
    font-size: 15px;
}
table tbody tr td {
    height: 30px;
}
@media screen and (max-width: 600px) {
  .text {
      display: none;
  }
  table {
    width: 80%; 
    margin-bottom: 50px;
    font-size: 15px;
  }
}
</style>
<template>
    <div>
        <DarkModeSwitcher />
        <div class="container">
            <div class="block xl:grid grid-cols-2 gap-4">
                <router-link to="/beginner" class="-intro-x flex items-center pt-5">
                    <img
                        class="w-6"
                        src="@/assets/images/logo/logo.png"
                        style="width: auto; height: 30px;"
                    />
                    <span class="-intro-x text-lg text-white dark:text-gray-500 pl-10 text">Бүх нийтийн боловсролд хуруу нэмнэ.</span>
                </router-link>
            </div>
            <div class="body box" align="center">
                <div class="card user-info">
                    <div>hello</div>
                    <img
                        class="rounded-full img card-img"
                        :src="user.image"
                    />
                    <div class="info">
                        <span class="name">{{user.name}}</span><br>
                        <p class="text-gray-600 spant">#{{user.id}}</p>
                    </div>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td align="left"><b>Утас</b></td>
                                    <td align="right">{{user.phone}}</td>
                                </tr>
                                <tr>
                                    <td align="left"><b>Имэйл</b></td>
                                    <td align="right">{{user.email}}</td>
                                </tr>
                                <tr>
                                    <td align="left"><b>Бүртүүлсэн огноо</b></td>
                                    <td v-if="user.start == ''" align="right">Тодорхойгүй</td>
                                    <td v-else align="right">{{user.start.split('T')[0]}}</td>
                                </tr>
                                <tr>
                                    <td align="left"><b>Гэрчилгээ авсан огноо</b></td>
                                    <td v-if="user.end == ''" align="right">Тодорхойгүй</td>
                                    <td v-else align="right">{{user.end.split('T')[0]}}</td>
                                </tr>
                                <tr>
                                    <td align="left"><b>Дундаж хурд (WPM)</b></td>
                                    <td align="right">{{user.wpm}}</td>
                                </tr>
                                <tr>
                                    <td style="height: 50px;"></td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DarkModeSwitcher from "@/components/DarkModeSwitcher";
export default {
    components: {
        DarkModeSwitcher
    },
    data() {
        return {
            token: '',
            user: {
                id: '000000',
                name: 'Unknown',
                email: 'Unknown',
                phone: '0000-0000',
                start: '',
                end: '',
                wpm: 0,
                image: `${require('@/assets/images/placeholders/200x200.jpg')}`
            }
        }
    },
    mounted() {
        this.token = this.$route.query.token;
        this.getInfo();
    },
    methods: {
        getInfo() {
            var rts = this;
            this.$axios({
                method: 'post',
                url: rts.$appUrl +`/api/user/check-user-certi`,
                headers: {
                "Authorization": `Bearer ${this.token}`
                }
            })
            .then(function(data){
                if(data.data.result == 'failed') {
                    this.$notify.error({
                        title: 'Амжилтгүй',
                        message: 'Уучлаарай гэрчилгээний мэдээлэл одсонгүй',
                        duration: 0
                    });
                } else {
                    var i = data.data.info[0];
                    rts.user.id = i.id;
                    rts.user.name = i.name;
                    rts.user.email = i.email;
                    rts.user.phone = i.phone;
                    rts.user.image = i.image;
                    rts.user.wpm = i.wpm;
                    rts.user.start = i.start;
                    rts.user.end = i.end;
                }
            }).catch(error => {
                this.$notify.error({
                    title: 'Амжилтгүй',
                    message: 'Уучлаарай гэрчилгээний мэдээлэл одсонгүй',
                    duration: 0
                });
            });
        }
    }
}
</script>