<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap'); */

.bottom-box {
    width: 100%;
    bottom: 0;
    background: #fff;
    position: fixed;
    height: 50px;
    left: 0;
}
.text-span {
    font-size: 30px;
    font-weight: 500;
    height: 50px;
    width: 50px;
    display: inline-block;
    border-radius: 8px;
    position: relative;
    font-family: 'Roboto Mono', monospace;
}
.shake-span {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}
.span-normal {
    border: 1px solid #dadada;
    color: #fff;
    background: #0383F2;
}
.span-correct {
    border: 1px solid #10c373;
    color: #10c373;
    background: #e2f7e2;
} 
.span-error {
    border: 1px solid #ff8097;
    background: #ff8097;
}
.span-error-text {
    position: absolute;
    top : 5%; 
    left : 30%;
    color: #fff;
}
.current-span {
    height: 3px;
    width: 50px;
    background: #0383f2;
    margin-top: 15px;
    transition: 1s;
}
.correct-span {
    background: green;
}
.span-opacity-0 {
    opacity: 0.2;
}

.all-title {
    color: grey;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
.intro-title {
    font-family: 'Exo 2', sans-serif;
    font-size: 30px;
    padding-left: 10%;
}

.intro-text {
    font-family: 'Exo 2', sans-serif;
    font-size: 25px;
    padding-left: 10%;
}
.word-box {
    position: absolute;
    padding-left: 20%;
}

</style>
<template>
    <div>
        <div class="intro-x">
            <div :style="{'padding-top': '50px'}" align="center" @click="setFocus">
                <div class="intro-title flex items-center" align="left"><BellIcon class="mr-2 w-8 h-8" />ШИНЭ ҮСЭГ</div>
                <div align="left" class="flex intro-text mt-5">
                    <div :style="{'margin-top': '5px', 'padding-right': '20px'}">{{fingerText}}</div>
                    <div :contenteditable="this.status.contentEdit" @input="this.type" :style="{'opacity': '0'}" ref="inputDiv" id="inputDiv"></div>

                    <div v-for="(main, cc) in text.splitted.length" :key="cc" :ref="'parent-div-' + cc" align="left">
                        <div v-if="text.count == cc">
                            <div v-for="(text, index) in text.splitted[cc]" :key="index" :hidden="true" class="text-span ml-1 mr-1 span-normal" align="center" :ref="'main-span-' + index">
                                <div v-if="text != ' '" class="intro-y text-span-second items-center" :ref="'span-' + index">
                                    <span :ref="'child-span-' + index">{{ text }}</span>
                                </div>
                                <div v-else class="intro-y text-span-second items-center" :ref="'span-' + index">
                                    <span :ref="'child-span-' + index" :style="{'opacity': '0'}">a</span>
                                </div>
                                <div :ref="'span-error-word-' + index" class="span-error-text"></div>
                                <div :ref="'boxed-' + index" class="-lesson-intro-x current-span" v-if="index == counter.current">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <div :style="{'margin-top': '5px', 'padding-left': '20px'}">үсгэн дээр дарна уу.</div>
                </div>

                <div class="mt-5 flex" align="left">
                    <img :src="keyboardImage" :style="{'width': '40%', 'height': 'auto', 'max-width': '100%', 'margin-left': '22%'}">
                    <div align="left" class="mt-20">
                        <div align="left" :style="{'margin-top': '5%'}">
                            <!-- <p class="all-title">{{status.groupname}} / Зай авах товч</p> -->
                            <p class="all-title"><strong>{{status.groupname}} / {{status.lessonname}}</strong></p>
                            <p class="inside all-title" :style="{'font-size': '25px'}">Хичээл №<strong>{{lesson.lvl}}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-box z-50">
            <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
                <div class="col-span-4 sm:col-span-4 xxl:col-span-4" align="center"></div>
                <div class="col-span-4 sm:col-span-4 xxl:col-span-4" align="center">
                    <el-progress :text-inside="false" :color="'#91C715'" :stroke-width="10" :show-text="false" :percentage="progress" :style="{'width': '100%', 'margin-top': '22px'}" status="success"></el-progress>
                </div>
                <div class="col-span-4 sm:col-span-4 xxl:col-span-4" align="center"></div>
            </div>
        </div>

        <div id="payment-required-modal" class="modal">
            <div class="modal__content">
                <div class="p-5 text-center">
                    <!-- <xCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" /> -->
                    <div align="center"><img src="@/assets/images/lessonBox/congra.png" class="w-24 h-24"></div>
                    <div class="text-gray-600 mt-2">
                        <span :style="{'font-size': '16px'}"><b>Танд баяр хүргэе !</b> </span><br>
                        Та одоогийн байдлаар нийт <b>51</b> хичээлийг амжилттай давлаа. <br>
                        Үлдэгдэл хичээлийг үзэхийн тулд <b>цааш</b> товчийг дарж үйлчилгээний эрхээ сунгана уу.
                    </div>
                </div>
                <div class="px-5 pb-8 text-center"> 
                    <button type="button" data-dismiss="modal" @click="paymentBack" class="button w-24 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"> Буцах </button>
                    <button type="button" data-dismiss="modal" @click="paymentNext" class="button w-24 bg-theme-9 text-white"> Цааш </button> 
                </div>
            </div>
        </div>
        <div id="login_modal" ref="login_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div class="modal__content">
                    <div class="p-5 text-center">
                        <LockIcon class="w-12 h-12 mx-auto mt-3" :style="{'color': 'grey'}"/>
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 xl:col-span-12 mt-2" :style="{'border-bottom': '1px solid #d0d2d6', 'padding-bottom': '20px'}">
                                <div class="text-gray-600 mt-2 text-xl"> Та нэвтэрсний дараа хичээлээ үргэлжлүүлнэ үү. </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 pb-8 text-center"><button type="button" data-dismiss="modal" class="button w-24 bg-theme-1 text-white" @click="toLogin()"> OK </button> </div>
                </div>
            </div> 
    </div>
</template>
<script>
import BottomBar from "@/components/bottomBar";
let timer = null;
let correctSound;
let errorSound;
export default {
    components: {
        BottomBar
    },
    data() {
        return {
            progress: 0,
            keyboardImage: '',
            fingerText: '',
            text: {
                original : '',
                splitted: [],
                count: 0
            },
            counter: {
                current : 0,
                realWpm: 0,
                accuracy: 0,
                wpm: 0,
                start: false,
                time_passed : 1,
                characters: 0,
                errors: 0
            },
            status: {
                contentEdit: true,
                lessonname: '',
                groupname: ''
            },
            lesson : {
                id: '',
                lvl: ''
            },

        }
    },
    mounted() {
        this.lesson.id = this.$route.params.id;
        clearInterval(timer);
        correctSound = new Audio(require(`@/assets/sound/key/correct.mp3`));
        errorSound = new Audio(require(`@/assets/sound/key/error.mp3`));
        this.getText();
        this.setFocus();
    },
    beforeRouteLeave (to, from , next) {
        clearInterval(timer);
        next();
    },
    methods: {
        toLogin(){
            this.$router.push("/login");
        },
        paymentNext() {
            this.$router.push('/payment');
        },
        paymentBack(){
            this.$router.push('/lesson');
        },
        Loading(val) {
            if(val == true) {
                this.$loading({
                lock: true,
                text: 'Түр хүлээнэ үү',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
                });
            } else {
                this.$loading().close();
            }
        },
        getText() {
            var rts = this;
            const token = localStorage.getItem('token');
            if(token) {
                this.Loading(true);
                this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/get-lesson',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        id: this.lesson.id
                    }
                })
                .then(function(data){
                    console.log(data);
                    rts.Loading(false);
                    if(data.data.result) {
                        rts.counter.realWpm = data.data.group.wpm;
                        rts.status.lessonname = data.data.data.lessonname;
                        rts.status.groupname = data.data.group.groupName;
                        if(data.data.data.type != 'intro') {
                            rts.$router.push('/lesson');
                        } else {
                            rts.lesson.lvl = data.data.data.lvl;
                            rts.text.original = data.data.data.text;
                            rts.splitText();
                        }
                    } else {
                        rts.$router.push('/lesson');
                    }
                    
                }).catch(error => {
                    rts.Loading(false);
                    if(error.response.status == 402) {
                        cash('#payment-required-modal').modal('show');
                    }
                });
            }else{
                this.Loading(true);
                this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/get-lesson',
                    data: {
                        id: this.lesson.id
                    }
                })
                .then(function(data){
                    rts.Loading(false);
                    if(data.data.result) {
                        if(data.data.result=="notLogged"){
                            cash("#login_modal").modal("show");
                        }else{
                            rts.counter.realWpm = data.data.group.wpm;
                            rts.status.lessonname = data.data.data.lessonname;
                            rts.status.groupname = data.data.group.groupName;
                            if(data.data.data.type != 'intro') {
                                rts.$router.push('/lesson');
                            } else {
                                rts.lesson.lvl = data.data.data.lvl;
                                rts.text.original = data.data.data.text;
                                rts.splitText();
                            }
                        }

                    } else {
                        rts.$router.push('/lesson');
                    }
                    
                }).catch(error => {
                    rts.Loading(false);
                    if(error.response.status == 402) {
                        cash('#payment-required-modal').modal('show');
                    }
                });
            }
            
        },
        splitText() {
            let txt = this.text.original;
            this.text.splitted = txt.match(/.{1,1}/g);
            this.mon_finger();
        },
        startGame() {
            clearInterval(timer);
            this.counter.start = true;
            timer = setInterval(this.updateTimer, 1000);
        },
        updateTimer() {
            this.counter.time_passed++;
        },
        type() {
            correctSound.pause();
            errorSound.pause();
            if(this.counter.start == false) {
                this.startGame();
            }
            var input = this.$refs.inputDiv.innerHTML.replace('&amp;','&');
            if(input == '&nbsp;')
                input = ' ';

            var current = this.text.splitted[this.text.count][this.counter.current]
            this.$refs.inputDiv.innerHTML = '';
            

            if(input == current) {
                correctSound.currentTime = 0;
                correctSound.play();
                this.counter.characters++;
                this.progress = parseInt(this.counter.characters * 100 / this.text.original.length);
                this.$refs['main-span-' + this.counter.current][0].classList.remove('span-error');
                this.$refs['span-error-word-' + this.counter.current][0].innerHTML = '';
                this.$refs['main-span-' + this.counter.current][0].classList.remove('shake-span');
                this.$refs['child-span-' + this.counter.current][0].classList.remove('span-opacity-0');
                this.$refs['main-span-' + this.counter.current][0].classList.add('span-correct');
                if(this.text.count < this.text.splitted.length) {
                    if(this.counter.current == this.text.splitted[this.text.count].length - 1) {
                        this.counter.current = 0;
                        this.text.count++;
                    } else {
                        this.counter.current++;
                    }
                }

                if(this.text.count >= this.text.splitted.length) {
                    this.finishGame();
                    // this.$router.push({name: 'top-menu-success-lesson', params: {prev: 3, next: 4}});
                } else {
                    this.mon_finger();
                }
                
                
            } else {
                errorSound.currentTime = 0;
                errorSound.play();
                this.counter.errors++;
                this.$refs['span-error-word-' + this.counter.current][0].innerHTML = input;
                this.$refs['child-span-' + this.counter.current][0].classList.add('span-opacity-0');
                this.$refs['main-span-' + this.counter.current][0].classList.add('span-error');
                this.$refs['main-span-' + this.counter.current][0].classList.add('shake-span');
                setTimeout(() => {
                    this.$refs['span-error-word-' + this.counter.current][0].innerHTML = '';
                    this.$refs['child-span-' + this.counter.current][0].classList.remove('span-opacity-0');
                    this.$refs['main-span-' + this.counter.current][0].classList.remove('span-error');
                    this.$refs['main-span-' + this.counter.current][0].classList.remove('shake-span');
                }, 300);
            }
        },
        setFocus() {
            this.$refs.inputDiv.focus();
        },
        finishGame() {
            clearInterval(timer);
            var score = 0; var accuracy= 0; var wpm = 0;
            
            var typed = this.counter.characters + this.counter.errors;
            accuracy = parseInt(typed / ( typed + this.counter.errors )  * 100 );
            wpm = parseInt((this.counter.characters / 5) / (this.counter.time_passed / 60));
            console.log(wpm);
            var diff_acc;
            if(accuracy > 80) {
                var diff = accuracy - 80;
                diff_acc = parseInt((diff * 100) / 20);
                
            } else {
                diff_acc = 0;
            }
            

            var wpmPoint = 100;
            
            score = wpmPoint + diff_acc;
            var rts = this;
            console.log(this.lesson.id + " " + wpm + " " + accuracy + " " + score + " " + this.lesson.lvl);
            const token = localStorage.getItem('token');
            if(token) {
                this.Loading(true);
                this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/update-user-lesson',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        lessonId: this.lesson.id,
                        wpm,
                        accuracy,
                        score,
                        level: this.lesson.lvl
                    }
                })
                .then(function(data){
                    if(data.data.point > 0) {
                        rts.$emit('SetPointFull', data.data.point);
                    }
                    rts.Loading(false);
                    rts.$router.push({name: 'top-menu-success-lesson', params: {prev: rts.lesson.lvl}});
                });
            }else{
                this.Loading(true);
                this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/update-user-lesson',
                    data: {
                        lessonId: this.lesson.id,
                        wpm,
                        accuracy,
                        score,
                        level: this.lesson.lvl
                    }
                })
                .then(function(data){
                    if(data.data.point > 0) {
                        rts.$emit('SetPointFull', data.data.point);
                    }
                    rts.Loading(false);
                    rts.$router.push({name: 'top-menu-success-lesson', params: {prev: rts.lesson.lvl}});
                });  
            }
        },
        mon_finger() {
            var current = this.text.splitted[this.text.count][this.counter.current]
            
            if( current == ' ' ) {
                return this.keyboardImage = require(`@/assets/images/lesson/space.svg`);
            }
            
            var impossibleSymbols = ['%', ':','.', '"','?', '/', '\''];
            if(impossibleSymbols.includes(current)) {
                switch (current) {
                    case '%':
                        this.fingerText = 'Баруун гарын ядам хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/percent.svg`);
                        break;
                    case '"':
                        this.fingerText = 'Баруун гарын ядам хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/double_quot.svg`);
                        break;
                    case '?':
                        this.fingerText = 'Баруун гарын ядам хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/question.svg`);
                        break;
                    case ':':
                        this.fingerText = 'Баруун гарын ядам хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/todorhoiloh.svg`);
                        break;
                    case '.':
                        this.fingerText = 'Баруун гарын долоовор хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/dot.svg`);
                        break;
                    default:
                        break;
                }
            } else {
                var left_4 = ['=','№','ф','й','я'];
                var left_shift_4 = ['1','Ф','Й','Я','+'];
                var left_3 = ['-','ц','ы','ч'];
                var left_shift_3 = ['2','Ц','Ы','Ч'];
                var left_2 = ['"','у','б','ё'];
                var left_shift_2 = ['3','У','Б','Ё'];
                var left_1 = [':','₮','ж','ө','с','э','а','м'];
                var left_shift_1 = ['4','5','Ж','Ө','С','Э','А','М'];
                var right_1 = ['.','_','н','г','х','р','и','т'];
                var right_shift_1 = ['6','7','Н','Г','Х','Р','И','Т'];
                var right_2 = [',','ш','о','ь'];
                var right_shift_2 = ['8','Ш','О','Ь'];
                var right_3 = ['%','ү','л','в'];
                var right_shift_3 = ['9','Ү','Л','В'];
                var right_4 = ['?','е','щ','з','к','ъ','д','п','ю','|','\''];
                var right_shift_4 = ['0','Е','Щ','З','К','Ъ','Д','П','Ю','|'];
                
                for (let i = 0; i < left_shift_4.length; i++) {
                    if (left_shift_4[i] == current) {
                        this.fingerText = 'Баруун гарын чигчий хуруугаар Shift, зүүн гарын чигий хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_4.length; i++) {
                    if (left_4[i] == current) {
                        this.fingerText = 'Зүүн гарын чигчий хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_3.length; i++) {
                    if (left_3[i] == current) {
                        this.fingerText = 'Зүүн гарын ядам хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_shift_3.length; i++) {
                    if (left_shift_3[i] == current) {
                        this.fingerText = 'Баруун гарын чигчий хуруугаар Shift, зүүн гарын ядам хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_2.length; i++) {
                    if (left_2[i] == current) {
                        this.fingerText = 'Зүүн гарын дунд хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_shift_2.length; i++) {
                    if (left_shift_2[i] == current) {
                        this.fingerText = 'Баруун гарын чигчий хуруугаар Shift, зүүн гарын дунд хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_1.length; i++) {
                    if (left_1[i] == current) {
                        this.fingerText = 'Зүүн гарын долоовор хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_shift_1.length; i++) {
                    if (left_shift_1[i] == current) {
                        this.fingerText = 'Баруун гарын чигчий хуруугаар Shift, зүүн гарын долоовор хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_1.length; i++) {
                    if (right_1[i] == current) {
                        this.fingerText = 'Баруун гарын долоовор хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_shift_1.length; i++) {
                    if (right_shift_1[i] == current) {
                        this.fingerText = 'Зүүн гарын чигчий хуруугаар Shift, Баруун гарын долоовор хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_2.length; i++) {
                    if (right_2[i] == current) {
                        this.fingerText = 'Баруун гарын дунд хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_shift_2.length; i++) {
                    if (right_shift_2[i] == current) {
                        this.fingerText = 'Зүүн гарын чигчий хуруугаар Shift, Баруун гарын дунд хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_3.length; i++) {
                    if (right_3[i] == current) {
                        this.fingerText = 'Баруун гарын ядам хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_shift_3.length; i++) {
                    if (right_shift_3[i] == current) {
                        this.fingerText = 'Зүүн гарын чигчий хуруугаар Shift, Баруун гарын ядам хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_4.length; i++) {
                    if (right_4[i] == current) {
                        this.fingerText = 'Баруун гарын чигчий хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_shift_4.length; i++) {
                    if (right_shift_4[i] == current) {
                        this.fingerText = 'Зүүн гарын чигчий хуруугаар Shift, Баруун гарын чигчий хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }
            }
            
        }
    }
}
</script>