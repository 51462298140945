<style scoped>
.wrongLine{
  text-decoration: line-through;
}
.notify1 {
  width: 280px;
}
.notify2 {
  text-align: right; 
  width: 100%;
}
.notify3 {
  margin-top: 8px;
  text-align: right;
}
</style>
<template>
    <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
      <div class="col-span-12 mt-6">
        <div class="intro-y block sm:flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Уралдааны жагсаалт</h2>
        </div>
        <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
          <table v-if="room.list.length === 0" class="table table-report sm:mt-2">
            <tr>
              <td align="center">
                {{txt}}
              </td>
            </tr>
          </table>
          <table v-else class="table table-report sm:mt-2"> 
            <thead>
              <tr>
                <th class="whitespace-no-wrap" align="center" width="3%"><HomeIcon class="w-5 h-5 whitespace-no-wrap" /></th>
                <th class="whitespace-no-wrap" align="center">Зураг</th>
                <th class="whitespace-no-wrap">Уралдааны нэр</th>
                <th class="whitespace-no-wrap">Мэдээлэл</th>
                <th class="whitespace-no-wrap">Оролцох эрх</th>
                <th class="whitespace-no-wrap">Эхлэх огноо</th>
                <th class="whitespace-no-wrap">Дуусах огноо</th>
                <th class="text-center whitespace-no-wrap"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rooms, index) in room.list"
                :key="index"
                class="intro-x zoom-in"
              >
              <td align="center">
                <!-- <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="puff" class="w-6 h-6" />
                </div> -->
                <MinusCircleIcon v-if="checkDate(rooms.start_at) == false" class="w-5 h-5 mx-auto" :style="{ 'color':'red' }" />
                <CheckCircleIcon v-else class="w-5 h-5 mx-auto" :style="{ 'color':'green' }" />
              </td>
                <td class="w-60" align="center">
                  <div class="flex">
                    <div class="w-20 h-20 image-fit zoom-in" :style="{'margin': 'auto'}">
                        <el-image 
                          class="rounded-md w-20 h-20"
                          @click="showBigImage(rooms.image)"
                          :src="rooms.image">
                        </el-image>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="ml-4 mr-auto"> <div class="font-medium">{{ rooms.name }}</div> <div class="text-gray-600 text-xs"> #{{ rooms.raceId }} </div> </div>
                </td>
                <td>
                    <span>Оролцогчдын тоо <strong>{{ checkUsers(rooms.raceId) }}</strong></span><br>
                    <span>ТОП WPM <strong>{{ checkTopWpm(rooms.raceId) }} WPM</strong></span><br>
                    <span>Тэргүүлэгч <strong>{{ checkUsername(rooms.raceId) }}</strong></span>
                </td>
                <!-- <td>
                    <span>{{ checkTopWpm(rooms.raceId) }} WPM</span>
                </td>
                <td>
                    <span>{{ checkUsername(rooms.raceId) }}</span>
                </td> -->
                <td>
                    <span>{{rooms.minPoint}} - {{rooms.maxPoint}} оноо</span><br>
                    <span>{{rooms.limitWpm}} WPM хүртэл</span>
                </td>
                <td>{{rooms.start_at.split('T')[0]}}</td>
                <td>
                  {{rooms.end_at.split('T')[0]}}
                </td>
                <td class="table-report__action w-56">
                  <div class="flex justify-center items-center whitespace-no-wrap">
                    <a v-if="rooms.active == true" href="javascript:;" class="flex items-center mr-3" @click="joinRankedRace(rooms.raceId, index)">
                      <LogInIcon class="w-4 h-4 mr-1" />
                        <span :style="{'padding-left': '10px'}">Оролцох</span>
                    </a>
                    <a v-else href="javascript:;" class="flex items-center mr-3">
                      <CheckSquareIcon class="w-4 h-4 mr-1" />
                        <span :style="{'padding-left': '10px'}">Дууссан</span>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="demo-image__preview">
      <el-image 
        hidden
        ref="bigImage"
        id="bigImage"
        style="width: 100px; height: 100px"
        :src="`https://api.hurdanhuruu.mn/images/race/default.jpeg`" 
        :preview-src-list="bigImageSource">
      </el-image>
    </div>
    <div id="login_modal" ref="login_modal" class="modal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                <div class="modal__content">
                    <div class="p-5 text-center">
                        <LockIcon class="w-12 h-12 mx-auto " :style="{'color': 'grey'}"/>
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 xl:col-span-12 mt-2" :style="{'border-bottom': '1px solid #d0d2d6', 'padding-bottom': '20px'}">
                                <div class="text-gray-600 mt-2 text-xl"> Та нэвтэрсний дараа шагналтай уралдаанд оролцох боломжтой. </div>
                                <div class="p-5 grid grid-cols-12 gap-4 row-gap-3">
                                <div class="col-span-12 sm:col-span-12"> <input type="email" v-model="login.Uname" required class="input w-full border mt-2 flex-1" placeholder="И-мэйл хаяг" /> </div>
                                <div class="col-span-12 sm:col-span-12"> <input type="password" v-model="login.Upass" required class="input w-full border mt-2 flex-1" placeholder="Нууц үг" /> </div>

                           </div>
                 </div>
            </div>
            </div>
                <div class="intro-x mt-5 xl:mt-1 text-center pb-6">
                <button
                  class="button button--lg w-full xl:w-40 text-white bg-theme-1 xl:mr-3 align-top"
                  :disabled='texts.isDisabled'
                  v-html="buttontext"
                  @click="Login"
                >
                </button>
                
                <button
                  class="button button--lg w-full xl:w-40 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
                  @click="toRegister"
                >
                  Бүртгүүлэх
                </button>
            </div>
                </div>
            </div> 
    </div>
</template>
<script>
import Button from './Button.vue';
import TopMenu from "@/layouts/TopMenu";
export default {
  components: { Button, TopMenu},
    data() {
        return {
          login:{
                Uname:'',
                Upass:''
            },
            texts:{
                disabled:false
            },
            buttontext:"нэвтрэх",
            txt: 'Түр хүлээнэ үү',
            stat: {
              val: true,
              valF: false
            },
            room: {
                list: [],
                players: [],
                wpm: [],
                tops: []
            },
            status: {
              inviteConrfirm: false
            },
            bigImageSource: ['']
        }
    },
    mounted() {
        this.getRooms();
    },
    methods: {
      toRegister(){
            cash("#login_modal").modal("hide");
            this.$router.push("/register");
        },
        Login() {
        var rts = this;
        rts.texts.disabled = true;
        this.buttontext = 'Уншиж байна..';
        this.$axios({
          method: 'POST',
          url: rts.$appUrl +'/api/user/login',
          headers: {
            'Content-Type': 'application/json'
          }, 
          data: {
            email: this.login.Uname,
            password: this.login.Upass
          }
        }).then(function(data){
            rts.buttontext = 'Нэвтрэх';
            rts.texts.disabled = false;
            if(data.data.result === 'fail') {

              rts.$msgbox({
                message: data.data.data,
                type: 'error'
              });

            } else if(data.data.result === 'success') {
                cash("#login_modal").modal("hide");
              rts.$msgbox({
                message: 'Системд амжилттай нэвтэрлээ.',
                type: 'success'
              });

              // rts.$axios.defaults.headers.common.authorization = `Bearer ${data.data.data[0].token}`;
              localStorage.token = data.data.token;
              localStorage.user = JSON.stringify(data.data.data);
              // rts.$router.push("/beginner");
              setTimeout(() => {
                window.location.reload();

              }, 1000);
            }
        });
    },
      Loading(val) {
        if(val == true) {
          this.$loading({
            lock: true,
            text: 'Түр хүлээнэ үү',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
        } else {
          this.$loading().close();
        }
      },
      showBigImage(img) {
        this.bigImageSource = [img];
        const el = document.getElementById("bigImage");
        el.click();
      },
      checkUsername(raceId) {
        let name= ' - ';        
        let ex = this.room.tops.some(function(field) {
          return field.raceId === raceId
        });
        if(ex) {
          this.room.tops.forEach(element => {
            if(element.raceId == raceId) {
              name = element.name;
            }
          });
        }
        return name;
      },
      checkTopWpm(raceId) {
        let wpm;
        let ex = this.room.wpm.some(function(field) {
          return field.raceId === raceId
        });
        if(ex) {
          this.room.wpm.forEach(element => {
            if(element.raceId === raceId) {
              wpm = element.wpm;
            }
          });
        } else {
          wpm = 0;
        }
        return wpm;
      },
      checkUsers(raceId) {
        let count;        
        let ex = this.room.wpm.some(function(field) {
          return field.raceId === raceId
        });
        if(ex) {
          this.room.wpm.forEach(element => {
            if(element.raceId === raceId) {
              count = element.users;
            }
          });
        } else {
          count = 0;
        }
        return count;
      },
      getRooms() {
          const token = localStorage.getItem('token');
          this.Loading(true);
            var rts = this;
            if(token) {
                this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/text/get-all-ranking-text',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(function(data) {
                  rts.Loading(false);
                    if (data.data.result == 'success') {
                      rts.room.list= data.data.data;
                      rts.room.wpm = data.data.wpm;
                      rts.room.tops = data.data.users;
                    } else {
                      rts.txt = 'Одоогоор уралдаан зарлагдаагүй байна'
                    }
                    }).catch(error => {
                      if(error.response.status == 402) {
                        rts.$router.push('/payment');
                      }
                    });
            }else{
              this.$axios({
                    method: 'post',
                    url: this.$appUrl+'/api/text/get-all-ranking-text',
                }).then(function(data) {
                  rts.Loading(false);
                    if (data.data.result == 'success') {
                      rts.room.list= data.data.data;
                      rts.room.wpm = data.data.wpm;
                      rts.room.tops = data.data.users;
                    } else {
                      rts.txt = 'Одоогоор уралдаан зарлагдаагүй байна'
                    }
                    }).catch(error => {
                      if(error.response.status == 402) {
                        rts.$router.push('/payment');
                      }
                    });
            }
        },

      joinRoom(roomId) {
          this.status.inviteConrfirm = true;
          this.$socket.emit('joinRoom', {
              roomId: roomId
          });
      },
      joinRankedRace(id, index) {
        const token = localStorage.getItem('token');
        if(token){
          const point = JSON.parse(localStorage.getItem('user')).point;
          const wpm = JSON.parse(localStorage.getItem('user')).record_wpm;
          const min = this.room.list[index].minPoint;
          const max = this.room.list[index].maxPoint;
          const limit = this.room.list[index].limitWpm;
          if(point > max) {
            this.$msgbox({
              message: `Уучлаарай таны оноо оролцох эрхийг давсан байна.`,
              type: 'error'
            });
          } else if(point < min) {
            this.$msgbox({
              message: `Уучлаарай таны оноо хүрэхгүй байна.`,
              type: 'error'
            });
          } else if(wpm > limit) {
            this.$msgbox({
              message: `Уучлаарай таны WPM оролцох эрхийг давсан байна.`,
              type: 'error'
            });
          }
          else {
            this.$router.push({name: 'top-menu-ranking', params: {raceId: id}});
          }
        }else{
          cash("#login_modal").modal("show");
        }
      },
      IgnoreRoom(roomId, userId) {
        this.status.inviteConrfirm = true;
        this.$socket.emit('ignoreRoom', {
          roomId: roomId,
          userId: userId
        });
      },
      checkDate(date) {
            let start = new Date(date);
            // start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate() + ' ' + start.getHours() + ':' + start.getMinutes() + ':' + start.getSeconds();

            let current = new Date();
            // current = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate() + ' ' + current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
            if(current > start) {
              return true
            }
              return false;
      },
      confirmInvite(event) {
        let roomId = event.target.classList[event.target.classList.length - 1];
        this.joinRoom(roomId);
        this.$router.push('/room');
      },
      ignoreInvite(event) {
        let roomId = event.toElement.classList[event.toElement.classList.length - 2];
        let fromUserId = event.toElement.classList[event.toElement.classList.length - 1];
        this.IgnoreRoom(roomId, fromUserId);
      },   
    },
    sockets: {
      newRoom: function(data) {
        let ex = this.room.list.some(function(field) {
          return field.roomId === data.data.roomId
        });
        
        if(!ex) {
          this.room.list.push(data.data);
        }
      },
      acceptedRequest: function(data) {
        let user = localStorage.getItem('user');
        if(user) {
          var id = JSON.parse(localStorage.getItem('user'));
          if(id.id == data.user) {
            this.joinRoom(data.roomId);
            this.$router.push('/room');
          }
        }
      },
      newRoomPlayers: function(data) {
        let ex = this.room.players.some(function(field) {
          return field.roomId === data.data.roomId
        });
        if(!ex) {
          this.room.players.push(data.data);
        } else {
          this.room.players.forEach(element => {
            if(element.roomId === data.data.roomId) {
              element.total = data.data.total;
            }
          });
        }
      },
      deletedRoom: function(data) {
        
          this.room.list.forEach((element, index) => {
            
            if(element.roomId == data.roomId) {
              this.room.list.splice(index, 1);
            }
          });
      },
      inviteGame: function(data) {
      this.status.inviteConrfirm = false;
      var rts = this;
      let user = localStorage.getItem('user');
      if(user) {
        var id = JSON.parse(localStorage.getItem('user'));
        if(id.id === data.to) {
          const h = this.$createElement;
          rts.$notify({
            title: 'Урилга',
            showClose: false,
            dangerouslyUseHTMLString: true,
            duration: 6000,
            onClick: function() {
              this.close();
            },
            onClose: function() {
              if(rts.status.inviteConrfirm === false) {
                rts.IgnoreRoom(data.roomId, data.fromId);
              }
            },
            message: h('div', { 
              class: 'notify1' 
              }, [
                    h('div', 
                      { 
                        class: 'notify2'
                      }, data.from + ' таныг уралдаанд урьж байна'),
                      h('div', {
                          class: 'notify3'
                        }, [
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-9 text-white ${data.roomId}`,
                                  on: {
                                    click: rts.confirmInvite.bind()
                                  }
                                }, 'Зөвшөөрөх'
                              ),
                              h(
                                'button',
                                { 
                                  class: `button button--sm w-24 mr-1 mb-2 bg-theme-6 text-white ${data.roomId} ${data.fromId}`,
                                  on: {
                                    click: rts.ignoreInvite.bind()
                                  }
                                }, 'Цуцлах'
                              )
                      ])
                    ])
          });

          
        }
      }
    }
    }
}
</script>