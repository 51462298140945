<style scoped>
.bottom-box {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #fff;
    height: 100px;
}
</style>
<template>
    <div class="bottom-box z-50">
        <div align="center">
            <el-progress :text-inside="false" :color="'#91C715'" :stroke-width="15" :show-text="false" :percentage="100" :style="{'width': '50%', 'margin-top': '44px'}" status="success"></el-progress>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>