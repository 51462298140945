<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
.big-title {
  margin-top: -210px;
  font-family: 'Exo 2', sans-serif;
}
.default-font {
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
}
.vue-typer .custom.char {
  color: #000;
}

</style>
<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4" >
        
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen" :style="{'overflow': 'hidden'}">
          <router-link to="/beginner" class="-intro-x flex items-center pt-5">
            <img
              class="w-6"
              src="@/assets/images/logo/logo.png"
              style="width: 120px; height: auto;"
            />
            <span class="-intro-x text-lg text-white dark:text-gray-500 pl-10">Бүх нийтийн боловсролд хуруу нэмнэ.</span>
          </router-link>
          <div class="my-auto" :style="{'position': 'relative'}">
            
            <div
              class="text-white font-medium text-4xl leading-tight -intro-x big-title"
            >
              Хурдан хуруу
            </div>
            <div class="-intro-x mt-2 text-lg text-white dark:text-gray-500 default-font">
              <p id="companionMethods"></p>
            </div>
            <img
              :style="{'margin-bottom': '0', 'position': 'absolute', 'width': '500px', 'height': 'auto', 'margin-top': '120px'}"
              class="-intro-x w-1/2"
              :src="require(`@/assets/images/logo/login.png`)"
            />
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <!-- <div class="mx-auto xl:ml-20 bg-white xl:bg-transparent text-lg dark:text-gray-500" :style="{'position': 'absolute', 'right': '50px', 'margin-top': '25px'}">Өнөө цагийн хүн бүрийн эзэмших ёстой чадваруудын нэг.</div> -->
          <div
            class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
          
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Нэвтрэх
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center p-5">
              Бүртгэлтэй цахим хаяг болон утасны дугаараа хийж нэвтэрнэ үү
            </div>
            <form @submit.prevent="Login">
            <div class="intro-x mt-8">
              <input
                type="text"
                class="intro-x login__input input input--lg border border-gray-300 block"
                placeholder="Email"
                v-model="model.email"
              />
              <input
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                v-model="model.password"
                placeholder="Password"
              />
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <div class="flex items-center mr-auto">
               
              </div>
              <a href="javascript:;" data-toggle="modal" data-target="#forgot-password-modal">Нууц үгээ мартсан уу?</a>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center">
                <button
                  type="submit"
                  class="button button--lg w-full xl:w-40 text-white bg-theme-7 xl:mr-3 align-top"
                  :disabled='texts.isDisabled'
                  v-html="loginBtn"
                >
                </button>
                
                <button
                  class="button button--lg w-full xl:w-40 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
                  @click="signup"
                >
                  Бүртгүүлэх
                </button>

            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center">
              <el-button @click="logInWithFacebook" :disabled="facebookButton.disabled" type="primary" class="button button--lg w-full xl:w-full text-white bg-theme-4 xl:mr-3 align-top" :loading="facebookButton.load">{{facebookButton.text}}</el-button>
            </div>
            </form>
            <div
              class="intro-x mt-10 xl:mt-20 text-gray-700 dark:text-gray-600 text-center xl:text-left"
            >
              Нэвтэрч орсноор
              <router-link class="text-theme-1 dark:text-theme-10" to="/terms"
                >үйчилгээний нөхцөлийг</router-link> <br>зөвшөөрч байна.
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
    <div id="forgot-password-modal" class="modal">
        <div class="modal__content px-5 py-10">
            <form @submit.prevent="forgotPassword">
              <div class="text-center">
                  <div class="mb-3"> Бүртгэлтэй и-мэйл хаягаа оруулснаар тухайн хаягаар таны шинэчлэгдсэн нууц үг <b>нууц үг</b> очих болно.! </div>
              </div>
              <div class="p-5 grid grid-cols-12 gap-4 row-gap-3">
                  <div class="col-span-12 sm:col-span-12"> <label>To:</label> <input type="email" v-model="forgot.email" required class="input w-full border mt-2 flex-1" placeholder="example@gmail.com" /> </div>
              </div>
              <div class="px-5 py-3 text-right border-t border-gray-200 dark:border-dark-5"> 
                <button type="button" data-dismiss="modal" class="button w-20 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"> Болих </button> 
                <button type="submit" class="button w-40 bg-theme-1 text-white" :disabled="forgot.isDisabled"> {{this.forgot.btn}} </button> 
              </div>
            </form>
        </div>
    </div>


    <div id="password-changed-modal" class="modal" ref="password_changed_modal">
        <div class="modal__content">
            <div class="p-5 text-center">
                <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
                <div class="text-2xl mt-5">Амжилтай шинэчлэгдлээ!</div>
                <div class="text-gray-600 mt-2"> Таны шинэ нууц үг таны и-мэйл хаяг руу илгээгдлээ! </div>
            </div>
            <div class="px-5 pb-8 text-center"> <button type="button" data-dismiss="modal" class="button w-24 bg-theme-1 text-white"> Ok </button> </div>
        </div>
    </div>


  </div>
</template>

<script>
import DarkModeSwitcher from "@/components/DarkModeSwitcher";
import facebookLogin from 'facebook-login-vuejs';
import Toastify from "toastify-js";
import Vue from "vue";
import TypeIt from "typeit";

export default {
  components: {
    DarkModeSwitcher,
    facebookLogin    
  },
  async mounted() {
    cash("body")
      .removeClass("app")
      .addClass("login");

      if(this.$route.query.sms) {
        this.$msgbox({
          message: 'И-мэйл хаяг амжилттай баталгаажлаа.',
          type: 'success'
        });
      }

      new TypeIt("#companionMethods", {
            speed: 80,
            waitUntilVisible: true
          })
          .type("Сонирхлтой", {delay: 300})
          .move(-4)
          .type('о')
          .move('END')
          .type(', өрсөлдөөнтэй байдлаар 10 хуруугаар')
          .break({delay: 500})
          .type('алдаагүй, аймаар хурдан')
          .pause(300)
          .move(-7)
          .delete(7)
          .move('END')
          .pause(200)
          .type(', зөв бичиж сургах вэб сайт')
          .pause(500)
          .go();

      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
  },
  data() {
      return {
        facebookButton: {
          text: 'Facebook -ээр нэвтрэх',
          load: false,
          disabled: false
        },
        model: {
          email: '',
          password: '',
          fullscreenLoading: false
          // rememberMe: false
        },
        texts: {
          loginBtn: 'Нэвтрэх',
          isDisabled : false
        },
        forgot: {
          email: '',
          btn: 'Илгээх',
          isDisabled : false
        },
        facebook: {
          isConnected: false,
          name: '',
          email: '',
          personalID: '',
          FB: undefined
        }
      };
    },
  computed: {
      loginBtn: function() {
        return this.texts.loginBtn;
      }
    },
  methods: {
    async logInWithFacebook() {
      console.log("pizda ene yagaad ajillaad bgn bee");
      let rts = this;
      this.facebookButton.text = 'Түр хүлээнэ үү...';
      this.facebookButton.load = true;
      this.facebookButton.disabled = true;
      const FB = window.FB;
      FB.login(function(response) {
        if (response.authResponse) {
          rts.$axios({
            method: 'GET',
            url: `https://graph.facebook.com/v15.0/me?fields=id%2Cname%2Cemail%2Cpicture&access_token=${response.authResponse.accessToken}`,
          }).then(function(data) {
            rts.withFacebookLogin(data);
          }).catch(error => {
                rts.facebookButton.text = 'Facebook -ээр нэвтрэх';
                rts.facebookButton.load = false;
                rts.facebookButton.disabled = false;
            rts.$msgbox({
              message: 'Уучлаарай. Шаардлагатай талбарууд татагдсангүй.',
              type: 'error'
            });
          })
        } else {
          rts.facebookButton.text = 'Facebook -ээр нэвтрэх';
          rts.facebookButton.load = false;
          rts.facebookButton.disabled = false;
        }
      },{scope: 'public_profile,email'});
    },
    withFacebookLogin(data) {
      let rts = this;
      rts.$axios({
              method: 'POST',
              url: rts.$appUrl + '/api/user/facebook-login',
              headers: {},
              data: {
                user: data.data
              }
            }).then(data => {
              if(data.data.result === 'success') {
                rts.$msgbox({
                  message: 'Системд амжилттай нэвтэрлээ.',
                  type: 'success'
                });

                rts.facebookButton.text = 'Facebook -ээр нэвтрэх';
                rts.facebookButton.load = false;
                rts.facebookButton.disabled = true;

                // rts.$axios.defaults.headers.common.authorization = `Bearer ${data.data.data[0].token}`;
                localStorage.token = data.data.token;
                localStorage.active = data.data.data.active;
                localStorage.user = JSON.stringify(data.data.data);
                // rts.$router.push("/beginner");
                setTimeout(() => {
                  window.location.href = window.location.protocol + '//' + window.location.host + '/beginner';
                }, 1000);
              }
      });
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: "589326966333220", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          xfbml : true,
          version: "v15.0"
        });
      };
      return true;
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
      return true;
    },
    Loading(val) {
      if(val == true) {
        this.$loading({
          lock: true,
          text: 'Түр хүлээнэ үү ',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      } else {
        this.$loading().close();
      }
    },
    Login() {
      var rts = this;
        rts.texts.isDisabled = true;
        this.texts.loginBtn = 'Уншиж байна..';
        this.$axios({
          method: 'POST',
          url: rts.$appUrl +'/api/user/login',
          headers: {
            'Content-Type': 'application/json'
          }, 
          data: {
            email: this.model.email,
            password: this.model.password
          }
        }).then(function(data){
            rts.texts.loginBtn = 'Нэвтрэх';
            rts.texts.isDisabled = false;
            if(data.data.result === 'fail') {

              rts.$msgbox({
                message: data.data.data,
                type: 'error'
              });

            } else if(data.data.result === 'success') {

              rts.$msgbox({
                message: 'Системд амжилттай нэвтэрлээ.',
                type: 'success'
              });

              // rts.$axios.defaults.headers.common.authorization = `Bearer ${data.data.data[0].token}`;
              localStorage.token = data.data.token;
              localStorage.user = JSON.stringify(data.data.data);
              // rts.$router.push("/beginner");
              setTimeout(() => {
                window.location.href = window.location.protocol + '//' + window.location.host + '/beginner';
              }, 1000);
            }
        });
    },
    signup() {
      this.$router.push('/register');
    }, 
    forgotPassword() {
      var rts = this;
      rts.forgot.btn = 'Уншиж байна ...';
      rts.forgot.isDisabled = true;
      this.$axios({
          method: 'post',
          url: this.$appUrl+'/api/user/forgot-password',
          headers:{},
          data: {
            email: rts.forgot.email
          }
      }).then(function(data){
        if(data.data.result == 'success') {
          rts.forgot.btn = 'Илгээх';
          rts.forgot.isDisabled = false;
          cash("#password-changed-modal").modal('show');
        } else {
          rts.forgot.btn = 'Илгээх';
          rts.forgot.isDisabled = false;
          rts.$msgbox({
            message: data.data.data,
            type: 'warning'
          });
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getUserData() {
      let rts = this;
      this.facebook.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
        userInformation => {
          this.facebook.personalID = userInformation.id;
          this.facebook.email = userInformation.email;
          this.facebook.name = userInformation.name;
          this.$axios({
              method: 'post',
              url: this.$appUrl+'/api/user/facebook-login',
              headers:{},
              data: {
                userInformation
              }
          }).then(function(data){
            console.log(data);
          }).catch(error => {
            console.log(error);
          });
        }
      )
    },
    sdkLoaded(payload) {
      this.facebook.isConnected = payload.isConnected;
      this.facebook.FB = payload.FB;
      // if (this.isConnected) this.getUserData()
    },
    onLogin() {
      this.facebook.isConnected = true;
      this.getUserData();
    },
    onLogout() {
      this.facebook.isConnected = false;
    }
  }
};


</script>
