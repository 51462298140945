<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.right {
  float: right;
}

.left {
  float: left;
}

.rating .stars {
    text-align: center;
}


.rating .stars .star:before {
  font-family: 'FontAwesome';
  content: '\f005';
  color: #d0d2d6;
  font-size: 40px;
}


.rating .stars .center {
    float: none;
    margin-top: 10px;
}

.rating .stars .star.to_rate:before,
.rating .stars .star.rated:before {
  color: orange;
}

.rating {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.progress-text {
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
    color: grey;
    font-size: 25px;
    position: absolute;
    top: 45px;
}

.point-span {
    font-family: 'Bebas Neue', cursive;
    font-size: 60px;
    color: grey;
    margin-top: -10px;
}

.point-name {
    font-family: 'Exo 2', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: grey;
}

.progress-text-stage {
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
    color: grey;
    font-size: 20px;
    position: absolute;
    top: 52px;
}

.progress-text .inside {
    font-size: 15px;
    margin-top: -10px;
}

.progress-text-stage .inside {
    font-size: 15px;
    margin-top: -10px;
}


.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 50;
  stroke-miterlimit: 10;
  stroke: #91C715;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin:  auto;
  box-shadow: inset 0px 0px 0px #91C715;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.success-title {
    font-family: 'Exo 2', sans-serif;
    font-size: 40px;
    font-weight: 700;
    color: grey;
}

.bottom-box {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    height: 50px;
    left: 0;
}

.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: 137px -403.6666666667px #00fff2, 136px -121.6666666667px #00d5ff, -128px -35.6666666667px #00ff80, -39px -142.6666666667px #ff00ee, 56px -334.6666666667px #3300ff, 245px -144.6666666667px #5e00ff, -66px -47.6666666667px #00ffc8, -10px -278.6666666667px #001eff, -89px 22.3333333333px #00ff40, 95px -247.6666666667px #00ff04, 38px -106.6666666667px #aeff00, 124px -109.6666666667px #0400ff, 154px -231.6666666667px #00ffd0, -224px -396.6666666667px #e6ff00, 101px -144.6666666667px #2f00ff, 177px -292.6666666667px #2bff00, -243px -57.6666666667px #ffaa00, 124px -92.6666666667px #00ffb3, -24px -357.6666666667px #ffae00, 86px 6.3333333333px #ff1100, 144px -243.6666666667px #ff2200, -106px -264.6666666667px #ff007b, -118px -97.6666666667px #88ff00, -27px -83.6666666667px #005eff, -212px -293.6666666667px #a6ff00, 34px -367.6666666667px #d9ff00, -189px -201.6666666667px #ff00d5, -71px -57.6666666667px #00ff22, 163px -44.6666666667px #5eff00, -11px 52.3333333333px #ff2b00, -234px -264.6666666667px #2200ff, -239px -404.6666666667px #4000ff, 200px -408.6666666667px #1100ff, 59px 42.3333333333px #00eeff, -50px -177.6666666667px #00ffbb, 181px 9.3333333333px #7b00ff, -16px 61.3333333333px #ff3700, 161px -159.6666666667px #ffea00, 98px -238.6666666667px #ff1100, -102px -251.6666666667px #ff5100, -41px -385.6666666667px #00ff9d, 30px -366.6666666667px #ff007b, -200px -312.6666666667px #ff0066, 179px -223.6666666667px #ff0037, 211px -54.6666666667px #a200ff, -140px -325.6666666667px #ff00bb, -5px -267.6666666667px #ff6600, 48px -386.6666666667px #0015ff, 60px -1.6666666667px #b7ff00, -54px -235.6666666667px #2200ff, -109px -239.6666666667px #15ff00;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: 137px -403.6666666667px #00fff2, 136px -121.6666666667px #00d5ff, -128px -35.6666666667px #00ff80, -39px -142.6666666667px #ff00ee, 56px -334.6666666667px #3300ff, 245px -144.6666666667px #5e00ff, -66px -47.6666666667px #00ffc8, -10px -278.6666666667px #001eff, -89px 22.3333333333px #00ff40, 95px -247.6666666667px #00ff04, 38px -106.6666666667px #aeff00, 124px -109.6666666667px #0400ff, 154px -231.6666666667px #00ffd0, -224px -396.6666666667px #e6ff00, 101px -144.6666666667px #2f00ff, 177px -292.6666666667px #2bff00, -243px -57.6666666667px #ffaa00, 124px -92.6666666667px #00ffb3, -24px -357.6666666667px #ffae00, 86px 6.3333333333px #ff1100, 144px -243.6666666667px #ff2200, -106px -264.6666666667px #ff007b, -118px -97.6666666667px #88ff00, -27px -83.6666666667px #005eff, -212px -293.6666666667px #a6ff00, 34px -367.6666666667px #d9ff00, -189px -201.6666666667px #ff00d5, -71px -57.6666666667px #00ff22, 163px -44.6666666667px #5eff00, -11px 52.3333333333px #ff2b00, -234px -264.6666666667px #2200ff, -239px -404.6666666667px #4000ff, 200px -408.6666666667px #1100ff, 59px 42.3333333333px #00eeff, -50px -177.6666666667px #00ffbb, 181px 9.3333333333px #7b00ff, -16px 61.3333333333px #ff3700, 161px -159.6666666667px #ffea00, 98px -238.6666666667px #ff1100, -102px -251.6666666667px #ff5100, -41px -385.6666666667px #00ff9d, 30px -366.6666666667px #ff007b, -200px -312.6666666667px #ff0066, 179px -223.6666666667px #ff0037, 211px -54.6666666667px #a200ff, -140px -325.6666666667px #ff00bb, -5px -267.6666666667px #ff6600, 48px -386.6666666667px #0015ff, 60px -1.6666666667px #b7ff00, -54px -235.6666666667px #2200ff, -109px -239.6666666667px #15ff00;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: 137px -403.6666666667px #00fff2, 136px -121.6666666667px #00d5ff, -128px -35.6666666667px #00ff80, -39px -142.6666666667px #ff00ee, 56px -334.6666666667px #3300ff, 245px -144.6666666667px #5e00ff, -66px -47.6666666667px #00ffc8, -10px -278.6666666667px #001eff, -89px 22.3333333333px #00ff40, 95px -247.6666666667px #00ff04, 38px -106.6666666667px #aeff00, 124px -109.6666666667px #0400ff, 154px -231.6666666667px #00ffd0, -224px -396.6666666667px #e6ff00, 101px -144.6666666667px #2f00ff, 177px -292.6666666667px #2bff00, -243px -57.6666666667px #ffaa00, 124px -92.6666666667px #00ffb3, -24px -357.6666666667px #ffae00, 86px 6.3333333333px #ff1100, 144px -243.6666666667px #ff2200, -106px -264.6666666667px #ff007b, -118px -97.6666666667px #88ff00, -27px -83.6666666667px #005eff, -212px -293.6666666667px #a6ff00, 34px -367.6666666667px #d9ff00, -189px -201.6666666667px #ff00d5, -71px -57.6666666667px #00ff22, 163px -44.6666666667px #5eff00, -11px 52.3333333333px #ff2b00, -234px -264.6666666667px #2200ff, -239px -404.6666666667px #4000ff, 200px -408.6666666667px #1100ff, 59px 42.3333333333px #00eeff, -50px -177.6666666667px #00ffbb, 181px 9.3333333333px #7b00ff, -16px 61.3333333333px #ff3700, 161px -159.6666666667px #ffea00, 98px -238.6666666667px #ff1100, -102px -251.6666666667px #ff5100, -41px -385.6666666667px #00ff9d, 30px -366.6666666667px #ff007b, -200px -312.6666666667px #ff0066, 179px -223.6666666667px #ff0037, 211px -54.6666666667px #a200ff, -140px -325.6666666667px #ff00bb, -5px -267.6666666667px #ff6600, 48px -386.6666666667px #0015ff, 60px -1.6666666667px #b7ff00, -54px -235.6666666667px #2200ff, -109px -239.6666666667px #15ff00;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: 137px -403.6666666667px #00fff2, 136px -121.6666666667px #00d5ff, -128px -35.6666666667px #00ff80, -39px -142.6666666667px #ff00ee, 56px -334.6666666667px #3300ff, 245px -144.6666666667px #5e00ff, -66px -47.6666666667px #00ffc8, -10px -278.6666666667px #001eff, -89px 22.3333333333px #00ff40, 95px -247.6666666667px #00ff04, 38px -106.6666666667px #aeff00, 124px -109.6666666667px #0400ff, 154px -231.6666666667px #00ffd0, -224px -396.6666666667px #e6ff00, 101px -144.6666666667px #2f00ff, 177px -292.6666666667px #2bff00, -243px -57.6666666667px #ffaa00, 124px -92.6666666667px #00ffb3, -24px -357.6666666667px #ffae00, 86px 6.3333333333px #ff1100, 144px -243.6666666667px #ff2200, -106px -264.6666666667px #ff007b, -118px -97.6666666667px #88ff00, -27px -83.6666666667px #005eff, -212px -293.6666666667px #a6ff00, 34px -367.6666666667px #d9ff00, -189px -201.6666666667px #ff00d5, -71px -57.6666666667px #00ff22, 163px -44.6666666667px #5eff00, -11px 52.3333333333px #ff2b00, -234px -264.6666666667px #2200ff, -239px -404.6666666667px #4000ff, 200px -408.6666666667px #1100ff, 59px 42.3333333333px #00eeff, -50px -177.6666666667px #00ffbb, 181px 9.3333333333px #7b00ff, -16px 61.3333333333px #ff3700, 161px -159.6666666667px #ffea00, 98px -238.6666666667px #ff1100, -102px -251.6666666667px #ff5100, -41px -385.6666666667px #00ff9d, 30px -366.6666666667px #ff007b, -200px -312.6666666667px #ff0066, 179px -223.6666666667px #ff0037, 211px -54.6666666667px #a200ff, -140px -325.6666666667px #ff00bb, -5px -267.6666666667px #ff6600, 48px -386.6666666667px #0015ff, 60px -1.6666666667px #b7ff00, -54px -235.6666666667px #2200ff, -109px -239.6666666667px #15ff00;
  }
}
@keyframes bang {
  to {
    box-shadow: 137px -403.6666666667px #00fff2, 136px -121.6666666667px #00d5ff, -128px -35.6666666667px #00ff80, -39px -142.6666666667px #ff00ee, 56px -334.6666666667px #3300ff, 245px -144.6666666667px #5e00ff, -66px -47.6666666667px #00ffc8, -10px -278.6666666667px #001eff, -89px 22.3333333333px #00ff40, 95px -247.6666666667px #00ff04, 38px -106.6666666667px #aeff00, 124px -109.6666666667px #0400ff, 154px -231.6666666667px #00ffd0, -224px -396.6666666667px #e6ff00, 101px -144.6666666667px #2f00ff, 177px -292.6666666667px #2bff00, -243px -57.6666666667px #ffaa00, 124px -92.6666666667px #00ffb3, -24px -357.6666666667px #ffae00, 86px 6.3333333333px #ff1100, 144px -243.6666666667px #ff2200, -106px -264.6666666667px #ff007b, -118px -97.6666666667px #88ff00, -27px -83.6666666667px #005eff, -212px -293.6666666667px #a6ff00, 34px -367.6666666667px #d9ff00, -189px -201.6666666667px #ff00d5, -71px -57.6666666667px #00ff22, 163px -44.6666666667px #5eff00, -11px 52.3333333333px #ff2b00, -234px -264.6666666667px #2200ff, -239px -404.6666666667px #4000ff, 200px -408.6666666667px #1100ff, 59px 42.3333333333px #00eeff, -50px -177.6666666667px #00ffbb, 181px 9.3333333333px #7b00ff, -16px 61.3333333333px #ff3700, 161px -159.6666666667px #ffea00, 98px -238.6666666667px #ff1100, -102px -251.6666666667px #ff5100, -41px -385.6666666667px #00ff9d, 30px -366.6666666667px #ff007b, -200px -312.6666666667px #ff0066, 179px -223.6666666667px #ff0037, 211px -54.6666666667px #a200ff, -140px -325.6666666667px #ff00bb, -5px -267.6666666667px #ff6600, 48px -386.6666666667px #0015ff, 60px -1.6666666667px #b7ff00, -54px -235.6666666667px #2200ff, -109px -239.6666666667px #15ff00;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}


@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>
<template>
    <div class="grid grid-cols-12 gap-6">
        <div class="pyro">
            <div class="before"></div>
            <div class="after"></div>
        </div>
        <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6" :style="{'position': 'relative'}">
            <div class="col-span-12 sm:col-span-12 xxl:col-span-12 intro-y" :style="{'margin-top': '50px'}">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                
                <div class="success-title intro-y" align="center">Амжилттай</div>
                <div class="rating" :style="{'overflow': 'hidden', 'margin-top': '-10px'}">
                    <div class="stars" align="center" :style="{'width': '100%'}">
                        <a v-for="(i, l) in user.star" :key="l" class="star rated"></a>
                        <a v-for="(i, l) in 5 - user.star" :key="'a' + l" class="star"></a>
                    </div>
                </div>
                <div align="center" :style="{'margin-top': '-20px'}">
                    <div :style="{'position': 'relative', 'width': '400px', 'border-top': '1px solid grey', 'padding-top': '20px'}" class="mt-5" align="center">
                        <span :style="{'left': '62px'}" class="progress-text"><span id="userWPM">0</span><br><p class="inside">WPM</p></span>
                        <el-progress type="dashboard" :percentage="100" :width="100" :text-inside="false" :stroke-width="2" :color="'grey'" :show-text="false"></el-progress>

                        <span :style="{'left': '170px'}" class="progress-text"><span id="userAccuracy">0</span>%<br><p class="inside">Чанар</p></span>
                        <el-progress class="ml-5" type="dashboard" :width="100" :percentage="100" status="success" :color="'grey'" :text-inside="true" :stroke-width="2"></el-progress>

                        <span :style="{'left': '280px'}" class="progress-text-stage">{{checkStage()}}/{{stage}}<br><p class="inside">Хичээл</p></span>
                        <el-progress class="ml-5" type="dashboard" :width="100" :percentage="100" status="success" :color="'grey'" :text-inside="true" :stroke-width="2"></el-progress>
                    </div>
                </div>
                <div align="center">
                    <p class="point-name" :style="{'color': '#0383F2'}">Оноо</p>
                    <div :style="{'position': 'relative', 'width': '200px', 'border-top': '1px solid grey'}" align="center">
                        <p class="point-span" id="count" :style="{'color': '#0383F2'}">0</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-box z-50">
            <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
                <div class="col-span-4 sm:col-span-4 xxl:col-span-4 intro-y" align="right">
                    <el-button round :style="{'margin-top': '6px'}" @click="tryAgain">Дахин эхлүүлэх</el-button>
                </div>
                <div class="col-span-4 sm:col-span-4 xxl:col-span-4 intro-y" align="center">
                    <el-progress :text-inside="false" :color="'#91C715'" :stroke-width="10" :show-text="false" :percentage="100" :style="{'width': '100%', 'margin-top': '22px'}" status="success"></el-progress>
                </div>
                <div class="col-span-4 sm:col-span-4 xxl:col-span-4 intro-y" align="left">
                    <el-button round type="success" :style="{'margin-top': '6px', 'background': '#91C715', 'height': '37px'}" :hidden="next.show" @click="nextLesson"><ArrowRightIcon class="w-20 h-5 -mt-1"/></el-button>
                    <el-button @click="toCertificate" round type="success" :style="{'margin-top': '6px', 'background': '#91C715', 'height': '37px'}" :hidden="!next.show">Гэрчилгээ авах</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let firstScore = 0;
let firstWPM = 0;
let firstAccuracy = 0;
let scoreCounter = null;
let wpmCounter = null;
let accuracyCounter = null;
export default {
    created() {
        var rts = this;
        document.onkeydown = function (e) {
            let evt = e.keyCode;
            if(evt == 13) {
                if(!rts.next.show) {
                    rts.nextLesson();
                }
            }
        }
    },
    data() {
        return {
            stage: 0,
            prev: {
                id: '',
                lvl: '',
                type: ''
            },
            next: {
                id: '',
                lvl: '',
                type: '',
                show: false
            },
            user: {
                score: 0,
                wpm: 0,
                accuracy: 0,
                star: 0
            }
        }
    },
    mounted() {
        this.prev.lvl = this.$route.params.prev;
        this.next.lvl = parseInt(this.$route.params.prev) + 1;
        this.getLessons();
    },
    beforeRouteLeave (to, from , next) {
        clearInterval(scoreCounter);
        clearInterval(wpmCounter);
        clearInterval(accuracyCounter);
        document.onkeydown = null;
        next();
    },
    methods: {
        toCertificate() {
          this.$router.push('/certificate');
        },
        format(percentage) {
            return `${percentage}`;
        },
        Loading(val) {
            if(val == true) {
                this.$loading({
                lock: true,
                text: 'Түр хүлээнэ үү',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
                });
            } else {
                this.$loading().close();
            }
        },
        getLessons(){
            var rts = this;
            const token = localStorage.getItem('token');
            if(token) {
                this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/get-result-lessons',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        prev: this.prev.lvl,
                        next: this.next.lvl
                    }
                })
                .then(function(data){
                    const next = data.data.next;
                    const p = data.data.prev;
                    const u = data.data.score;
                    if(next == undefined) {
                        rts.next.show = true;
                    } else {
                        rts.next.show = false;
                        rts.next.id = next.id;
                        rts.next.type = next.type;
                    }

                    rts.prev.id = p.id;
                    rts.prev.type = p.type;

                    rts.user.score = u.score;
                    rts.user.accuracy = u.accuracy;
                    rts.user.wpm = u.wpm;

                    var c = data.data.count.count;
                    if(c < 10) {
                        rts.stage = '00'+c;
                    } else if(c < 100) {
                        rts.stage = '0'+c;
                    } else {
                        rts.stage = c;
                    }

                    if (u.score > 160) {
                        rts.user.star = 5;
                    } else if(u.score > 120) {
                        rts.user.star = 4;
                    } else if (u.score > 80) {
                        rts.user.star = 3;
                    } else if(u.score > 40) {
                        rts.user.star = 2;
                    } else if (u.score > 0) {
                        rts.user.star = 1;
                    } else {
                        rts.user.star = 0;
                    }
                    
                    firstScore = 0;
                    firstWPM = 0;
                    firstAccuracy = 0;
                    scoreCounter = setInterval(rts.counter,50);
                    wpmCounter = setInterval(rts.countWpm,50);
                    accuracyCounter = setInterval(rts.countAccuracy,50);
                    
                }).catch(error => {
                    if(error.response.status == 402) {
                        rts.$router.push('/payment');
                    }
                });
            }else{
              this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/get-result-lessons',
                    data: {
                        prev: this.prev.lvl,
                        next: this.next.lvl
                    }
                })
                .then(function(data){
                    const next = data.data.next;
                    const p = data.data.prev;
                    const u = data.data.score;
                    if(next == undefined) {
                        rts.next.show = true;
                    } else {
                        rts.next.show = false;
                        rts.next.id = next.id;
                        rts.next.type = next.type;
                    }

                    rts.prev.id = p.id;
                    rts.prev.type = p.type;

                    rts.user.score = 100;
                    rts.user.accuracy =100;
                    rts.user.wpm = 30;

                    var c = data.data.count.count;
                    if(c < 10) {
                        rts.stage = '00'+c;
                    } else if(c < 100) {
                        rts.stage = '0'+c;
                    } else {
                        rts.stage = c;
                    }

                    if (u.score > 160) {
                        rts.user.star = 5;
                    } else if(u.score > 120) {
                        rts.user.star = 4;
                    } else if (u.score > 80) {
                        rts.user.star = 3;
                    } else if(u.score > 40) {
                        rts.user.star = 2;
                    } else if (u.score > 0) {
                        rts.user.star = 1;
                    } else {
                        rts.user.star = 0;
                    }
                    
                    firstScore = 0;
                    firstWPM = 0;
                    firstAccuracy = 0;
                    scoreCounter = setInterval(rts.counter,50);
                    wpmCounter = setInterval(rts.countWpm,50);
                    accuracyCounter = setInterval(rts.countAccuracy,50);
                    
                }).catch(error => {
                    if(error.response.status == 402) {
                        rts.$router.push('/payment');
                    }
                });
            }
        },
        countAccuracy() {
            if(firstAccuracy < this.user.accuracy) {
                firstAccuracy = firstAccuracy +  3;
                document.getElementById("userAccuracy").innerHTML = firstAccuracy;
            } else {
                firstAccuracy = this.user.accuracy;
                document.getElementById("userAccuracy").innerHTML = firstAccuracy;
                clearInterval(accuracyCounter);
            }
        },
        counter() {
            if(firstScore < this.user.score) {
                firstScore = firstScore +  3;
                document.getElementById("count").innerHTML = firstScore;
            } else {
                firstScore = this.user.score;
                document.getElementById("count").innerHTML = firstScore;
                clearInterval(scoreCounter);
            }
        },
        countWpm() {
            if(firstWPM < this.user.wpm) {
                firstWPM = firstWPM +  3;
                document.getElementById("userWPM").innerHTML = firstWPM;
            } else {
                firstWPM = this.user.wpm;
                document.getElementById("userWPM").innerHTML = firstWPM;
                clearInterval(wpmCounter);
            }
        },
        tryAgain() {
            if(this.prev.type == 'boxed') {
                this.$router.push({name: 'top-menu-boxed-lesson', params: {id: this.prev.id}});
            } else if(this.prev.type == 'intro') {
                this.$router.push({name: 'top-menu-intro-lesson', params: {id: this.prev.id}});
            } else if(this.prev.type == 'review') {
                this.$router.push({name: 'top-menu-review-lesson', params: {id: this.prev.id}});
            } else if (this.prev.type == 'hold') {
                this.$router.push({name: 'top-menu-holded-lesson', params: {id: this.prev.id}});
            }
        },
        nextLesson() {
            if(this.next.type == 'boxed') {
                this.$router.push({name: 'top-menu-boxed-lesson', params: {id: this.next.id}});
            } else if (this.next.type == 'intro') {
                this.$router.push({name: 'top-menu-intro-lesson', params: {id: this.next.id}});
            } else if (this.next.type == 'review') {
                this.$router.push({name: 'top-menu-review-lesson', params: {id: this.next.id}});
            } else if (this.next.type == 'hold') {
                this.$router.push({name: 'top-menu-holded-lesson', params: {id: this.next.id}});
            }
        },
        checkStage() {
            var stage = this.prev.lvl;
            if(stage < 10) {
                return stage = '00'+stage;
            } else if(stage < 100) {
                return stage = '0'+stage
            } else {
                return stage;
            }
        }
    }
}
</script>