<template>
  <DonutChart
    :width="width"
    :height="height"
    :chart-data="data"
    :options="options"
  >
  </DonutChart>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    now: {
      type: Number, 
      default: 0
    },
    left: {
      type: Number,
      default: 0
    }
  },
  computed: {
    data() {
      return {
        labels: ["", ""],
        datasets: [
          {
            data: [this.left, this.now],
            backgroundColor: ["#ffc300", "#c1cad9"],
            hoverBackgroundColor: ["#84de5b", "#c1cad9"],
            borderWidth: 15,
            borderColor: this.$store.state.main.darkMode ? "#303953" : "#fff"
          }
        ]
      };
    },
    options() {
      return {
        legend: {
          display: false
        },
        cutoutPercentage: 80
      };
    }
  }
};
</script>
