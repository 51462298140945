<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
            <div class="col-span-12 lg:col-span-12 intro-x">
                <router-link to="/beginner" class="-intro-x flex items-center pt-5">
                    <img
                    class="w-6"
                    src="@/assets/images/logo/logo.png"
                    style="width: 120px; height: auto;"
                    />
                </router-link>
            </div>
            <div class="col-span-12 lg:col-span-12">
                <div class="intro-y box col-span-12 lg:col-span-3">
                    <div
                    class="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200 dark:border-dark-5"
                    >
                    <h2 class="font-medium text-base mr-auto py-5">Үйлчилгээний нөхцөл</h2>
                    </div>
                    <div class="p-5">
                    <div class="tab-content p-5">
                        <div class="accordion">
                            <div class="accordion__pane active border border-gray-200 dark:border-dark-5 p-4"> <a href="javascript:;" class="accordion__pane__toggle font-medium block">НЭГ. ҮГ, ХЭЛЛЭГИЙН ТАЙЛБАР</a>
                                <div class="accordion__pane__content mt-3 text-gray-700 dark:text-gray-600 leading-relaxed"> 
                                    <b>1.1 </b>Хурданхуруу-10 хуруугаар зөв хурдан бичиж сургах  онлайн сургалтын вэб сайт<br>
                                    <b>1.2 </b>Үйлчилгээ–Хурдан хуруу системийг ашиглах үйлчилгээ<br>
                                    <b>1.3 </b>Үйлчилгээ үзүүлэгч–Хурданхуруу вэб сайтаар үйлчилгээ үзүүлж буй Ай Си Би Си ХХК<br>
                                    <b>1.4 </b>Үйлчилгээний нөхцөл–Үйлчилгээтэй холбоотой харилцааг зохицуулах баримт бичиг<br>
                                    <b>1.5 </b>Хэрэглэгч – Хурдан хуруу системийг ашиглаж буй хувь хүн байгууллага<br>
                                    <b>1.6 </b>Хэрэглэгчийн мэдээлэл–Хэрэглэгчийн хувийн мэдээлэл болон сайт дээрх амжилтийн мэдээлэл<br>
                                    <b>1.7 </b>Талууд–Хэрэглэгч болон Үйлчилгээ үзүүлэгч<br>
                                </div>
                            </div>
                            <div class="accordion__pane border border-gray-200 dark:border-dark-5 p-4 mt-3"> <a href="javascript:;" class="accordion__pane__toggle font-medium block">ХОЁР. ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ</a>
                                <div class="accordion__pane__content mt-3 text-gray-700 dark:text-gray-600 leading-relaxed"> 
                                    <b>2.1 </b>Үйлчилгээний нөхцөл нь нэг талаас hurdanhuruu.mn цахим сайт нь “Ай Си Би Си ” ХХК-ний вэб сайт бөгөөд энэхүү үйлчилгээний нөхцлөөр тус онлайн вэб сайтыг ашиглахтай холбоотой үүсэх харилцааг зохицуулна.<br>
                                    <b>2.2 </b>Үйлчилгээний нөхцөл нь Хэрэглэгч Үйлчилгээний нөхцөлтэй танилцан хүлээн зөвшөөрч, www.hurdunhuruu.mn -д  бүртгүүлсэн өдрөөс хүчин төгөлдөр үйлчилнэ. <br>
                                    <b>2.3 </b>Хурдан хуруу систем нь оюуны өмчийн эрхтэй бүтээгдэхүүн бөгөөд Монгол Улсын “Оюуны өмчийн тухай” хуулиар хамгаалагдана. <br>
                                    <b>2.4 </b>Хурдан хуруу вэб сайт, түүний мэдээлэл, баримт бичиг нь Үйлчилгээ үзүүлэгчийн өмч юм. <br>
                                    <b>2.5 </b>Үйлчилгээтэй холбоотой техник, технологи, хадгалагдаж буй бүх мэдээлэл нь Үйлчилгээ үзүүлэгчийн нууц бөгөөд Монгол Улсын “Байгууллагын нууцын тухай” хуулиар зохицуулагдана. <br>
                                </div>
                            </div>
                            <div class="accordion__pane border border-gray-200 dark:border-dark-5 p-4 mt-3"> <a href="javascript:;" class="accordion__pane__toggle font-medium block">ГУРАВ. ХЭРЭГЛЭГЧИЙН ЭРХ, ҮҮРЭГ</a>
                                <div class="accordion__pane__content mt-3 text-gray-700 dark:text-gray-600 leading-relaxed"> 
                                    <b>3.1 </b>Хэрэглэгч "Хувийн нууцын бодлого" баримт бичиг болон Үйлчилгээний нөхцөлтэй танилцаж зөвшөөрснөөр дагаж мөрдөх үүрэг хүлээнэ. <br>
                                    <b>3.2 </b>Хэрэглэгч нь бусдыг төлөөлж эсвэл бусдад Үйлчилгээ хүргэх зорилгоор Үйлчилгээг ашиглах бол тухайн хувь хүн, хуулийн этгээдийн өмнөөс Үйлчилгээний нөхцөлтэй танилцаж, зөвшөөрснөөр төлөөлж буй этгээдийн өмнөөс хариуцлага хүлээнэ. <br>
                                    <b>3.3 </b>Өөрийн нууц үгийг алдалгүй, найдвартай хадгалах нь хэрэглэгчийн үүрэг байна. <br>
                                    <b>3.4 </b> Өөрийн нууц үгээ өөрчлөх, шинэчлэх нь хэрэглэгчийн хариуцлага байна. <br>
                                    <b>3.5 </b>Өөрийн нууц үгийг алдсанаас болон мэдээллийн нууцаа хадгалж чадаагүйгээс үүдэн гарсан асуудлын хариуцлагыг Үйлчилгээ үзүүлэгч хүлээхгүй. <br>
                                    <b>3.6 </b>Хэрэглэгч нь Үйлчилгээ үзүүлэгчийн компьютерийн сүлжээ, сервер компьютерт хууль бусаар халдах, Үйлчилгээнд эвдрэл, саатал учруулах оролдлого хийхгүй байх үүрэгтэй. <br>
                                    <b>3.7 </b>Хэрэглэгч нь Үйлчилгээг ашиглан хийсэн бүхий л үйлдэлд хариуцлага хүлээнэ. <br>
                                    <b>3.8 </b>Үйлчилгээний нууцлал, аюулгүй байдал, найдвартай ажиллагааны талаар санал, гомдлыг Үйлчилгээ үзүүлэгчид гаргана. <br>
                                </div>
                            </div>
                            <div class="accordion__pane border border-gray-200 dark:border-dark-5 p-4 mt-3"> <a href="javascript:;" class="accordion__pane__toggle font-medium block">ДӨРӨВ. ҮЙЛЧИЛГЭЭ ҮЗҮҮЛЭГЧИЙН ЭРХ, ҮҮРЭГ</a>
                                <div class="accordion__pane__content mt-3 text-gray-700 dark:text-gray-600 leading-relaxed">
                                    <b>4.1 </b>Үйлчилгээ үзүүлэгч нь “Хурдан хуруу” системийн арчилгаа, аюулгүй найдвартай байдал, Хэрэглэгчийн мэдээлэл болон ашиглалттай холбоотой мэдээллийг хадгалах, хамгаалах, нөөцлөх зэрэг үйл ажиллагааны жигд байдлыг хангах үүрэгтэй. <br>
                                    <b>4.2 </b>Үйлчилгээ үзүүлэгч нь 24/7 (24 цагийн турш, долоо хоногийн бүх өдөр) нөхцөлөөр Үйлчилгээг Хэрэглэгчдэд хүргэнэ. <br>
                                    <b>4.3 </b>Үйлчилгээ үзүүлэгч нь Хэрэглэгчийн байгаа байрлалд биечлэн очиж тусламж, дэмжлэг үзүүлэхгүй. <br>
                                    <b>4.4 </b>Үйлчилгээ үзүүлэгч нь техникийн өргөтгөл, програмын өөрчлөлт, шинэчлэлт хийх тохиолдолд үйлчилгээнд түр хугацааны зогсолт хийнэ. Энэ тохиолдолд хэрэглэгчийн имэйл хаяг болон вэб сайтаар дамжуулан урьдчилан мэдэгдэнэ. <br>
                                    <b>4.5 </b>Техникийн өргөтгөл, програмын өөрчлөлт, шинэчлэлт хийсэнтэй холбоотой Хэрэглэгчийн мэдээлэл өөрчлөгдсөн, устсан тохиолдолд хариуцлагыг Үйлчилгээ үзүүлэгч хүлээх бөгөөд асуудлыг шийдвэрлэхэд Талууд хамтран ажиллана. <br>
                                    <b>4.6 </b>Үйлчилгээ үзүүлэгч нь Хэрэглэгчийн интернет холболтоос үүдэлтэй асуудлын хариуцлагыг хүлээхгүй. <br>
                                    <b>4.7 </b>Хуулиар тусгайлан эрх олгогдсон байгууллага, тэдгээрийн ажилтан нь Хэрэглэгчийн мэдээлэлд хандах бол тухайн Хэрэглэгчээс зөвшөөрөл, урилга авахыг сануулна. <br>
                                    <b>4.8 </b>Үйлчилгээ үзүүлэгч нь Үйлчилгээний нөхцлийг өөрчлөн, шинэчлэх эрхтэй. Үйлчилгээний нөхцөлд өөрчлөлт хийсэн даруйд өөрчлөлтийн мэдэгдлийг Хэрэглэгчийн имэйл хаяг болон вэб сайтаар дамжуулан мэдэгдэнэ. <br>
                                    <b>4.9 </b>Үйлчилгээ үзүүлэгч нь хэрэглэгчийг сайт дээрх тодорхой шалгууруудыг давсны дараа Хурдан хурууг эзэмшсэн болохыг батлах “Сертификат” олгоно. <br>
                                </div>
                            </div>
                            <div class="accordion__pane border border-gray-200 dark:border-dark-5 p-4 mt-3"> <a href="javascript:;" class="accordion__pane__toggle font-medium block">ТАВ. ТӨЛБӨР, ТООЦОО</a>
                                <div class="accordion__pane__content mt-3 text-gray-700 dark:text-gray-600 leading-relaxed">
                                    <b>5.1 </b>“Хурдан хуруу” вэб сайт нь 3 сар 19,900₮, 6 сар нь 29,900₮, 12 сар нь 39,900₮ гэсэн төлбөртэй байна. <br>
                                    <b>5.2 </b>Үйлчилгээний хугацаа дууссан бол сайт ашиглахад зарим хязгаарлалт хийгдэнэ. Үйлчилгээний төлбөр төлөгдөхөд үйлчилгээний хугацаа сунгагдаж, хязгаарлалт цуцлагдана. <br>
                                    <b>5.3 </b>Үйлчилгээ үзүүлэгч хэрэглэгчийн зүгээс сэтгэл ханамжгүй байгаа нь төлбөр буцаах үндэслэл болохгүй <br>
                                </div>
                            </div>
                            <div class="accordion__pane border border-gray-200 dark:border-dark-5 p-4 mt-3"> <a href="javascript:;" class="accordion__pane__toggle font-medium block">ЗУРГАА. АСУУДАЛ, МАРГААН ШИЙДВЭРЛЭХ</a>
                                <div class="accordion__pane__content mt-3 text-gray-700 dark:text-gray-600 leading-relaxed">
                                    <b>6.1 </b>Хэрэглэгч нь Үйлчилгээг ашиглахтай холбоотой гарын авлагыг www.hurdanhuruu.mn вэб сайтын “Тусламж” цэснээс авах боломжтой. <br>
                                    <b>6.2 </b>Үйлчилгээ үзүүлэгч нь Үйлчилгээг ашиглахтай холбоотой санал, гомдол, хүсэлтийг support@itlab.mn имэйл хаяг болон 75117733 утсаар (ажлын өдрүүдэд 09:00 - 18:00 цагийн хооронд) хүлээн авч шийдвэрлэнэ. <br>
                                    <b>6.3 </b>Үйлчилгээний тасалдал нь байгалийн давагдашгүй хүчин зүйл (гал, үер, газар хөдлөлт, аянга болон тэсрэлт) эсвэл төрийн эрх бүхий байгууллагаас гаргасан хуулийн зохицуулалт, журам, хэрэгжүүлсэн үйл ажиллагаа, ажил хаялт, дайн, террорист халдлага, гоц халдварт өвчин зэрэгтэй холбоотой бол Үйлчилгээг тасалдсанд тооцохгүй бөгөөд Үйлчилгээ үзүүлэгч хариуцлага хүлээх үндэслэл болохгүй. <br>
                                    <b>6.4 </b>Хэрэглэгч нь Үйлчилгээний нөхцлөөр хүлээсэн үүргээ зөрчсөн, Үйлчилгээг хууль бусаар ашигласан, Үйлчилгээ үзүүлэгчийн үйл ажиллагаанд болон бусад Хэрэглэгчдэд хохирол учруулсан нь батлагдвал Хэрэглэгчийн нэвтрэх эрхийг цуцлана. <br>
                                    <b>6.5 </b>Үйлчилгээтэй холбоотой санал, гомдол, асуудал, маргааныг Талууд эв, зүйгээр харилцан зөвшилцөх замаар шийдвэрлэнэ. Хэрэв шийдвэрлэх боломжгүй бол Монгол Улсын хууль, тогтоомжид заасны дагуу шийдвэрлүүлнэ. <br>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import DarkModeSwitcher from "@/components/DarkModeSwitcher";
import facebookLogin from 'facebook-login-vuejs';
import Toastify from "toastify-js";
import Vue from "vue";

export default {
  components: {
    DarkModeSwitcher,
    facebookLogin
  },
  mounted() {

    cash("body")
      .removeClass("login")
      .addClass("app");
  },
};


</script>
