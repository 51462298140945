<style scoped>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
.parent-box {
    width: 80%;
    position: relative;
    padding-left: 20px;
    padding-right: 20px; 
}

.parent-span {
    font-size: 35px;
    color: #515457;
    font-family: 'JetBrains Mono', monospace;
}

.active-span {
    border-top: 2px solid #c7c9c8;
    border-radius: 5px;

}

.active-child-span {
    border-bottom: 3px solid #0383f2;
}

.child-span {
    white-space: pre-wrap;
}

.progress {
    margin-top: 20px;
}

.correct-span {
    background: #E7FBD3;
    color: #0E634D;
}

.error-span {
    background : #FFC0CB;
    color: #8B0000;
}

.warning-span {
    background: #FFE9B2;
    color: #0E634D;
}
.wpm-number {
    margin-top: -25px;
    font-family: 'Exo 2', sans-serif;
    font-size: 30px;
    color: grey;
    position: relative;
    padding-right: 28px;
    display: inline-block;
}

.accuracy-number {
    margin-top: -25px;
    font-size: 30px;
    font-family: 'Exo 2', sans-serif;
    color: grey;
}

.wpm-title {
    font-family: 'Exo 2', sans-serif;
    font-size: 12px;
    vertical-align: top;
    color: grey;
    position: absolute;
    top: 3px;
    right: 0px;
}

.all-title {
    color: grey;
}

.hold-background {
    width: 100%;
    background: black;
    height: 100%; 
    opacity: 0.8;
}

.hold-background-img {
    margin: auto;
}

.hold-background-text {
    margin-top: 5%;
    font-size: 40px;
    color:  #fff;
    font-family: 'Exo 2', sans-serif;
}

.hold-word-box {
    width: 60px;
    height: 60px;
    color: black;
    margin-right: 10px;
    font-family: 'JetBrains Mono', monospace;
    margin-left: 25%;
}


.hold-modal {
  position: fixed; /* Stay in place */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background: black;
  opacity: 0.9;
}
</style>
<template>
    <div>
        <div class="intro-x">
            <div :style="{'padding-top': '50px'}" align="center" @click="setFocus">
                <input @input="this.type" :disabled="inputDisabled" :style="{'position': 'absolute', 'opacity': '0'}" ref="inputDiv" id="inputDiv" autocomplete="off"/>
                <div class="parent-box" align="left">
                    <span v-for="(item, index) in text.splitted" :key="index" class="parent-span" :ref="'parent-span-' + index" v-bind:class="{'active-span': counter.count == index}">
                        <span v-for="(word, i) in item" :key="i" class="child-span" :ref="'child-span-' + index + i" v-bind:class="{'active-child-span': text.current == i && counter.count == index, 'error-span': errorSpans.includes(index + '-' + i), 'correct-span': correctSpans.includes(index + '-' + i), 'warning-span': warningSpans.includes(index + '-' + i) && correctSpans.includes(index + '-' + i)}">{{word}}</span>
                    </span>

                    <div class="progress">
                        <el-progress :percentage="progress" :show-text="false" :color="'#5787E4'" :stroke-width="3"></el-progress>
                    </div>
                </div>
                <div class="mt-1 flex" align="center">
                    <img :src="keyboardImage" :style="{'width': '40%', 'height': 'auto', 'max-width': '100%', 'margin-left': '22%'}">
                    <div align="left" class="mt-20">
                        <div :style="{'margin-top': '5%'}" align="left">
                            <p class="all-title">Хурд</p><br>
                            <span class="wpm-number">{{counter.wpm}} <div class="wpm-title">WPM</div></span>
                        </div>
    
                        <div :style="{'margin-top': '10%'}" align="left">
                            <p class="all-title">Чанар</p><br>
                            <p class="accuracy-number">{{counter.accuracy}}%</p>
                        </div>
                        <div align="left" :style="{'margin-top': '5%'}">
                            <!-- <p class="all-title">{{status.groupname}} / Зай авах товч</p> -->
                            <p class="all-title"><strong>{{status.groupname}} / {{status.lessonname}}</strong></p>
                            <p class="inside all-title" :style="{'font-size': '25px'}">Хичээл №<strong>{{lesson.lvl}}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="hold-modal" class="hold-modal z-50" :style="{'display': `${disabledModal}`}" align="center">
            <div align="center">
                <div class="hold-background-text flex"> <p class="box hold-word-box">{{holdWord.toUpperCase()}}</p> үсгэн дээр удаан дарж текстээ бичнэ үү. </div>   
            </div>
            <img class="hold-background-img" :src="require(`@/assets/images/lessonBox/${holdImage}`)" >
        </div>

        <div id="payment-required-modal" class="modal">
            <div class="modal__content">
                <div class="p-5 text-center">
                    <!-- <xCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" /> -->
                    <div align="center"><img src="@/assets/images/lessonBox/congra.png" class="w-24 h-24"></div>
                    <div class="text-gray-600 mt-2">
                        <span :style="{'font-size': '16px'}"><b>Танд баяр хүргэе !</b> </span><br>
                        Та одоогийн байдлаар нийт <b>51</b> хичээлийг амжилттай давлаа. <br>
                        Үлдэгдэл хичээлийг үзэхийн тулд <b>цааш</b> товчийг дарж үйлчилгээний эрхээ сунгана уу.
                    </div>
                </div>
                <div class="px-5 pb-8 text-center"> 
                    <button type="button" data-dismiss="modal" @click="paymentBack" class="button w-24 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"> Буцах </button>
                    <button type="button" data-dismiss="modal" @click="paymentNext" class="button w-24 bg-theme-9 text-white"> Цааш </button> 
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>

let timer = null;
let correctSound;
let errorSound;
export default {
    created() {
        var rts = this;
        document.onkeyup = function (e) {
            let evt = e.key;
            if(evt == rts.holdWord) {
                if(rts.disabledModal == 'none') {
                    rts.disabledModal = 'block';
                    rts.inputDisabled = true;
                }
            }
        }

        document.onkeydown = function (e) {
            let evt = e.key;
            if (evt == rts.holdWord) {
                rts.$refs.inputDiv.focus();
                if(rts.disabledModal == 'block') {
                    rts.disabledModal = 'none';
                    rts.inputDisabled = false;
                }
            }
        }
    },
    data() {
        return {
            holdImage: 'righthand-modal.png',
            holdWord: '',
            disabledModal: 'block',
            inputDisabled: true,
            keyboardImage: '',
            progress: 0,
            text: {
                original: '',
                splitted: '',
                current: 0
            },
            status: {
                contentEdit: true,
                lessonname: '',
                groupname: ''
            },
            counter: {
                count: 0,
                start: false,
                time_passed : 1,
                characters: 0,
                accuracy: 0,
                wpm: 0
            },
            correctSpans: [],
            errorSpans: [],
            warningSpans: [],
            lesson : {
                id: '',
                lvl: ''
            }
        }
    },
    mounted() {
        this.lesson.id = this.$route.params.id;
        clearInterval(timer);
        correctSound = new Audio(require(`@/assets/sound/key/correct.mp3`));
        errorSound = new Audio(require(`@/assets/sound/key/error.mp3`));
        this.getText();
        this.setFocus();
    },
    beforeRouteLeave (to, from , next) {
        clearInterval(timer);
        document.onkeyup = null;
        document.onkeydown = null;
        next();
    },
    methods: {
        paymentNext() {
            this.$router.push('/payment');
        },
        paymentBack(){
            this.$router.push('/lesson');
        },
        Loading(val) {
            if(val == true) {
                this.$loading({
                lock: true,
                text: 'Түр хүлээнэ үү',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
                });
            } else {
                this.$loading().close();
            }
        },
        splitText() {
            var txt = this.text.original.split(' ');
            txt.forEach((element, index) => {
                if(index != txt.length - 1) {
                    txt[index] = element + " ";
                }
            });
            this.text.splitted = txt;
            this.mon_finger();
        },
        getText() {
            var rts = this;
            const token = localStorage.getItem('token');
            if(token) {
                this.Loading(true);
                this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/get-lesson',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        id: this.lesson.id
                    }
                })
                .then(function(data){
                    rts.Loading(false);
                    if(data.data.result == 'success') {
                        rts.holdImage = data.data.data.url;
                        rts.holdWord = data.data.data.holdword;
                        rts.counter.realWpm = data.data.group.wpm;
                        rts.status.lessonname = data.data.data.lessonname;
                        rts.status.groupname = data.data.group.groupName;
                        if(data.data.data.type != 'hold') {
                            rts.$router.push('/lesson');
                        } else {
                            rts.lesson.lvl = data.data.data.lvl;
                            rts.text.original = data.data.data.text;
                            rts.splitText();
                        }
                    } else {
                        cash('#payment-required-modal').modal('show');
                    }
                    
                }).catch(error => {
                    rts.Loading(false);
                    if(error.response.status == 402) {
                        rts.$router.push('/payment');
                    }
                });
            }else{
                this.Loading(true);
                this.$axios({
                    method: 'post',
                    url: rts.$appUrl +'/api/lesson/get-lesson',
                    data: {
                        id: this.lesson.id
                    }
                })
                .then(function(data){
                    rts.Loading(false);
                    if(data.data.result == 'success') {
                        rts.holdImage = data.data.data.url;
                        rts.holdWord = data.data.data.holdword;
                        rts.counter.realWpm = data.data.group.wpm;
                        rts.status.lessonname = data.data.data.lessonname;
                        rts.status.groupname = data.data.group.groupName;
                        if(data.data.data.type != 'hold') {
                            rts.$router.push('/lesson');
                        } else {
                            rts.lesson.lvl = data.data.data.lvl;
                            rts.text.original = data.data.data.text;
                            rts.splitText();
                        }
                    } else {
                        cash('#payment-required-modal').modal('show');
                    }
                    
                }).catch(error => {
                    rts.Loading(false);
                    if(error.response.status == 402) {
                        rts.$router.push('/payment');
                    }
                });
            }
            
        },
        type() {
            correctSound.pause();
            errorSound.pause();
            var t = new RegExp(this.holdWord, 'i');
            this.$refs.inputDiv.value = this.$refs.inputDiv.value.replace(/&amp;/g,'&').replace(t, '');
            var input = this.$refs.inputDiv.value;
            // console.log(input.length);
            if(input.length > 0) {
                if(this.counter.start == false) {
                    this.startGame();
                }
                this.errorSpans = [];
                this.correctSpans = [];
                var ss = [];
                var txt = input;

                this.progress = parseInt(input.length * 100 / this.text.original.length);
                

                for (let y = 0; y < this.text.splitted.length; y++) {
                    var curr_txt = ''; var last_txt = '';
                    if(txt.length >= this.text.splitted[y].length) {
                        for (let index = 0; index < this.text.splitted[y].length; index++) {
                            curr_txt += txt[index];
                        }
                        ss.push(curr_txt);
                        for (let index = this.text.splitted[y].length; index < txt.length; index++) {
                            last_txt += txt[index];
                        }
                        txt = last_txt;
                    }else {
                        ss.push(txt);  break;
                    }
                }
                
                ss.forEach((element, index) => {
                    this.counter.count = index;
                    this.text.current = element.length;
                    for (let i = 0; i < element.length; i++) {
                        if (input[input.length - 1] == this.text.original[input.length - 1]) {
                            correctSound.currentTime = 0;
                            correctSound.play();
                        } else {
                            errorSound.currentTime = 0;
                            errorSound.play();
                        }

                        if (this.text.splitted[index][i] == element[i]) {
                            this.correctSpans.push(index + '-' + i);
                        } else {
                            this.errorSpans.push(index + '-' + i);
                            this.warningSpans.push(index + '-' + i);
                        }
                    }
                });

                if(this.text.errorLimit < this.errorSpans.length) {
                    this.$confirm('Уучлаарай. Та хэтэрхий их алдаатай бичиж байна.', 'Санамж', {
                        confirmButtonText: 'Дахиж оролдох',
                        cancelButtonText: 'Болих',
                        type: 'warning',
                        center: true
                    }).then(() => {
                        this.counter.characters = 0;
                        this.counter.count = 0;
                        this.text.current = 0;
                        this.$refs.inputDiv.value = '';
                        clearInterval(timer);
                        this.counter.time_passed = 1;
                        this.counter.accuracy = 0;
                        this.counter.wpm = 0;
                        this.counter.start = false;
                        this.errorSpans = [];
                        this.warningSpans = [];
                        this.correctSpans = [];
                        this.getText();
                    }).catch(() => {
                        this.$router.push('/lesson');
                    });
                }

                this.counter.wpm = parseInt((this.correctSpans.length / 5) / (this.counter.time_passed / 60));
                this.counter.accuracy = parseInt((input.length - this.errorSpans.length) * 100 / input.length);
                
                if(input.length == this.text.original.length) {
                    this.counter.characters = input.length;
                    return this.finishGame();
                } else {
                    this.mon_finger();
                }

            }
        },
        setFocus() {
            this.$refs.inputDiv.focus();
        },
        updateTimer() {
            this.counter.time_passed++;
        },
        startGame() {
            clearInterval(timer);
            this.counter.start = true;
            timer = setInterval(this.updateTimer, 1000);
        },
        finishGame() {
            clearInterval(timer);
        
            var wpm = parseInt((this.correctSpans.length / 5) / (this.counter.time_passed / 60));
            var accuracy = parseInt((this.counter.characters - this.errorSpans.length) * 100 / this.counter.characters);
            var diff_acc;
            if(accuracy > 80) {
                var diff = accuracy - 80;
                diff_acc = parseInt((diff * 100) / 20);
                
            } else {
                diff_acc = 0;
            }
            

            // var wpmPoint = parseInt((wpm * 100) / this.counter.realWpm);
            // if(wpmPoint > 100) {
            //     wpmPoint = 100;
            // }

            var bottomPoint = this.counter.realWpm - 18;
            var wpmPoint = 0;
            if(wpm > bottomPoint) {
                var diff_wpm = wpm - bottomPoint;
                wpmPoint = parseInt((diff_wpm * 100) / 18);
                if(wpmPoint > 100) {
                    wpmPoint = 100;
                }
            }
            
            if(wpmPoint == 0) {
                this.$confirm('Уучлаарай. Таны бичилтийн хурд бага байна.', 'Санамж', {
                    confirmButtonText: 'Дахиж оролдох',
                    cancelButtonText: 'Болих',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.counter.characters = 0;
                    this.counter.count = 0;
                    this.text.current = 0;
                    this.$refs.inputDiv.value = '';
                    clearInterval(timer);
                    this.counter.time_passed = 1;
                    this.counter.accuracy = 0;
                    this.counter.wpm = 0;
                    this.counter.start = false;
                    this.errorSpans = [];
                    this.warningSpans = [];
                    this.correctSpans = [];
                    this.getText();
                }).catch(() => {
                    this.$router.push('/lesson');
                });
            } else if(diff_acc == 0) {
                this.$confirm('Уучлаарай. Та хэтэрхий их алдаатай бичиж байна.', 'Санамж', {
                    confirmButtonText: 'Дахиж оролдох',
                    cancelButtonText: 'Болих',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.counter.characters = 0;
                    this.counter.count = 0;
                    this.text.current = 0;
                    this.$refs.inputDiv.value = '';
                    clearInterval(timer);
                    this.counter.time_passed = 1;
                    this.counter.accuracy = 0;
                    this.counter.wpm = 0;
                    this.counter.start = false;
                    this.errorSpans = [];
                    this.warningSpans = [];
                    this.correctSpans = [];
                    this.getText();
                }).catch(() => {
                    this.$router.push('/lesson');
                });
            } else {
                var score = wpmPoint + diff_acc;
                var rts = this;
                const token = localStorage.getItem('token');
                if(token) {
                    this.Loading(true);
                    this.$axios({
                        method: 'post',
                        url: rts.$appUrl +'/api/lesson/update-user-lesson',
                        headers: {
                            "Authorization": `Bearer ${token}`
                        },
                        data: {
                            lessonId: this.lesson.id,
                            wpm,
                            accuracy,
                            score,
                            level: this.lesson.lvl
                        }
                    })
                    .then(function(data){
                        if(data.data.point > 0) {
                            rts.$emit('SetPointFull', data.data.point);
                        }
                        rts.Loading(false);
                        rts.$router.push({name: 'top-menu-success-lesson', params: {prev: rts.lesson.lvl}});
                    });
                }else{
                    this.Loading(true);
                    this.$axios({
                        method: 'post',
                        url: rts.$appUrl +'/api/lesson/update-user-lesson',
                        data: {
                            lessonId: this.lesson.id,
                            wpm,
                            accuracy,
                            score,
                            level: this.lesson.lvl
                        }
                    })
                    .then(function(data){
                        if(data.data.point > 0) {
                            rts.$emit('SetPointFull', data.data.point);
                        }
                        rts.Loading(false);
                        rts.$router.push({name: 'top-menu-success-lesson', params: {prev: rts.lesson.lvl}});
                    });
                }
            }
        },
        mon_finger() {
            var ind = this.$refs.inputDiv.value.replace('&amp;','&');
            var current = this.text.original[ind.length]
            
            if( current == ' ' ) {
                return this.keyboardImage = require(`@/assets/images/lesson/space.svg`);
            }

            var impossibleSymbols = ['%', ':','.', '"','?', '/', '\''];
            if(impossibleSymbols.includes(current)) {
                switch (current) {
                    case '%':
                        this.fingerText = 'Баруун гарын ядам хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/percent.svg`);
                        break;
                    case '"':
                        this.fingerText = 'Баруун гарын ядам хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/double_quot.svg`);
                        break;
                    case '?':
                        this.fingerText = 'Баруун гарын ядам хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/question.svg`);
                        break;
                    case ':':
                        this.fingerText = 'Баруун гарын ядам хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/todorhoiloh.svg`);
                        break;
                    case '.':
                        this.fingerText = 'Баруун гарын долоовор хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/dot.svg`);
                        break;
                    default:
                        break;
                }
            } else {
                var left_4 = ['=','№','ф','й','я'];
                var left_shift_4 = ['1','Ф','Й','Я','+'];
                var left_3 = ['-','ц','ы','ч'];
                var left_shift_3 = ['2','Ц','Ы','Ч'];
                var left_2 = ['"','у','б','ё'];
                var left_shift_2 = ['3','У','Б','Ё'];
                var left_1 = [':','₮','ж','ө','с','э','а','м'];
                var left_shift_1 = ['4','5','Ж','Ө','С','Э','А','М'];
                var right_1 = ['.','_','н','г','х','р','и','т'];
                var right_shift_1 = ['6','7','Н','Г','Х','Р','И','Т'];
                var right_2 = [',','ш','о','ь'];
                var right_shift_2 = ['8','Ш','О','Ь'];
                var right_3 = ['%','ү','л','в'];
                var right_shift_3 = ['9','Ү','Л','В'];
                var right_4 = ['?','е','щ','з','к','ъ','д','п','ю','|','\''];
                var right_shift_4 = ['0','Е','Щ','З','К','Ъ','Д','П','Ю','|'];
                
                for (let i = 0; i < left_shift_4.length; i++) {
                    if (left_shift_4[i] == current) {
                        this.fingerText = 'Баруун гарын чигчий хуруугаар Shift, зүүн гарын чигий хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_4.length; i++) {
                    if (left_4[i] == current) {
                        this.fingerText = 'Зүүн гарын чигчий хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_3.length; i++) {
                    if (left_3[i] == current) {
                        this.fingerText = 'Зүүн гарын ядам хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_shift_3.length; i++) {
                    if (left_shift_3[i] == current) {
                        this.fingerText = 'Баруун гарын чигчий хуруугаар Shift, зүүн гарын ядам хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_2.length; i++) {
                    if (left_2[i] == current) {
                        this.fingerText = 'Зүүн гарын дунд хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_shift_2.length; i++) {
                    if (left_shift_2[i] == current) {
                        this.fingerText = 'Баруун гарын чигчий хуруугаар Shift, зүүн гарын дунд хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_1.length; i++) {
                    if (left_1[i] == current) {
                        this.fingerText = 'Зүүн гарын долоовор хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < left_shift_1.length; i++) {
                    if (left_shift_1[i] == current) {
                        this.fingerText = 'Баруун гарын чигчий хуруугаар Shift, зүүн гарын долоовор хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_1.length; i++) {
                    if (right_1[i] == current) {
                        this.fingerText = 'Баруун гарын долоовор хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_shift_1.length; i++) {
                    if (right_shift_1[i] == current) {
                        this.fingerText = 'Зүүн гарын чигчий хуруугаар Shift, Баруун гарын долоовор хуруугаар';
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_2.length; i++) {
                    if (right_2[i] == current) {
                        this.fingerText = 'Баруун гарын дунд хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_shift_2.length; i++) {
                    if (right_shift_2[i] == current) {
                        this.fingerText = 'Зүүн гарын чигчий хуруугаар Shift, Баруун гарын дунд хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_3.length; i++) {
                    if (right_3[i] == current) {
                        this.fingerText = 'Баруун гарын ядам хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_shift_3.length; i++) {
                    if (right_shift_3[i] == current) {
                        this.fingerText = 'Зүүн гарын чигчий хуруугаар Shift, Баруун гарын ядам хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_4.length; i++) {
                    if (right_4[i] == current) {
                        this.fingerText = 'Баруун гарын чигчий хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lesson/${current}.svg`);
                    }
                }

                for (let i = 0; i < right_shift_4.length; i++) {
                    if (right_shift_4[i] == current) {
                        this.fingerText = 'Зүүн гарын чигчий хуруугаар Shift, Баруун гарын чигчий хуруугаар'
                        return this.keyboardImage = require(`@/assets/images/lessonShift/${current}.svg`);
                    }
                }
            }
        }
    }
}
</script>
